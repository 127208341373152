import React from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

const icons = {
  desc: <Icon icon={faChevronDown} />,
  asc: <Icon icon={faChevronUp} />,
}

export default ({ column, direction, onSort }) => (
  <span
    className={direction ? 'text-primary' : ''}
    style={{ cursor: 'pointer' }}
    onClick={() => onSort({ direction: direction === 'asc' ? 'desc' : 'asc' })}
  >
    {column.title} {icons[direction]}
  </span>
)
