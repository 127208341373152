import React, { useContext, useEffect, useState } from 'react'
import { Pagination, PaginationLink, PaginationItem, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { toastr } from 'react-redux-toastr'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { Label, FormGroup, Input } from 'reactstrap'
import ReactGrid from '../../../reactgrid'
import { PriceBreakdownContext } from './TenderForm'
import { retrieveChildren } from '../../../reactgrid/PriceBreakdownGrid/PriceBreakdownGrid'
import { PriceBreakdownRow } from '../../../reactgrid/PriceBreakdownGrid/types/PriceBreakdownRow'
import { nanoid } from 'nanoid'

import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { UNIT_OPTIONS } from '../../../api/gql/query'
import { useQuery } from '@apollo/react-hooks'
import DownloadXLSX from '../../../reactgrid/components/DownloadXLSX'
import UploadXLSX from '../../../reactgrid/components/UploadXLSX'
import { isUploadValid } from '../../../reactgrid/utils'
import getSortedPriceRows from '../../../reactgrid/PriceBreakdownGrid/functions/getSortedPriceRows'
import Loader from '../../../components/Loader'
import { flatten } from 'lodash'

const RightFloatedRow = styled(Row)`
  float: right;
`


const StyledPaginationItem = styled(PaginationItem)`
  ${props => props.disabled ? 'pointer-events: none; opacity: 0.7;' : 'pointer-events: all;'}
`

const AddBtnPaginationLink = styled(PaginationLink)`
  &:focus {
    box-shadow: none;
  }
`

const CenteredIcon = styled(Icon)`
  vertical-align: middle !important;
`

export enum GRID_MODE {
  DEFAULT,
  BIDDER,
}

interface ICrudModalState {
  isOpen: boolean
  row?: PriceBreakdownRow | null
}

interface PriceBreakdownProps {
  readOnly?: boolean
}

export const PriceBreakdown = ({ readOnly = false }: PriceBreakdownProps) => {
  const { t } = useTranslation()

  const { priceRows, setPriceRows, fetching, gridMode, tenderStatus } = useContext(PriceBreakdownContext)

  const { data: unitOptionsData, loading: unitOptionsFetching } = useQuery(UNIT_OPTIONS)


  const [activeIndex, setActiveIndex] = useState<number>(-1)

  const rootRows = [{ idx: -1, name: t('PriceBreakdown_summary'), rowId: -1 }, ...priceRows.filter((row) => !row.parentId)]

  const [rowsToRender, setRowsToRender] = useState<PriceBreakdownRow[]>(priceRows)

  const [crudModalProps, setCrudModalProps] = useState<ICrudModalState>({
    isOpen: false,
    row: null
  })

  const uploadXlsxDisabled = (gridMode === GRID_MODE.DEFAULT && (tenderStatus !== 'PREPARATION' && tenderStatus !== 'ANOTHER_ROUND')) || (gridMode === GRID_MODE.BIDDER && tenderStatus !== 'RUNNING')

  const handlePriceRowsChange = (idx?: number) => {
    const index = idx ?? activeIndex

    const parent = rootRows.find((row) => row.idx === index)
    const rowId = parent?.rowId

    if (index === -1) setRowsToRender(priceRows)
    else if (parent && rowId) {
      const rowChildren = retrieveChildren(rowId, [{ ...parent, id: rowId }, ...priceRows])
      const rowsToRender = priceRows.map((r) => {
        if (rowChildren.findIndex((ch) => ch.rowId === r.rowId) !== -1) return { ...r, hidden: false }

        else return { ...r, hidden: true }
      })
      setRowsToRender(rowsToRender)
    }
    else setRowsToRender([])
  }

  const handleIdxChange = (idx: number) => {
    setActiveIndex(idx)
    handlePriceRowsChange(idx)
  }

  useEffect(() => {
    if (!fetching) setRowsToRender(priceRows)
  }, [fetching])

  useEffect(() => {
    if (!fetching) {
      handlePriceRowsChange()
    }
  }, [priceRows])

  const addRootRow = (name = '') => {
    setPriceRows((rows) => rows.concat({ expanded: false, rowId: nanoid(), name, idx: rows.length }))
  }

  const handleXLSXProcess = (rows: PriceBreakdownRow[]) => {

    if (gridMode === GRID_MODE.BIDDER) {
      const isValid = isUploadValid(priceRows, rows)

      if (!isValid) return toastr.error(t('Error'), t('XLSX_upload_structure_mismatch'))

    }
    if (!uploadXlsxDisabled) toastr.success(t('Success'), t('XSLX_upload_success'))


    for (let i = 0; i < rows.length; i++) {
      rows[i].expanded = priceRows[i]?.expanded ? true : false
      if (gridMode === GRID_MODE.BIDDER) {
        // Dont allow to change the name in pb if bidder mode. We're matching it with ids. 
        // So just keep the original name
        // Name validation is not precise, eg.:
        // 'Odlučovač kalu a bublin s magnetem   G5/4\"  Flamcovent clean smart  \r↵'
        // Is NOT equal to 
        // 'Odlučovač kalu a bublin s magnetem   G5/4\"  Flamcovent clean smart  ↵'
        rows[i].name = priceRows[i].name ?? rows[i].name
      }
    }

    setPriceRows(rows)
  }

  useEffect(() => {
    // Reindex
    if (!fetching) setPriceRows(priceRows.map((row, idx) => ({ ...row, idx })))
  }, [rootRows.length])


  if (fetching || unitOptionsFetching) return <Loader />



  const maxIndent = getSortedPriceRows(priceRows).reduce((a, b) => Math.max(a, b.indent), 0) + 1
  const expandSpecificLevel = (level: number) => {
    const toRender = flatten(getSortedPriceRows(rowsToRender).filter((r) => !r.hidden).map((r) => retrieveChildren(r.rowId, rowsToRender)))

    setRowsToRender(getSortedPriceRows(rowsToRender).map((r) => {
      const rowIndex = toRender.findIndex((row) => r.rowId === row.rowId)

      if (rowIndex === -1) r.hidden = true
      else {
        if (r.indent <= level) {
          r.expanded = true
          r.hidden = false
        }
        else {
          r.hidden = true
          r.expanded = false
        }
      }

      return r
    }))
  }

  const isEdit = !!crudModalProps.row

  return (
    <>
      <RightFloatedRow>
        <Col lg='12'>
          <DownloadXLSX priceRows={priceRows} />
          <UploadXLSX disabled={uploadXlsxDisabled} onProcessed={handleXLSXProcess} />
        </Col>
      </RightFloatedRow>
      <Formik
        enableReinitialize
        onSubmit={async ({ sheetName }, { resetForm }) => {
          if (isEdit) setPriceRows((rows) => rows.map((r) => r.idx === crudModalProps.row?.idx ? { ...r, name: sheetName } : r))
          else {
            handleIdxChange(priceRows.length)

            addRootRow(sheetName)
          }

          setCrudModalProps({ isOpen: false, row: null })
          resetForm()
        }}
        initialValues={{
          sheetName: crudModalProps.row?.name ?? ''
        }}
        render={({ submitForm, handleChange, values }) => {
          return (
            <Modal
              toggle={() => setCrudModalProps(({ isOpen }) => ({ isOpen: !isOpen }))}
              isOpen={crudModalProps.isOpen}
            >
              <ModalHeader>
                {isEdit ? `${t('PriceBreakdown_modal_header_edit')} ${crudModalProps.row?.name || ''}` : t('PriceBreakdown_modal_header_add_new')}
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label>{t('Global_name')}</Label>
                  <Input
                    value={values.sheetName}
                    onChange={handleChange}
                    bsSize='lg'
                    name='sheetName'
                    type='text'
                    placeholder={t('Global_enter_value')} />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type='button' onClick={() => {
                  if (isEdit) {
                    setPriceRows((rows) =>
                      rows.map((r) => r.idx === crudModalProps.row?.idx ? null : r).filter((r) => !!r) as PriceBreakdownRow[]
                    )
                    setActiveIndex(-1)
                  }

                  setCrudModalProps({ isOpen: false, row: null })
                }} color={isEdit ? 'danger' : 'secondary'}>{t(isEdit ? 'Global_delete' : 'Global_cancel')}</Button>
                <Button onClick={submitForm} color='success'>{t('Global_save')}</Button>
              </ModalFooter>
            </Modal>
          )
        }}
      />
      <Pagination>
        {new Array(maxIndent).fill(null).map((_, i) => {
          return <PaginationItem type='button' key={i} onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            expandSpecificLevel(i)
          }}>
            <PaginationLink>{i + 1}</PaginationLink>
          </PaginationItem>
        })}
      </Pagination>

      <Pagination>
        {rootRows.map((row) => {
          let rowName: string | JSX.Element = <span>&nbsp;</span>

          if (row?.name?.length && row.name.length > 30) rowName = `${row.name.substr(0, 30)}...`
          else if (row?.name) rowName = row.name

          return (
            <PaginationItem
              onDoubleClick={() => {
                if (readOnly) return

                if (gridMode !== GRID_MODE.DEFAULT || tenderStatus !== 'PREPARATION' && tenderStatus !== 'ANOTHER_ROUND') return

                if (row.idx >= 0) setCrudModalProps({ isOpen: true, row })
              }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                handleIdxChange(row.idx)
              }}
              active={row.idx === activeIndex}
              key={row.idx}
            >
              <PaginationLink title={row.name}>
                {rowName}
              </PaginationLink>
            </PaginationItem>
          )
        })}
        {gridMode === GRID_MODE.DEFAULT &&
          <Pagination
            disabled={tenderStatus !== 'PREPARATION' && tenderStatus !== 'ANOTHER_ROUND' || readOnly}
            onClick={() => setCrudModalProps({ isOpen: true, row: null })} active={false} key={-2}
          >
            <AddBtnPaginationLink disabled={readOnly}>
              <CenteredIcon icon={faPlusSquare as any} />
            </AddBtnPaginationLink>
          </Pagination>
        }
      </Pagination>

      <ReactGrid
        canChangeCells={!readOnly}
        unitOptions={unitOptionsData?.unitOptions ?? []}
        priceRows={rowsToRender}
        parentId={activeIndex === -1
          ? undefined
          : rootRows.find((row) => row.idx === activeIndex)?.rowId}
      />
    </>
  )
}

export default PriceBreakdown