import React from 'react'
import {
  Cell,
  CellTemplate,
  Uncertain,
  Compatible,
  getCellProperty,
  UncertainCompatible,
  keyCodes,
  isNavigationKey,
  inNumericKey,
  isAllowedOnNumberTypingKey,
  isNumpadNumericKey,
} from '@silevis/reactgrid'
import { numberFormat as globalNumberFormat } from '../../../utils'

export interface IndicatorCell extends Cell {
  type: 'number'
  value: number
  format?: Intl.NumberFormat
  nanToZero?: boolean
  hideZero?: boolean
  averageValue?: number
}

export class IndicatorCellTemplate implements CellTemplate<IndicatorCell> {
  getCompatibleCell(uncertainCell: Uncertain<IndicatorCell>): Compatible<IndicatorCell> {
    let value: number
    try {
      value = getCellProperty(uncertainCell, 'value', 'number')
    } catch (error) {
      value = NaN
    }

    const numberFormat = uncertainCell.format || globalNumberFormat
    const displayValue = uncertainCell.nanToZero && Number.isNaN(value) ? 0 : value
    const text =
      Number.isNaN(displayValue) || (uncertainCell.hideZero && displayValue === 0)
        ? ''
        : numberFormat.format(displayValue)

    return { ...uncertainCell, value: displayValue, text }
  }

  handleKeyDown(
    cell: Compatible<IndicatorCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
  ): { cell: Compatible<IndicatorCell>; enableEditMode: boolean } {
    if (isNumpadNumericKey(keyCode)) keyCode -= 48
    const char = String.fromCharCode(keyCode)
    if (!ctrl && !alt && !shift && (inNumericKey(keyCode) || isAllowedOnNumberTypingKey(keyCode))) {
      const value = Number(char)
      if (Number.isNaN(value) && isAllowedOnNumberTypingKey(keyCode))
        return {
          cell: { ...this.getCompatibleCell({ ...cell, value }), text: char },
          enableEditMode: true,
        }
      return { cell: this.getCompatibleCell({ ...cell, value }), enableEditMode: true }
    }
    return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER }
  }

  update(
    cell: Compatible<IndicatorCell>,
    cellToMerge: UncertainCompatible<IndicatorCell>,
  ): Compatible<IndicatorCell> {
    return this.getCompatibleCell({ ...cell, value: cellToMerge.value })
  }

  private getTextFromCharCode = (cellText: string): string => {
    switch (cellText.charCodeAt(0)) {
      case keyCodes.DASH:
      case keyCodes.FIREFOX_DASH:
      case keyCodes.SUBTRACT:
        return '-'
      case keyCodes.COMMA:
        return ','
      case keyCodes.PERIOD:
      case keyCodes.DECIMAL:
        return '.'
      default:
        return cellText
    }
  }

  getClassName(cell: Compatible<IndicatorCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : ''
  }

  render(
    cell: Compatible<IndicatorCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<IndicatorCell>, commit: boolean) => void,
  ): React.ReactNode {
    let background = 'transparent'

    if (cell.averageValue) {


      if (cell.value <= 0.85 * cell.averageValue) {
        background = 'lawngreen'
      }
      else if (cell.value >= 1.15 * cell.averageValue) {
        background = 'red'
      }
    }

    if (!isInEditMode) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            textAlign: 'right',
            verticalAlign: 'center',
            lineHeight: '50px',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 8,
              left: 5,
              width: 10,
              height: 10,
              background,
              borderRadius: 10,
            }}
          />
          {cell.text}
          {/* <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: `${Math.ceil(Math.min(Math.max(cell.value, 0), 100))}%`,
              height: 4,
              background: 'linear-gradient(90deg, rgba(255,0,0,1) 0%,  rgba(255,228,0,1) 100%)',
            }}
          /> */}
        </div>
      )
    }

    const locale = cell.format
      ? cell.format.resolvedOptions().locale
      : window.navigator.languages[0]
    const format = globalNumberFormat

    return (
      <input
        inputMode="decimal"
        ref={(input) => {
          if (input) {
            input.focus()
            input.setSelectionRange(input.value.length, input.value.length)
          }
        }}
        defaultValue={
          Number.isNaN(cell.value) ? this.getTextFromCharCode(cell.text) : format.format(cell.value)
        }
        onChange={(e) =>
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: parseFloat(e.currentTarget.value.replace(/,/g, '.')),
            }),
            false,
          )
        }
        onBlur={(e) =>
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: parseFloat(e.currentTarget.value.replace(/,/g, '.')),
            }),
            true,
          )
        }
        onKeyDown={(e) => {
          if (
            inNumericKey(e.keyCode) ||
            isNavigationKey(e.keyCode) ||
            isAllowedOnNumberTypingKey(e.keyCode)
          )
            e.stopPropagation()
          if (
            (!inNumericKey(e.keyCode) &&
              !isNavigationKey(e.keyCode) &&
              !isAllowedOnNumberTypingKey(e.keyCode)) ||
            e.shiftKey
          )
            e.preventDefault()
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
      />
    )
  }
}
