import { useEffect, useState } from 'react'
import client, { Query } from '../api'
import Cookie from 'js-cookie'
import useRouter from './useRouter'

export default () => {
  const [user, setUser] = useState()

  const runQuery = async () => {
    const { data, errors, loading } = await client.query({ query: Query.ME })

    if (!data.me) {
      Cookie.remove('token')
      window.location = '/'
    }
    setUser(data.me)
  }

  if (!user) runQuery()

  return { user }
}
