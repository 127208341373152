import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'
import ReduxToastr from 'react-redux-toastr'
import client from './api'
import store from './redux/store/index'
import Routes from './routes/Routes'
// import { initAuth } from './services/authService'
import Loader from './components/Loader.tsx'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import { useTranslation } from 'react-i18next'
require('dotenv').config()
const App = () => {
  const { t } = useTranslation()
  const [initialized, setInitialized] = useState(false)

  document.title = t('Page_title')

  useEffect(() => {
    const init = async () => {
      // await initAuth()
      setInitialized(true)
    }

    init()
  }, [])

  return initialized ? (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Routes />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="bottom-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </ApolloProvider>
    </Provider>
  ) : (
    <Loader />
  )
}

export default App
