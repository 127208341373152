import { useEffect, useState } from 'react'
import { GRID_MODE } from '../../../pages/tendering/components/PriceBreakdown'

import { Column } from '@silevis/reactgrid'
import { TenderStatus } from '../../../utils/types'

interface IDataColumnsProps {
  width: number
  gridMode: GRID_MODE
  tenderStatus?: TenderStatus | null
  columnWidths: {
    name: number
    unit: number
    quantity: number
    price: number
    controls: number
  }
  canChangeCells: boolean
}

export const useDataColumns = ({
  gridMode,
  tenderStatus,
  width,
  columnWidths,
  canChangeCells
}: IDataColumnsProps): Column[] => {
  const userCustomSizeSet = !!localStorage.getItem('breakdownColumnWidths')


  const [dataColumns, setDataColumns] = useState<Column[]>([
    {
      columnId: 'name',
      width: columnWidths.name,
      resizable: true,
    },
    { columnId: 'unit', width: columnWidths.unit, resizable: true },
    { columnId: 'quantity', width: columnWidths.quantity, resizable: true },
    ...(gridMode === GRID_MODE.BIDDER
      ? [{ columnId: 'price', width: columnWidths.price, resizable: true }]
      : []),
    ...(gridMode === GRID_MODE.DEFAULT && canChangeCells
      ? [{ columnId: 'controls', width: columnWidths.controls, resizable: true }]
      : []),
  ])

  useEffect(() => {
    setDataColumns((dc) => dc.map((d) => ({ ...d, width: columnWidths[d.columnId] })))
  }, [columnWidths])

  // useEffect(() => {
  //   if (!userCustomSizeSet) {
  //     setDataColumns((dCols) => {
  //       const fixedWidth = dCols.reduce((a, v, i) => (a += i > 0 ? v.width || 0 : 0), 0)

  //       let newDCols = dCols.map((col, i) => ({
  //         ...col,
  //         width: i === 0 ? width - fixedWidth : col.width,
  //       }))

  //       return newDCols
  //     })
  //   }
  // }, [width, gridMode])

  return dataColumns
}

export default useDataColumns
