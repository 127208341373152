import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Alert, Spinner } from 'reactstrap'

import * as yup from 'yup'
import { Formik, Form } from 'formik'
import Input from '../../components/form/FormikInput'

import { useMutation } from '@apollo/react-hooks'
import { Mutation } from '../../api/gql'
import { initAuth } from '../../services/authService'
import { useErrorMessage } from '../../hooks'

import { useTranslation } from 'react-i18next'
import logo from '../../assets/img/logo_signin.png'
import styled from 'styled-components'
import LanguageSelect from '../../components/LanguageSelect'

const Logo = styled.img.attrs((props) => ({
  src: logo,
}))`
  height: 200px;
`

const SignIn = ({ history }) => {
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
    remember: false,
  })

  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('Sign_in_form_validation_email_format'))
      .required(t('Sign_in_form_validation_email_required')),
    password: yup.string().required(t('Sign_in_form_validation_password_required')),
  })

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('user') as string)
      if (user) setInitialValues({ ...user, remember: true })
    } catch (_e) { }
  }, [])

  const [signIn, { error }] = useMutation(Mutation.SIGN_IN)
  const [formError, setFormError] = useErrorMessage(error)

  return (
    <React.Fragment>
      <div className="text-center">
        <Logo />
        <h2>{t('Sign_in_heading')}</h2>
        <p className="lead">{t('Sign_in_subheading')}</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              enableReinitialize
              validateOnMount={false}
              onSubmit={async (values) => {
                console.log('submit')
                setFormError?.('')
                try {
                  await signIn({ variables: values })
                  // await initAuth()
                  sessionStorage.removeItem('trialModalClosed')
                  window.location.href = '/'

                  if (values.remember) {
                    localStorage.setItem(
                      'user',
                      JSON.stringify({ email: values.email, password: values.password }),
                    )
                  } else localStorage.removeItem('user')
                } catch (e) {
                  //setFormError(e.message.replace('GraphQL error: ', ''))
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Input
                    label={t('Sign_in_form_email_label')}
                    bsSize="lg"
                    type="email"
                    name="email"
                    placeholder={t('Sign_in_form_email_placeholder')}
                    debounceTime={0}
                  />
                  <Input
                    label={t('Sign_in_form_password_label')}
                    bsSize="lg"
                    type="password"
                    name="password"
                    placeholder={t('Sign_in_form_password_placeholder')}
                    debounceTime={0}
                  />
                  <Input label={t('Sign_in_form_remember_label')} type="checkbox" name="remember" />

                  {formError && (
                    <div>
                      <Alert color="danger">
                        <div className="alert-message">{formError}</div>
                      </Alert>
                    </div>
                  )}
                  <div className="text-center mt-3">
                    <Button disabled={isSubmitting} color="primary" size="lg" type="submit">
                      {isSubmitting && (
                        <Spinner
                          size="sm"
                          label={t('Sign_in_form_sign_in_btn_spinner_label')}
                          style={{ verticalAlign: 'middle' }}
                        />
                      )}{' '}
                      {t('Sign_in_form_sign_in_btn_label')}
                    </Button>
                    <div className="mt-3">
                      <Link to="/auth/signup">
                        <Button type="button" color="link">
                          {t('Sign_in_form_sign_up_link')}
                        </Button>
                      </Link>
                      <br />
                      <Link to="/auth/reset">
                        <Button type="button" color="link">
                          {t('Sign_up_form_forgot_password_link')}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
      <div className={'text-center'}>
        <LanguageSelect />
      </div>
    </React.Fragment>
  )
}

export default SignIn
