import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { useTranslation } from 'react-i18next'

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const SizeFormatter = (props) => (
  <DataTypeProvider formatterComponent={({ value }) => formatBytes(value)} {...props} />
)

export const DateFormatter = (props) => {
  const { t } = useTranslation()
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => t('formatter_date2', { date: value })}
      {...props}
    />
  )
}
export const CustomFormatter = ({ formatter, ...props }) => (
  <DataTypeProvider formatterComponent={formatter} {...props} />
)
