import React, { useState, useEffect } from 'react'
import pt from 'prop-types'
import { HOST_URL } from '../../utils'
import Grid from '../grid'
import { useTranslation } from 'react-i18next'
import FileIcon from './FileIcon'
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons'
import LoadingTransparent from '../Loading'

const FileGrid = ({ rows, columns, ...restProps }) => {
  const { t } = useTranslation()

  const COLUMNS = [
    { title: t('FileManager_file_name_label'), name: 'filename' },
    { title: t('FileManager_uploaded_by_label'), name: 'uploadedBy', width: 150 },
    { title: t('FileManager_created_label'), name: 'createdAt', width: 160 },
    { title: t('FileManager_size_label'), name: 'size', width: 100 },
  ]

  const _rows = rows
    ? rows.map((f) => ({
        ...f,
        uploadedBy: f.user ? f.user.name : f.bidder ? f.bidder.name : 'system',
      }))
    : []

  return (
    <>
      <Grid
        {...restProps}
        noDataMessage={t('File_manager_No_documents_selected')}
        columns={columns || COLUMNS}
        rows={_rows}
        formatters={[
          {
            formatter: ({ value, row }) => (
              <a className="text-dark" href={`${HOST_URL}/download/${row.id}`}>
                <FileIcon filename={row.filename} /> {value}
              </a>
            ),
            for: ['filename'],
          },
        ]}
        defaultSorting={[{ columnName: 'filename', direction: 'asc' }]}
      />
    </>
  )
}

export default FileGrid
