import React from 'react'
import { FileManager } from '../../../components/file-manager'
import { useQuery } from '@apollo/react-hooks'
import { Query } from '../../../api'

export default () => {
  return (
    <FileManager
      onRemoved={(s) => {
        // setTenderFiles({
        //   variables: {
        //     files: [...values.files.map((f) => f.id)].filter(
        //       (f) => s.indexOf(f) === -1,
        //     ),
        //   },
        // })
      }}
      showFileSelect={false}
      directory={'general'}
    />
  )
}
