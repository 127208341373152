import React from 'react'
import { FormGroup, Label } from 'reactstrap'
import { useTranslation } from 'react-i18next'

export default ({ onChange, round, tender }) => {
  const { t } = useTranslation()
  return (
    <FormGroup style={{ display: 'contents' }}>
      <Label className="m-2">{t('Procurement_tenders_round_label', 'Round')}:</Label>
      <select
        className="form-control col form-control-lg"
        value={round || tender.round}
        onChange={onChange}
      >
        {Array.from(Array(tender.round)).map((_, i) => (
          <option key={i} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </select>
    </FormGroup>
  )
}
