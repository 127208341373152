import React, { useCallback } from 'react'
import pt from 'prop-types'
import { Button, Col } from 'reactstrap'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import SearchInput from '../../form/SearchInput'
import cn from 'classnames'

import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'

const ActionBar = ({
  showSearch,
  onSearchChange,
  actionButton,
  actionButtonRight,
  onNew,
  onDelete,
  newButtonText,
  className,
}) => {
  const { t } = useTranslation()
  const onChangeDebounced = useCallback(debounce(onSearchChange, 200), [onSearchChange])

  return (
    <div className={cn('form-row', className)}>
      {newButtonText && (
        <Col md="auto" sm={12}>
          <Button size="lg" color="primary" className="btn-pill" onClick={onNew}>
            <Icon icon={faPlus} />
            {` ${newButtonText || 'New'}`}
          </Button>
        </Col>
      )}
      {actionButton && (
        <Col md="auto" sm={12}>
          {actionButton}
        </Col>
      )}
      {showSearch && (
        <Col>
          <SearchInput
            bsSize="lg"
            placeholder={t('ActionBar_search_placeholder')}
            onChange={onChangeDebounced}
          />
        </Col>
      )}
      {actionButtonRight && <Col md="auto">{actionButtonRight}</Col>}
    </div>
  )
}

ActionBar.propTypes = {
  onSearchChange: pt.func,
  onNew: pt.func,
  onDelete: pt.func,
  newButtonText: pt.string,
  showSearch: pt.bool,
}

ActionBar.defaultProps = {
  showSearch: true,
}

export default ActionBar
