import React from 'react'
import moment from 'moment'

import Wrapper from '../components/Wrapper'
import Sidebar from '../components/Sidebar'
import Main from '../components/Main'
import Navbar from '../components/Navbar'
import Content from '../components/Content'
import Footer from '../components/Footer'
import TrialModal from '../components/TrialModal.tsx'

import Loader from '../components/Loader.tsx'

import { useAuth, useRouter } from '../hooks'
import Subscription from '../pages/payment/subscription'

const Dashboard = ({ children }) => {
  const { user } = useAuth()
  if (user) {
    const daysLeft = moment(user.activeSubscription.expiresAt).diff(moment(), 'day')

    if (daysLeft <= 0) {
      return (
        <Wrapper>
          <Sidebar />
          <Main>
            <Navbar activeSubscription={user.activeSubscription} />
            <Subscription />
            <TrialModal daysLeft={daysLeft} user={user} />
            <Footer />
          </Main>
        </Wrapper>
      )
    }
    return (
      <React.Fragment>
        <Wrapper>
          <Sidebar />
          <Main>
            <Navbar activeSubscription={user.activeSubscription} />
            <Content>{children}</Content>
            <Footer />
          </Main>
        </Wrapper>
        {/* <Settings /> */}

        {daysLeft < 14 && <TrialModal daysLeft={daysLeft} user={user} />}
      </React.Fragment>
    )
  }

  return <Loader />
}

export default Dashboard
