// import React, { useState } from 'react'
// import { Row, Col } from 'reactstrap'

import TenderForm from './components/TenderForm.tsx'
// import TenderStatus from './components/TenderStatus'

const Tender = () => {
  return <TenderForm />
}

export default Tender
