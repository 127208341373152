import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Query } from '../../api'
import Loader from '../../components/Loader.tsx'
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Pie } from 'react-chartjs-2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  faClock,
  faExclamationTriangle,
  faHandHoldingUsd,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons'

const CardNumber = styled.h3`
  font-size: 60px;
  font-weight: bold;
  float: left;
  margin-bottom: 0;
`
const CardHeading = styled.h4`
  display: inline-block;
`
const CardHeadingWrapper = styled.div``

const CardIcon = styled(FontAwesomeIcon)`
  font-size: 60px;
  font-weight: bold;
  display: block;
  float: right;
`

export default () => {
  const { t } = useTranslation()

  const { data, loading, error } = useQuery(Query.DASHBOARD)

  const [deadlines, setDeadlines] = useState([])

  const [tenderChartData, setTenderChartData] = useState([])
  const [options] = useState({
    legend: {
      // display: false,
    },
    tooltips: {
      enabled: false,
    },
  })

  useEffect(() => {
    if (data && data.me) {
      const project = data.me.currentProject
      if (project) {
        setTenderChartData({
          labels: [
            t('Dashboard_screen_tenders_chart_awarded_label', 'Awarded') +
            ' (' +
            project.tenders.filter((t) => t.status === 'AWARDED').length +
            ')',
            t('Dashboard_screen_tenders_chart_running_label', 'Running') +
            ' (' +
            project.tenders.filter((t) => t.status === 'RUNNING').length +
            ')',
            t('Dashboard_screen_tenders_chart_preparation_label', 'In preparation') +
            ' (' +
            (project.tenders.filter((t) => t.status === 'PREPARATION').length +
              project.tenders.filter((t) => t.status === 'ANOTHER_ROUND').length) +
            ')',
          ],
          datasets: [
            {
              data: [
                project.tenders.filter((t) => t.status === 'AWARDED').length,
                project.tenders.filter((t) => t.status === 'RUNNING').length,
                project.tenders.filter((t) => t.status === 'PREPARATION').length +
                project.tenders.filter((t) => t.status === 'ANOTHER_ROUND').length,
              ],
              backgroundColor: ['#f2a43a', '#5fc27e', '#a180da'],
              borderWidth: 0,
            },
          ],
        })

        if (data.me.currentProject)
          setDeadlines(data.me.currentProject.tenders.map((t) => new Date(t.submissionDate)))
      }
    }
  }, [data])

  const datesDiff = (d1, d2) => {
    return Math.floor((d1 - d2) / (1000 * 60 * 60 * 24))
  }


  if (loading) return <Loader />
  if (data && !data.me.currentProject) return <p>{t('Please_select_project')}</p>
  if (!data) return null
  const project = data.me.currentProject

  return (
    <div>
      <h1>{t('Dashboard_screen_dashboard_label')}</h1>
      <>
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <CardHeadingWrapper>
                  <CardNumber className={' text-left mr-3 text-warning'}>
                    {project.tenders.length}
                  </CardNumber>
                  <CardHeading className={'mt-2 mb-4 pb-1'}>
                    {t('Dashboard_screen_tenders_label', 'Tenders')}
                  </CardHeading>
                  <CardIcon icon={faFileAlt} />
                </CardHeadingWrapper>
                <hr />
                <Pie data={tenderChartData} options={options} />
              </CardBody>
            </Card>
          </Col>

          <Col>
            <Card>
              <CardBody>
                <CardNumber className={' text-left mr-3 text-success'}>
                  {project.organization.bidders.length}
                </CardNumber>
                <CardHeadingWrapper>
                  <div className="d-inline-block">
                    <CardHeading className={'mt-2'}>
                      {t('Dashboard_screen_bidders_label', 'Bidders')}
                    </CardHeading>
                    <div>{t('Dashboard_screen_bidders_text', "in company's database")}</div>
                  </div>
                  <CardIcon icon={faHandHoldingUsd} />
                </CardHeadingWrapper>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardNumber className={' text-left mr-3 text-danger'}>
                  {
                    deadlines.filter(
                      (d) => datesDiff(d, new Date()) < 7 && datesDiff(d, new Date()) > 0,
                    ).length
                  }
                </CardNumber>

                <CardHeadingWrapper>
                  <div className="d-inline-block">
                    <CardHeading className={'mt-2'}>
                      {t('Dashboard_deadlines_label', 'Deadlines')}
                    </CardHeading>
                    <div>{t('Dashboard_deadlines_next_7_days_text', 'next 7 days')}</div>
                  </div>
                  <CardIcon icon={faExclamationTriangle} />
                </CardHeadingWrapper>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardNumber className={' text-left mr-3 text-dark'}>
                  {
                    deadlines.filter(
                      (d) => datesDiff(d, new Date()) < 30 && datesDiff(d, new Date()) > 0,
                    ).length
                  }
                </CardNumber>
                <CardHeadingWrapper>
                  <div className="d-inline-block">
                    <CardHeading className={'mt-2'}>
                      {t('Dashboard_deadlines_label', 'Deadlines')}
                    </CardHeading>
                    <div>{t('Dashboard_deadlines_next_30_days_text', 'next 30 days')}</div>
                  </div>
                  <CardIcon icon={faClock} />
                </CardHeadingWrapper>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    </div>
  )
}
