import React, { useCallback, useEffect } from 'react'
import { Column, Id, ReactGrid, MenuOption, CellLocation, Row } from '@silevis/reactgrid'
import { FC, useState } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import {
  ExtraChevronCell,
  ExtraChevronCellTemplate,
} from './cellTemplates/ExtraChevronCellTemplate'
import { ExtraNumberCellTemplate, ExtraNumberCell } from './cellTemplates/ExtraNumberCellTemplate'
import { ExtraTextCellTemplate } from './cellTemplates/ExtraTextCellTemplate'
import { PriceBreakdownCellChange } from './types/PriceBreakdownCellChange'

import { PriceBreakdownGridProps } from './types/PriceBreakdownGridProps'

import useDataRows from './functions/useDataRows'

import { useContext } from 'react'
import { PriceBreakdownContext } from '../../pages/tendering/components/TenderForm'

import { nanoid } from 'nanoid'
import { NonEditableChevronCellTemplate } from '../PriceBreakdownGrid/cellTemplates/NonEditableChevronCell'
import { GRID_MODE } from '../../pages/tendering/components/PriceBreakdown'
import styled from 'styled-components'
import { ExtraDropdownCellTemplate } from './cellTemplates/ExtraDropdownCellTemplate'
import useDataColumns from './functions/useDataColumns'
import { PriceBreakdownRow } from './types/PriceBreakdownRow'
import { useSize } from 'react-use'
import { getPriceRowChildren } from '../utils'
import getDescendantIds from './functions/getDescendantIds'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

const IconWrapper = styled.div`
    height: 30px;
    width: 50px;
    margin: auto;
    cursor: pointer;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    justify-content: center;
    display: flex;
    align-items: center;
    &:hover > svg {
      opacity: 0.8;
    }
`

export const retrieveChildren = (id: Id, priceRows: PriceBreakdownRow[]): PriceBreakdownRow[] => {
  if (!id) return []

  const row = priceRows.find((row) => row.rowId === id);
  if (!row) {
    return [];
  }
  const children = priceRows.filter((row) => row.parentId === id);
  return [row, ...children.flatMap((child) => retrieveChildren(child.rowId, priceRows))];
};


const registerTooltipHandlers = () => {
  // TODO (?) - if this would cause some performance issues, then it's possible to optimalize
  // Take changes as param and take only new divs, instead of all of them
  // Or figure out different solution
  let chevronColumns: HTMLDivElement[] = Array.prototype.slice.call(document.querySelectorAll<HTMLDivElement>('.rg-extraChevron-cell'))
  chevronColumns.forEach((column) => {
    column.removeEventListener('mouseover', () => { })
    column.addEventListener('mouseover', (e) => {
      // @ts-ignore
      e.target.title = e.target.innerText.replace('❯\n', '')
    })
  })
}

const PriceBreakdownGrid: FC<PriceBreakdownGridProps> = ({
  createSiblingRowKeyCombination,
  createChildRowKeyCombination,
  expandChildrenKeyCombination,
  createSiblingRowAboveKeyCombination,
  priceRows,
  parentId,
  unitOptions,
  canChangeCells
}) => {

  const { t } = useTranslation()

  const pbRef = useRef<any>()
  const [undoRedoStack, setUndoRedoStack] = useState<{ stack: [PriceBreakdownRow[]], currentIndex: number }>({ stack: [priceRows], currentIndex: 0 })

  const context = useContext(PriceBreakdownContext)
  const { setPriceRows, gridMode, tenderStatus } = context

  const [openedDropdownId, setOpenedDropdownId] = useState<Id | undefined>()
  const [copiedRowId, setCopiedRowId] = useState<Id | null>(null);

  const [tooltipsInitiallyRegistered, setTooltipsInitiallyRegistered] = useState<boolean>(false);

  const [focusedCell, setFocusedCell] = useState<CellLocation>()

  const createSibling = (parentId?: Id, rowId?: Id, direction: 'above' | 'bellow' = 'bellow') => {
    if (!canChangeCells) return

    const newRowId = nanoid()
    const row = priceRows.find((row) => row.rowId === rowId)
    let idx = 0

    if (row?.idx) {
      idx = direction === 'bellow' ? row.idx + 1 : row.idx
    }

    let newRows = priceRows

    newRows.splice(idx ?? priceRows.length, 0, {
      rowId: newRowId,
      parentId,
      idx: idx ?? priceRows.length
    })

    if (row) {
      setPriceRows(newRows.map((row, idx) => ({ ...row, idx }))) // Reindex
    }

  }

  const changeRow = (rowId: Id, row: Partial<PriceBreakdownRow>) => {
    setPriceRows((rows) =>
      rows.map((r) =>
        r.rowId === rowId ?
          ({ ...r, ...row })
          : r
      ))
  }

  const handlePaste = useCallback((e: Event) => {
    if (!focusedCell || !canChangeCells) return

    e.stopImmediatePropagation()
    e.preventDefault()

    // @ts-ignore
    const clipBoardText: string = e.clipboardData.getData('text')

    if (clipBoardText) {
      let linesOfText = clipBoardText.split('\n')

      if (linesOfText.length <= 1) {
        changeRow(focusedCell.rowId, { [focusedCell.columnId]: linesOfText[0] })

        return
      }

      if (focusedCell.columnId === 'name') {

        // Replace " char as it for some reason gets pastend into first and last element
        // changeRow(focusedCell.rowId, { name: linesOfText[0].replace('"', '') })
        // linesOfText[linesOfText.length - 1] = linesOfText[linesOfText.length - 1].slice(0, -1)

        // linesOfText.splice(0, 1)
      } else if (focusedCell.columnId === 'quantity') {
        linesOfText = linesOfText.map((line) => parseFloat(line.replace('\r', ''))) as any
      }

      const rowDirectChildren = getPriceRowChildren(priceRows, [focusedCell.rowId]).filter((r) => r.parentId === focusedCell.rowId)

      for (let i = 0; i < linesOfText.length; i++) {
        if (rowDirectChildren[i]) {
          changeRow(rowDirectChildren[i].rowId, { ...rowDirectChildren[i], [focusedCell.columnId]: linesOfText[i] })
        } else {
          addChildRow(focusedCell.rowId, { [focusedCell.columnId]: linesOfText[i] })
        }
      }
    }
  }, [focusedCell, canChangeCells])

  useEffect(() => {
    window.addEventListener('paste', handlePaste)

    return () => {
      window.removeEventListener('paste', handlePaste)
    }
  }, [handlePaste])



  const onChange = (changes) => {
    // If user only expanded row (not data change)
    const onlyExpanded = changes?.length === 1 && (changes[0].newCell.isExpanded !== changes[0].previousCell.isExpanded)

    context.onChange(changes)
    if (changes === true) {
      // Changes are not rendered immediately, delay as workaround
      setTimeout(() => {
        registerTooltipHandlers()
      }, 1000)
    }

    else if (!onlyExpanded) {
      // Store new state to undoRedoStack
      const newStack = undoRedoStack.stack

      const newPriceRows = priceRows.map((row) => {

        const idx = changes.findIndex((ch) => ch.rowId === row.rowId)

        if (idx !== -1) return { ...row, name: changes[idx].newCell.text }

        return row
      })

      newStack.push(newPriceRows)

      setUndoRedoStack({ stack: newStack, currentIndex: newStack.length - 1 })
    }

  }

  const removeWithDescendants = (id: Id) => {
    const descendantIds = getDescendantIds(id, priceRows);
    setPriceRows((rows) => rows.filter((row) => !descendantIds.includes(row.rowId) && row.rowId !== id));
  }

  const deleteRows = (rowIds: Id[]) => {
    rowIds.forEach((rowId) => removeWithDescendants(rowId))
  }


  const addChildRow = (rowId: Id, row?: Partial<Row>) => {
    if (!canChangeCells) return

    setPriceRows((rows) => rows.concat({ rowId: nanoid(), parentId: rowId, expanded: true, idx: rows.length, ...row }))
  }

  const dataRows = useDataRows({
    priceRows,
    setPriceRows,
    openedDropdownId,
    gridMode,
    tenderStatus,
    onChange,
    addChildRow,
    unitOptions,
    createSibling,
    canChangeCells,
    expandCellChildren: (location, recursively = true) => {
      const selectedCell = priceRows.find((row) => row.rowId === location.rowId)
      if (selectedCell) {
        const rowsToExpand = recursively ? [selectedCell, ...getPriceRowChildren(priceRows, [location.rowId])] : [selectedCell]

        setPriceRows((priceRows) => priceRows.map((row) => {
          if (rowsToExpand.findIndex((r) => r.rowId === row.rowId) !== -1) {
            return { ...row, expanded: !selectedCell.expanded }
          }
          return row
        }))
      }
    }
  })


  useEffect(() => {
    if (!tooltipsInitiallyRegistered && priceRows && priceRows.length > 1) {
      registerTooltipHandlers()
      setTooltipsInitiallyRegistered(true)
    }

    if (!undoRedoStack.stack[0].length && priceRows.length) setUndoRedoStack({ stack: [priceRows], currentIndex: 0 })
  }, [priceRows])


  const [sizedElement, { width }] = useSize(({ width }) => <div style={{ height: 1 }}> </div>, {
    width: 1250,
    height: 1,
  })

  const defaultColumnWidths = localStorage.getItem('breakdownColumnWidths') ? JSON.parse(localStorage.getItem('breakdownColumnWidths')!) : {
    name: gridMode === GRID_MODE.BIDDER ||
      (tenderStatus !== 'PREPARATION' && tenderStatus !== 'ANOTHER_ROUND')
      ? 900
      : 750,
    unit: 100,
    quantity: 150,
    price: 100,
    controls: 100
  }

  const [columnWidths, setColumnWidths] = useState(defaultColumnWidths)

  const onColumnResized = (columnId: string, width: number, _selectColIds: Id[]) => {
    setColumnWidths((w) => ({ ...w, [columnId]: Math.floor(width) }))
  }
  useEffect(() => {
    if (columnWidths) localStorage.setItem('breakdownColumnWidths', JSON.stringify(columnWidths))
  }, [columnWidths])

  let COLUMNS: Column[] = useDataColumns({
    gridMode,
    tenderStatus,
    width,
    columnWidths,
    canChangeCells
  })


  const rows = [
    {
      rowId: 'header',
      height: 50,
      cells: [
        {
          type: 'header',
          text: t('PRICEBREAKDOWN_COLUMN_NAME'),
          style: { paddingLeft: '20px' },
        },
        {
          type: 'header',
          text: t('PRICEBREAKDOWN_COLUMN_UNIT'),
        },
        {
          type: 'header',
          text: t('PRICEBREAKDOWN_COLUMN_QUANTITY'),
        },
      ],
    },
    ...dataRows,
  ]

  if (gridMode === GRID_MODE.DEFAULT && canChangeCells) {
    // @ts-ignore
    rows[0].cells.push({ type: 'header', text: '' })
  }

  if (gridMode === GRID_MODE.BIDDER) {
    // @ts-ignore
    rows[0].cells.push({ type: 'header', text: t('PRICEBREAKDOWN_COLUMN_PRICE') })
  }


  const cellChangesHandler = (changes: PriceBreakdownCellChange[]) => {
    onChange?.(changes)

    changes.forEach((change) => {
      if (change.type === 'extraDropdown') {
        const newCell = change.newCell
        if (change.previousCell.selectedValue !== change.newCell.selectedValue) {
          setPriceRows((rows) =>
            rows.map((row) =>
              row.rowId === change.rowId ? { ...row, unit: newCell.selectedValue } : row,
            ),
          )
        }

        if (change.previousCell.isOpen !== change.newCell.isOpen) {
          setOpenedDropdownId((change.newCell.isOpen && change.rowId) || undefined)
        }
        return
      }

      if (change.columnId === 'name') {
        const newCell = change.newCell as ExtraChevronCell

        setPriceRows((rows) =>
          rows.map((row) =>
            row.rowId === change.rowId
              ? { ...row, expanded: newCell.isExpanded, name: canChangeCells ? newCell.text : row.name }
              : row,
          ),
        )

        return
      }

      setPriceRows(
        priceRows.map((uRow) =>
          uRow.rowId === change.rowId
            ? {
              ...uRow,
              [change.columnId]: (change.newCell as ExtraNumberCell).value,
            }
            : uRow
        )
      )

    })
  }

  const generateFlatRow = () => {
    onChange(true)
    setPriceRows((rows) => rows.concat({ expanded: false, rowId: nanoid(), name: '', idx: rows.length }))
  }


  const copyRowStructure = (rowsToClone: PriceBreakdownRow[], copiedRowId: Id, idx: number) => {
    const copiedRowParentId = priceRows.find((row) => row.rowId === copiedRowId)?.parentId;
    const idMap: Record<Id, Id | undefined> = {};

    const UNDEFINED = 'UNDEFINED';

    return rowsToClone.map((row) => {
      if (!idMap[row.rowId]) {
        idMap[row.rowId] = nanoid();
      }

      if (!idMap[row.parentId ?? UNDEFINED]) {
        idMap[row.parentId ?? UNDEFINED] = copiedRowParentId === row.parentId ? undefined : nanoid();
      }

      const newIdx = row.rowId === copiedRowId ? idx : row.idx;

      const rowId = idMap[row.rowId];
      const parentId = idMap[row.parentId ?? UNDEFINED];
      return { ...row, id: rowId as Id, rowId: rowId as Id, parentId, idx: newIdx };
    });
  };



  const undo = useCallback(() => {
    if (undoRedoStack.stack[undoRedoStack.currentIndex - 1]) {
      setPriceRows(undoRedoStack.stack[undoRedoStack.currentIndex - 1])
      setUndoRedoStack(({ stack, currentIndex }) => ({ stack, currentIndex: currentIndex - 1 }))
    }

  }, [undoRedoStack])

  const redo = useCallback(() => {
    if (undoRedoStack.stack[undoRedoStack.currentIndex + 1]) {
      setPriceRows(undoRedoStack.stack[undoRedoStack.currentIndex + 1])
      setUndoRedoStack(({ stack, currentIndex }) => ({ stack, currentIndex: currentIndex + 1 }))
    }
  }, [undoRedoStack])


  const undoRedoListener = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key == 'z') {
      redo()
    }
    else if ((event.ctrlKey || event.metaKey) && event.key === "z") {
      undo()
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", undoRedoListener)

    return () => {
      window.removeEventListener('keydown', undoRedoListener)
    }
  }, [undo, redo])

  const getMenuOptions = (rowIds: Id[]): MenuOption[] => {
    const selectedRow = priceRows.find((row) => row.rowId === rowIds[0]);
    if (!selectedRow) return [];

    const rowAboveIdx = priceRows.reduce((a, b) => (b.idx < selectedRow.idx ? Math.max(a, b.idx) : a), 0);
    const rowBelowIdx = priceRows.reduce((a, b) => (b.idx > selectedRow.idx ? Math.min(a, b.idx) : a), priceRows.length);

    const options = [
      {
        id: nanoid(),
        rowId: nanoid(),
        label: 'Expand/Close row',
        handler: () => {
          setPriceRows((rows) => rows.map((r) => r.rowId === selectedRow.rowId ? ({ ...r, expanded: !r.expanded }) : r))
        }
      },
      {
        id: nanoid(),
        rowId: nanoid(),
        label: 'Insert row above',
        handler: () => {
          createSibling(selectedRow.parentId, selectedRow.rowId, 'above')
        }
      },
      {
        id: nanoid(),
        rowId: nanoid(),
        label: 'Insert row bellow',
        handler: () => {
          createSibling(selectedRow.parentId, selectedRow.rowId, 'bellow')
        }
      },
      {
        id: nanoid(),
        rowId: nanoid(),
        label: 'Copy with structure',
        handler: () => {
          setCopiedRowId(rowIds[0]);
        },
      },
      {
        id: nanoid(),
        rowId: nanoid(),
        label: 'Delete',
        handler: () => {
          deleteRows(rowIds);
        },
      },
    ];

    if (copiedRowId) {
      options.push(
        {
          id: nanoid(),
          rowId: nanoid(),
          label: `Paste with structure (above)`,
          handler: () => {
            const copiedRows = copyRowStructure(
              retrieveChildren(copiedRowId, priceRows),
              copiedRowId,
              selectedRow.idx - (selectedRow.idx - rowAboveIdx) / 2
            );
            // modify selected row idx, as we cannot set lower idx for copied row than 0
            if (selectedRow.idx === 0) {
              selectedRow.idx = priceRows.reduce((a, b) => (b.idx > selectedRow.idx ? Math.min(a, b.idx) : a), priceRows.length) / 2
            }

            setPriceRows((rows) => [...rows.map(row => row.rowId === selectedRow.rowId ? selectedRow : row), ...copiedRows]);
            onChange(true)
          },
        },
        {
          id: nanoid(),
          rowId: nanoid(),
          label: `Paste with structure (below)`,
          handler: () => {
            const copiedRows = copyRowStructure(
              retrieveChildren(copiedRowId, priceRows),
              copiedRowId,
              selectedRow.idx + (rowBelowIdx - selectedRow.idx) / 2
            );
            setPriceRows((rows) => [...rows, ...copiedRows]);
            onChange(true)
          },
        }
      );
    }
    return options;
  };

  useEffect(() => {
    if (pbRef?.current) {
      pbRef.current.eventHandlers.pasteHandler = () => { }
    }
  }, [])

  return (
    <>
      {sizedElement}
      {/* @ts-ignore */}
      <ReactGrid
        disableVirtualScrolling
        onColumnResized={onColumnResized}
        onFocusLocationChanged={(cell) => {
          setFocusedCell(cell)
        }}
        enableColumnSelection
        enableRangeSelection
        // enableFillHandle
        onContextMenu={getMenuOptions}
        onCellsChanged={cellChangesHandler}
        enableRowSelection
        ref={pbRef}
        customCellTemplates={{
          extraText: new ExtraTextCellTemplate({
            expandChildrenComb: expandChildrenKeyCombination,
            siblingComb: createSiblingRowKeyCombination,
            childComb: createChildRowKeyCombination,
            siblingAboveComb: createSiblingRowAboveKeyCombination,
          }),
          extraNumber: new ExtraNumberCellTemplate({
            expandChildrenComb: expandChildrenKeyCombination,
            siblingComb: createSiblingRowKeyCombination,
            childComb: createChildRowKeyCombination,
            siblingAboveComb: createSiblingRowAboveKeyCombination,
          }),
          extraChevron: new ExtraChevronCellTemplate({
            expandChildrenComb: expandChildrenKeyCombination,
            siblingComb: createSiblingRowKeyCombination,
            childComb: createChildRowKeyCombination,
            siblingAboveComb: createSiblingRowAboveKeyCombination,
          }),
          nonEditableChevron: new NonEditableChevronCellTemplate({
            expandChildrenComb: expandChildrenKeyCombination,
            siblingAboveComb: createSiblingRowAboveKeyCombination,
          }),
          extraDropdown: new ExtraDropdownCellTemplate({
            expandChildrenComb: expandChildrenKeyCombination,
            siblingComb: createSiblingRowKeyCombination,
            childComb: createChildRowKeyCombination,
            siblingAboveComb: createSiblingRowAboveKeyCombination,
          }),
        }}
        columns={COLUMNS}
        rows={rows}
      />

      {gridMode === GRID_MODE.DEFAULT && canChangeCells && (
        <div>
          <IconWrapper onClick={() => parentId ? addChildRow(parentId) : generateFlatRow()}>
            <Icon icon={faPlusCircle as any} size="lg" color="#7F7F7F" />
          </IconWrapper>
        </div>
      )}
    </>
  )
}

export default PriceBreakdownGrid
