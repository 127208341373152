import {
  CellTemplate,
  Uncertain,
  Compatible,
  getCellProperty,
  isNumpadNumericKey,
  inNumericKey,
  isAllowedOnNumberTypingKey,
  keyCodes,
  UncertainCompatible,
  isNavigationKey,
  NumberCell,
  CellLocation,
  Id,
} from '@silevis/reactgrid';
import { KeyCombination } from '../types/KeyCombination';
import { KeyCombinationComparer } from './KeyCombinationComparer';
import React from 'react'
import { numberFormat as globalNumberFormat } from '../../../utils'

export interface ExtraNumberCell extends Omit<NumberCell, 'type'> {
  type: 'extraNumber';
  createChild?: (location: CellLocation) => void;
  createSibling?: (parentId?: Id, rowId?: Id) => void;
  createSiblingAbove?: (parentId?: Id, rowId?: Id) => void;
  expandChildren?: (location: CellLocation, recursively?: boolean) => void
  location: CellLocation;
  parentId?: Id;
}

export class ExtraNumberCellTemplate extends KeyCombinationComparer implements CellTemplate<ExtraNumberCell> {
  getCompatibleCell(uncertainCell: Uncertain<ExtraNumberCell>): Compatible<ExtraNumberCell> {
    let value: number;
    try {
      value = getCellProperty(uncertainCell, 'value', 'number');
    } catch (error) {
      value = NaN;
    }
    const location: CellLocation = getCellProperty(uncertainCell, 'location', 'object');
    const numberFormat = uncertainCell.format || globalNumberFormat
    const displayValue = uncertainCell.nanToZero && Number.isNaN(value) ? 0 : value;
    const text =
      Number.isNaN(displayValue) || (uncertainCell.hideZero && displayValue === 0)
        ? ''
        : numberFormat.format(displayValue);


    return { ...uncertainCell, value: displayValue, text, location };
  }

  handleKeyDown(
    cell: Compatible<ExtraNumberCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<ExtraNumberCell>; enableEditMode: boolean } {
    if (isNumpadNumericKey(keyCode)) keyCode -= 48;
    const char = String.fromCharCode(keyCode);

    const currentCombination: KeyCombination = { keyCode, ctrl, shift, alt };

    if (this.compareChildRowKeyCombination(currentCombination)) {
      cell.createChild?.(cell.location);
      return { cell, enableEditMode: false };
    }

    if (this.compareSiblingRowKeyCombination(currentCombination)) {
      cell.createSibling?.(cell.parentId, cell.location.rowId);
      return { cell, enableEditMode: false };
    }

    if (this.compareExpandChildrenKeyCombination(currentCombination)) {
      cell.expandChildren?.(cell.location)
      return { cell, enableEditMode: false }
    }


    if (this.compareSiblingRowAboveKeyCombination(currentCombination)) {
      cell.createSiblingAbove?.(cell.parentId, cell.location.rowId)
      return { cell, enableEditMode: false }
    }

    if (!ctrl && !alt && !shift && (inNumericKey(keyCode) || isAllowedOnNumberTypingKey(keyCode))) {
      const value = Number(char);
      if (Number.isNaN(value) && isAllowedOnNumberTypingKey(keyCode))
        return { cell: { ...this.getCompatibleCell({ ...cell, value }), text: char }, enableEditMode: true };
      return { cell: this.getCompatibleCell({ ...cell, value }), enableEditMode: true };
    }
    return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER };
  }

  update(
    cell: Compatible<ExtraNumberCell>,
    cellToMerge: UncertainCompatible<ExtraNumberCell>
  ): Compatible<ExtraNumberCell> {
    return this.getCompatibleCell({ ...cell, value: cellToMerge.value });
  }

  private getTextFromCharCode = (cellText: string): string => {
    switch (cellText.charCodeAt(0)) {
      case keyCodes.DASH:
      case keyCodes.FIREFOX_DASH:
      case keyCodes.SUBTRACT:
        return '-';
      case keyCodes.COMMA:
        return ',';
      case keyCodes.PERIOD:
      case keyCodes.DECIMAL:
        return '.';
      default:
        return cellText;
    }
  };

  getClassName(cell: Compatible<ExtraNumberCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : '';
  }

  render(
    cell: Compatible<ExtraNumberCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<ExtraNumberCell>, commit: boolean) => void
  ): React.ReactNode {
    if (!isInEditMode) {
      return cell.text;
    }

    const locale = cell.format ? cell.format.resolvedOptions().locale : window.navigator.languages[0];
    const format = new Intl.NumberFormat(locale, { useGrouping: false, maximumFractionDigits: 20, minimumFractionDigits: 2 });

    return (
      <input
        inputMode="decimal"
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={Number.isNaN(cell.value) ? this.getTextFromCharCode(cell.text) : format.format(cell.value)}
        onChange={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, value: parseFloat(e.currentTarget.value.replace(/,/g, '.')) }),
            false
          )
        }
        onBlur={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, value: parseFloat(e.currentTarget.value.replace(/,/g, '.')) }),
            true
          )
        }
        onKeyDown={(e) => {
          if (inNumericKey(e.keyCode) || isNavigationKey(e.keyCode) || isAllowedOnNumberTypingKey(e.keyCode))
            e.stopPropagation();
          if (
            (!inNumericKey(e.keyCode) && !isNavigationKey(e.keyCode) && !isAllowedOnNumberTypingKey(e.keyCode)) ||
            e.shiftKey
          )
            e.preventDefault();
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
      />
    );
  }
}
