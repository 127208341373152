import React from 'react'

import { Col, Container, Row } from 'reactstrap'

import Main from '../components/Main'

const Auth = ({ children }) => (
  <>
    <Main className="d-flex w-100">
      <Container className="d-flex flex-column">
        <Row className="h-100">
          <Col className="mx-auto h-100">
            {children}
          </Col>
        </Row>
      </Container>
    </Main>
  </>
)

export default Auth
