import React from 'react'
import { Row, Col } from 'reactstrap'
import * as yup from 'yup'
import Input from './FormikInput'
import DropdownField from './FormikInput'
import { useTranslation } from 'react-i18next'
import { countryCodes, zipRegexes } from './Countries'

const AddressForm = ({ readOnly, name }) => {
  const { t } = useTranslation()

  AddressForm.validationSchemaRequired = yup.object().shape({
    street: yup
      .string()
      .min(3, t('Address_form_street_min'))
      .required(t('Address_form_street_required')),
    city: yup
      .string()
      .min(3, t('Address_form_city_min'))
      .required(t('Address_form_city_required')),

    zip: yup
      .string()
      .required(t('Address_form_zip_required'))
      .test('zip-regex-match', t('Address_form_zip_not_valid'), function (value) {
        let regex = '\\b' + zipRegexes[this.parent.country] + '\\b'
        if (value && this.parent.country && this.parent.country !== '') return value.match(regex)
        return true
      }),
    country: yup.string().required(t('Address_form_country_required')),
    lat: yup.number().typeError(t('Address_form_lat_must_be_number')),
    lng: yup.number().typeError(t('Address_form_long_must_be_number')),
  })

  AddressForm.validationSchemaOptional = yup.object().shape({
    street: yup.string().min(3, t('Address_form_street_min')),
    city: yup.string().min(3, t('Address_form_city_min')),
    zip: yup.string().test('zip-regex-match', t('Address_form_zip_not_valid'), function (value) {
      let regex = '\\b' + zipRegexes[this.parent.country] + '\\b'
      if (value && this.parent.country && this.parent.country !== '') return value.match(regex)
      return true
    }),
    country: yup.string().optional(),
    lat: yup.number().typeError(t('Address_form_lat_must_be_number')),
    lng: yup.number().typeError(t('Address_form_long_must_be_number')),
  })

  return (
    <>
      <Row>
        <Col>
          <Input
            name={`${name}.street`}
            label={t('Project_info_street_label')}
            placeholder={t('Project_info_street_placeholder')}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            name={`${name}.city`}
            label={t('Project_info_city_label')}
            placeholder={t('Project_info_city_placeholder')}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <Input
            name={`${name}.zip`}
            label={t('Project_info_zip_label')}
            placeholder={t('Project_info_zip_placeholder')}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <DropdownField
            name={`${name}.country`}
            label={t('Project_info_country_label')}
            type="select"
            options={countryCodes.map((code) => {
              return { label: t('country_' + code, code), value: code }
            })}
            labelField={'label'}
            valueField={'value'}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            name={`${name}.lat`}
            label={t('Project_info_lat_label')}
            placeholder={t('Project_info_lat_placeholder')}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <Input
            name={`${name}.lng`}
            label={t('Project_info_long_label')}
            placeholder={t('Project_info_long_placeholder')}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </>
  )
}

AddressForm.validationSchemaOptional = yup.object()
AddressForm.validationSchemaRequired = yup.object()

AddressForm.initialValues = {
  street: '',
  city: '',
  zip: '',
  country: '',
  lat: '',
  lng: '',
}

export default AddressForm
