import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Button, Card, CardBody, Spinner, Alert } from 'reactstrap'
import * as yup from 'yup'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'
import { Mutation } from '../../api/gql'
import i18next from 'i18next'
import Input from '../../components/form/FormikInput'

const ResetPassword = () => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('Reset_password_form_validation_email_format'))
      .required(t('Reset_password_form_validation_email_required')),
  })

  const [sendResetPasswordLink] = useMutation(Mutation.SEND_USER_PASSWORD_RESET_LINK)
  const [formError, setFormError] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">{t('Reset_password_heading')}</h1>
        <p className="lead">{t('Reset_password_subheading')}</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={validationSchema}
              formSubmitted={formSubmitted}
              setFormSubmitted={setFormSubmitted}
              onSubmit={async (values, actions, error) => {
                setFormError(null)
                try {
                  await sendResetPasswordLink({
                    variables: { language: i18next.language, ...values },
                  })
                  setFormSubmitted(true)
                } catch (e) {
                  setFormError(e.message)
                }
              }}
            >
              {({ isSubmitting, values }) => (
                <>
                  {!formSubmitted ? (
                    <Form>
                      <Input
                        label={t('Reset_password_email_label')}
                        bsSize="lg"
                        type="email"
                        name="email"
                        placeholder={t('Reset_password_email_placeholder')}
                      />
                      {formError && (
                        <div>
                          <Alert color="danger">
                            <div className="alert-message">{formError}</div>
                          </Alert>
                        </div>
                      )}
                      <div className="text-center mt-3">
                        <Button disabled={isSubmitting} color="primary" size="lg" type="submit">
                          {isSubmitting && (
                            <Spinner
                              size="sm"
                              label={t('Reset_password_form_Reset_password_btn_spinner_label')}
                              style={{ verticalAlign: 'middle' }}
                            />
                          )}{' '}
                          {t('Reset_password_reset_btn')}
                        </Button>
                      </div>
                    </Form>
                  ) : (
                    <Trans i18nKey="Reset_password_form_email_sent_to" email={values.email}>
                      An email was sent to <strong>{{ email: values.email }}</strong>.
                    </Trans>
                  )}
                </>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default ResetPassword
