import { Id } from '@silevis/reactgrid'
import { PriceBreakdownRow } from '../types/PriceBreakdownRow'

export const getSortedPriceRows = (
  rows: PriceBreakdownRow[],
  hidden: boolean = false,
  currentIndex = '1',
  level = 0,
  parentId?: Id,
): (PriceBreakdownRow & { indent: number; hidden: boolean; idxString: string })[] => {
  const currentLevelRows = rows
    .filter((row) => row.parentId === parentId)
    .sort((a, b) => a.idx - b.idx)

  return currentLevelRows.flatMap((row, idx) => {
    return [
      { ...row, indent: level, hidden: hidden || !!row.hidden, idxString: `${currentIndex}.${idx + 1}` },
      ...getSortedPriceRows(
        rows,
        hidden || !row.expanded,
        `${currentIndex}.${idx + 1}`,
        level + 1,
        row.rowId,
      ),
    ]
  })
}

export default getSortedPriceRows
