import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'

export const initAuth = async () => {
  window.localStorage.setItem('login', new Date().getTime())
}

export const getUser = () => {
  const token = Cookies.get('token')
  return token ? jwt.decode(token) : null
}

export const isAuthenticated = () => {
  return getUser() // && !!user
}

// export const setToken = async (token) => {
//   Cookies.set('token', token, {
//     expires: moment()
//       .add(30, 'days')
//       .toDate(),
//   })
//   // await initAuth()
// }

export const unsetToken = () => {
  Cookies.remove('token')
  // user = null
}
