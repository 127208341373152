import React from 'react'
import { Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const InfoForm = ({ tender, bidder }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Row className={'mb-3'}>
        <Col sm={6}>
          <b>{t('bidder_info_form_name_label', 'Name')}:</b>
          <p>{tender.name}</p>
        </Col>
        <Col sm={6}>
          <b>{t('bidder_info_form_bidder_label', 'Bidder')}:</b>
          <p>
            {bidder.name}
            {bidder.contactName && ' (' + bidder.contactName + ')'}
          </p>
          <b>{t('bidder_info_form_round_label', 'Round')}:</b>
          <p>{tender.round}</p>
        </Col>
      </Row>

      <b>{t('bidder_info_form_description_label', 'Description')}:</b>
      <p>{tender.description}</p>
      <Row className={'mb-3'}>
        <Col sm={6}>
          <b>{t('bidder_info_form_commencement_date_label', 'Commencement date')}:</b>
          <p>{t('formatter_dateTime', { date: tender.commencementDate })}</p>
        </Col>
        <Col sm={6}>
          <b>{t('bidder_info_form_submission_date_label', 'Submission date')}:</b>
          <p>{t('formatter_dateTime', { date: tender.submissionDate })}</p>
        </Col>
      </Row>
      <Row className={'mb-3'}>
        <Col sm={6}>
          <b>{t('bidder_info_form_package_manager_label', 'Package Manager')}:</b>
          <p>{tender.packageManager.name}</p>
        </Col>
        <Col sm={6}>
          <b>{t('bidder_info_form_address_label', 'Address')}:</b>
          <p className="mb-0">{tender.address.street}</p>
          <p className="mb-0">
            {tender.address.zip} {tender.address.city}
          </p>
          <p>{t('country_' + tender.address.country, tender.address.country)}</p>
        </Col>
      </Row>
    </div>
  )
}

export default InfoForm
