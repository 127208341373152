import React, { useState } from 'react'
import { useRouter, useTabSwitch } from '../../hooks'
import { Nav, NavLink, NavItem, TabContent, TabPane, Row, Col } from 'reactstrap'
import cn from 'classnames'

import ProjectInfo from './components/ProjectInfo'
import UsersTable from './components/UsersTable'

import { useTranslation } from 'react-i18next'

const Settings = () => {
  const { t } = useTranslation()
  const {
    query: { projectId },
  } = useRouter()
  const [activeTab, setActiveTab] = useTabSwitch()
  const isNew = projectId === 'new'

  return (
    <>
      <h1 className="h2 mb-3">{t('Project_info_project_settings_heading')}</h1>
      <Row>
        <Col md={12} sm={12}>
          <div className="tab">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={cn({ active: activeTab === 'info' })}
                  onClick={() => setActiveTab('info')}
                >
                  {t('Project_info_project_settings_info_navlink')}
                </NavLink>
              </NavItem>
              {!isNew && (
                <NavItem>
                  <NavLink
                    className={cn({ active: activeTab === 'users' })}
                    onClick={() => setActiveTab('users')}
                  >
                    {t('Project_info_project_settings_users_navlink')}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="info">
                <h1 className="h3 mb-3">{t('Project_info_project_settings_info_navlink')}</h1>
                <ProjectInfo />
              </TabPane>
              {!isNew && (
                <TabPane tabId="users">
                  <h1 className="h3 mb-3">{t('Project_info_project_settings_users_navlink')}</h1>
                  <UsersTable />
                </TabPane>
              )}
            </TabContent>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Settings
