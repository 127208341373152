import React from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { Query, Mutation } from '../../../api'

import * as yup from 'yup'
import { toastr } from 'react-redux-toastr'
import { Formik, Form } from 'formik'
import { Button, Row, Col } from 'reactstrap'
import Loading from '../../../components/Loading'
import Input from '../../../components/form/FormikInput'
import AddressForm from '../../../components/form/Address'
import { useTranslation } from 'react-i18next'

const initialValues = {
  name: '',
  description: '',
  address: { ...AddressForm.initialValues },
}

const OrganizationInfo = () => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(Query.ORGANIZATION)
  const [updateOrg, updateOrgData] = useMutation(Mutation.UPDATE_ORGANIZATION, {
    onCompleted: () => {
      toastr.success(t('Organization_info_organization_info_has_been_updated_label'))
    },
  })

  const isLoading = loading || updateOrgData.loading
  const organization = data ? data.organization : {}

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(3, t('Organization_info_name_min'))
      .required(t('Organization_info_name_required')),
    description: yup
      .string()
      .required(t('Organization_info_description_required'))
      .nullable(),
    address: AddressForm.validationSchemaOptional,
  })

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        ...initialValues,
        ...organization,
      }}
      enableReinitialize
      onSubmit={(values) => {
        updateOrg({ variables: { ...values } })
      }}
    >
      {() => (
        <Form style={{ position: 'relative' }}>
          <Row>
            <Col md={6} sm={12}>
              <Input
                type="text"
                name="name"
                label={t('Organization_info_name_label')}
                placeholder={t('Organization_info_name_placeholder')}
              />
              <Input
                type="textarea"
                rows={5}
                name="description"
                label={t('Organization_info_description_label')}
                placeholder={t('Organization_info_description_placeholder')}
              />
            </Col>
            <Col md={6} sm={12}>
              <AddressForm name="address" />
            </Col>
          </Row>
          <Button color="success" size="bg">
            {t('Organization_info_save_changes_btn')}
          </Button>
          {isLoading && <Loading />}
        </Form>
      )}
    </Formik>
  )
}

export default OrganizationInfo
