import React, { useState } from 'react'
import { Button, Card, CardBody, Alert, Spinner } from 'reactstrap'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import Input from '../../components/form/FormikInput'
import { useMutation } from '@apollo/react-hooks'
import { Mutation } from '../../api/gql'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/img/logo_signin.png'
import styled from 'styled-components'
import LanguageSelect from '../../components/LanguageSelect'
import { useUser } from '../../hooks'
import { toastr } from 'react-redux-toastr'
import i18next from 'i18next'

const Logo = styled.img.attrs((props) => ({
  src: logo,
}))`
  height: 200px;
`

const ResetPasswordFinish = ({ history }) => {
  const { t } = useTranslation()
  const user = useUser()
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(4, t('Reset_password_form_validation_password_min'))
      .required(t('Reset_password_form_validation_password_required')),
    password2: yup
      .string()
      .min(4, t('Reset_password_form_validation_password2_min'))
      .required(t('Reset_password_form_validation_password2_required'))
      .test('passwords-match', t('Reset_password_form_validation_passwords_not_matching'), function (
        value,
      ) {
        return this.parent.password === value
      }),
  })

  const [resetFinish] = useMutation(Mutation.RESET_USER_PASSWORD)
  const [formError, setFormError] = useState(null)

  return (
    <React.Fragment>
      <div className="text-center">
        <Logo />
        <h1 className="h2">{t('Reset_password_password_heading')}</h1>
        <p className="lead">{t('Reset_password_password_subheading')}</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Formik
              initialValues={{
                password: '',
                password2: '',
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, actions, error) => {
                setFormError(null)
                try {
                  await resetFinish({
                    variables: {
                      id: user.id,
                      password: values.password,
                      language: i18next.language,
                    },
                  })
                  history.replace('/auth/signout')
                  toastr.success(t('Reset_password_password_was_changed'))
                } catch (e) {
                  setFormError(e.message)
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Input
                    label={t('Reset_password_form_password_label')}
                    bsSize="lg"
                    type="password"
                    name="password"
                    placeholder={t('Reset_password_form_password_placeholder')}
                  />
                  <Input
                    label={t('Reset_password_form_password2_label')}
                    bsSize="lg"
                    type="password"
                    name="password2"
                    placeholder={t('Reset_password_form_password2_placeholder')}
                  />
                  {formError && (
                    <div>
                      <Alert color="danger">
                        <div className="alert-message">{formError}</div>
                      </Alert>
                    </div>
                  )}
                  <div className="text-center mt-3">
                    <Button disabled={isSubmitting} color="primary" size="lg" type="submit">
                      {isSubmitting && (
                        <Spinner
                          size="sm"
                          label={t('Reset_password_form_reset_password_btn_spinner_label')}
                          style={{ verticalAlign: 'middle' }}
                        />
                      )}{' '}
                      {t(
                        'Reset_password_password_form_create_password_btn_label',
                        'Create password',
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
      <div className={'text-center'}>
        <LanguageSelect />
      </div>
    </React.Fragment>
  )
}

export default ResetPasswordFinish
