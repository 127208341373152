import {
  CellTemplate,
  Uncertain,
  Compatible,
  getCellProperty,
  UncertainCompatible,
  keyCodes,
  getCharFromKeyCode,
  isAlphaNumericKey,
  DropdownCell,
  CellLocation,
  Id,
} from '@silevis/reactgrid';
import React, { FC } from 'react';
import { OptionProps, MenuProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import usePrevious from '../../hooks/usePrevious';

import { KeyCombination } from '../types/KeyCombination';
import { KeyCombinationComparer } from './KeyCombinationComparer';

export type OptionType = {
  label: string;
  value: string;
};

export interface ExtraDropdownCell extends Omit<DropdownCell, 'type'> {
  type: 'extraDropdown';
  createChild?: (location: CellLocation) => void;
  createSibling?: (parentId?: Id, rowId?: Id) => void;
  createSiblingAbove?: (parentId?: Id, rowId?: Id) => void;
  expandChildren?: (location: CellLocation, recursively?: boolean) => void
  location: CellLocation;
  parentId?: Id;
}

export class ExtraDropdownCellTemplate extends KeyCombinationComparer implements CellTemplate<ExtraDropdownCell> {
  getCompatibleCell(uncertainCell: Uncertain<ExtraDropdownCell>): Compatible<ExtraDropdownCell> {
    let selectedValue: string | undefined;
    const location = getCellProperty(uncertainCell, 'location', 'object');

    try {
      selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'string');
    } catch {
      selectedValue = undefined;
    }
    const values = getCellProperty(uncertainCell, 'values', 'object');
    const value = selectedValue ? parseFloat(selectedValue) : NaN;
    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, 'isDisabled', 'boolean');
    } catch {
      isDisabled = false;
    }
    let inputValue: string | undefined;
    try {
      inputValue = getCellProperty(uncertainCell, 'inputValue', 'string');
    } catch {
      inputValue = undefined;
    }
    let isOpen: boolean;
    try {
      isOpen = getCellProperty(uncertainCell, 'isOpen', 'boolean');
    } catch {
      isOpen = false;
    }
    const text = selectedValue || '';
    return { ...uncertainCell, selectedValue, text, value, values, isDisabled, isOpen, inputValue, location };
  }

  update(
    cell: Compatible<ExtraDropdownCell>,
    cellToMerge: UncertainCompatible<ExtraDropdownCell>
  ): Compatible<ExtraDropdownCell> {
    return this.getCompatibleCell({
      ...cell,
      selectedValue: cellToMerge.selectedValue,
      isOpen: cellToMerge.isOpen,
      inputValue: cellToMerge.inputValue,
    });
  }

  getClassName(cell: Compatible<ExtraDropdownCell>, isInEditMode: boolean): string {
    const isOpen = cell.isOpen ? 'open' : 'closed';
    return `${cell.className ? cell.className : ''}${isOpen} rg-dropdown-cell`;
  }

  handleKeyDown(
    cell: Compatible<ExtraDropdownCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<ExtraDropdownCell>; enableEditMode: boolean } {
    const currentCombination: KeyCombination = { keyCode, ctrl, shift, alt };

    if (keyCode === keyCodes.ESCAPE) {
      return { cell: { ...cell, isOpen: false }, enableEditMode: false };
    }

    if (this.compareChildRowKeyCombination(currentCombination)) {
      cell.createChild?.(cell.location);
      return { cell, enableEditMode: false };
    }

    if (this.compareChildRowKeyCombination(currentCombination)) {
      cell.createChild?.(cell.location);
      return { cell, enableEditMode: false };
    }

    if (this.compareSiblingRowKeyCombination(currentCombination)) {
      cell.createSibling?.(cell.parentId, cell.location.rowId);
      return { cell, enableEditMode: false };
    }

    if (this.compareExpandChildrenKeyCombination(currentCombination)) {
      cell.expandChildren?.(cell.location)
      return { cell, enableEditMode: false }
    }


    if (this.compareSiblingRowAboveKeyCombination(currentCombination)) {
      cell.createSiblingAbove?.(cell.parentId, cell.location.rowId)
      return { cell, enableEditMode: false }
    }

    if ((keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER) && !shift) {
      return { cell: this.getCompatibleCell({ ...cell, isOpen: !cell.isOpen }), enableEditMode: false };
    }
    const char = getCharFromKeyCode(keyCode, shift);
    if (!ctrl && !alt && isAlphaNumericKey(keyCode))
      return {
        cell: this.getCompatibleCell({ ...cell, inputValue: shift ? char : char.toLowerCase(), isOpen: !cell.isOpen }),
        enableEditMode: false,
      };
    return { cell, enableEditMode: false };
  }

  render(
    cell: Compatible<ExtraDropdownCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<ExtraDropdownCell>, commit: boolean) => void
  ): React.ReactNode {
    return <DropdownComponent cell={cell} onCellChanged={onCellChanged} getCompatibleCell={this.getCompatibleCell} />;
  }
}

const CustomOption: React.FC<OptionProps<OptionType, false>> = ({ innerProps, label, isSelected, isFocused }) => (
  <div
    {...innerProps}
    onPointerDown={(e) => e.stopPropagation()}
    className={`rg-dropdown-option${isSelected ? ' selected' : ''}${isFocused ? ' focused' : ''}`}
  >
    {label}
  </div>
);

const CustomMenu: React.FC<MenuProps<OptionType, false>> = ({ innerProps, children }) => (
  <div {...innerProps} className="rg-dropdown-menu">
    {children}
  </div>
);

interface DropdownComponentProps {
  cell: Compatible<ExtraDropdownCell>;
  onCellChanged: (cell: Compatible<ExtraDropdownCell>, commit: boolean) => void;
  getCompatibleCell: (uncertainCell: Uncertain<ExtraDropdownCell>) => Compatible<ExtraDropdownCell>;
}

const DropdownComponent: FC<DropdownComponentProps> = ({ cell, onCellChanged, getCompatibleCell }) => {
  const selectRef = React.useRef<any>(null);

  const [inputValue, setInputValue] = React.useState<string | undefined>(cell.inputValue);

  const prevCell = usePrevious(cell);

  React.useEffect(() => {
    if (cell.isOpen && selectRef.current) {
      setInputValue(cell.inputValue);
      selectRef.current.focus();
    }
  }, [cell.isOpen, cell.inputValue]);

  if (!cell.isOpen) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 10,
          justifyContent: 'space-between',
        }}
        onPointerDown={(e) => {
          onCellChanged(getCompatibleCell({ ...cell, isOpen: true }), true);
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {cell.values.find((val: any) => val.value === cell.selectedValue)?.value ?? cell.selectedValue ?? 'Select...'}
        <div
          className="chevron"
          style={{
            margin: '8px 4px 4px 10px',
            color: 'hsl(0,0%,40%)',
          }}
        >
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <CreatableSelect
        ref={selectRef}
        {...(cell.inputValue && {
          inputValue,
          defaultInputValue: inputValue,
          onInputChange: (e) => setInputValue(e),
        })}
        isSearchable={true}
        menuIsOpen
        onMenuClose={() => {
          (document.getElementsByClassName('rg-hidden-element')[0] as HTMLElement)?.focus();
        }}
        onChange={(e) => {
          onCellChanged(
            getCompatibleCell({
              ...cell,
              selectedValue: (e as OptionType).value,
              isOpen: false,
              inputValue: undefined,
            }),
            true
          );
        }}
        onBlur={() => {
          if (prevCell?.isOpen) {
            onCellChanged(getCompatibleCell({ ...cell, isOpen: false, inputValue: undefined }), true);
          }
        }}
        blurInputOnSelect={true}
        defaultValue={cell.values.find((val: any) => val.value === cell.selectedValue)}
        isDisabled={cell.isDisabled}
        options={cell.values}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.keyCode === keyCodes.ESCAPE) {
            onCellChanged(getCompatibleCell({ ...cell, isOpen: false, inputValue: undefined }), true);
          }
        }}
        components={{
          Option: CustomOption,
          Menu: CustomMenu,
        }}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
            height: '100%',
          }),
          control: (provided) => ({
            ...provided,
            border: 'none',
            borderColor: 'transparent',
            minHeight: '25px',
            background: 'transparent',
            boxShadow: 'none',
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            paddingTop: '0px',
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            padding: '0px 4px',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: 'inherit',
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            marginTop: '4px',
            marginBottom: '4px',
          }),
          input: (provided) => ({
            ...provided,
            padding: 0,
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
          }),
        }}
      />
    </div>
  );
};
