import React, { useState, useCallback, useEffect, useMemo } from 'react'
import pt from 'prop-types'
import {
  SearchState,
  IntegratedFiltering,
  SelectionState,
  IntegratedSelection,
  IntegratedSorting,
  SortingState,
  EditingState,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  VirtualTable,
  // Toolbar,
  TableSelection,
  // TableColumnResizing,
  TableHeaderRow,
  TableInlineCellEditing,
  TableEditRow,
  TableEditColumn,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'

import styled from 'styled-components'

import RowActions from './components/RowActions'
import ActionBar from './components/ActionBar'
import SortLabel from './components/SortLabel'

import EditCell from './components/EditCell'

import { SizeFormatter, DateFormatter, CustomFormatter } from './formatters'
import { useTranslation } from 'react-i18next'

const TableWrapper = styled.div`
  position: relative;

  .table th {
    border-top: none;
  }

  .dx-g-bs4-fixed-block {
    position: absolute;
  }

  .dx-g-bs4-table-cell {
    height: 46px;
  }
`

const GridTable = ({
  groupTitle,
  showHeader,
  columns,
  rows,
  getRowId,
  virtualTable,
  sorting,
  defaultSorting,
  onSearchChange,
  searchValue,
  selection,
  onSelectionChange,
  editingRowIds,
  inlineEditing,
  onCommitChanges,
  showSelectionColumn,
  selectByRowClick,
  rowActions,
  noDataMessage,
  formatters,
  actionBar,
  customFilter
}) => {
  const { t } = useTranslation()
  const _columns = columns
  const _columnsExtensions = _columns.map((c) => ({
    columnName: c.name,
    width: c.width || 'auto',
  }))
  const _editColumnsExtensions = _columns.map((c) => ({
    columnName: c.name,
    editingEnabled: !c.readOnly,
  }))

  const TableCell = ({ style, onClick, children, ...props }) => {
    let _style = {
      overflow: props.column.name === 'tags' ? 'overlay' : 'hidden',
      textOverflow: props.column.name === 'tags' ? 'initial' : 'elipsis',
    }
    if (onSelectionChange || selectByRowClick) _style['cursor'] = 'pointer'

    const focusProps = inlineEditing
      ? {
        onFocus: onClick,
        tabIndex: props.column.readOnly ? -1 : 0,
      }
      : {}

    const Cell = virtualTable ? VirtualTable.Cell : Table.Cell
    return (
      <Cell
        style={{
          ..._style,
          ...style,
        }}
        {...props}
        {...focusProps}
      >
        {children}
      </Cell>
    )
  }

  const _rows = rows

  const [search, setSearch] = useState(actionBar ? '' : null)

  const handleSearchChange = (value) => {
    setSearch(value)
    onSearchChange(value)
    if (customFilter) customFilter(value)

  }

  useEffect(() => {
    setSearch(searchValue)
  }, [searchValue])

  const colSpanCount = useMemo(() => {
    let count = columns.length
    rowActions && count++
    onSelectionChange && count++
    return count
  }, [rowActions, onSelectionChange, columns])

  const TableComponent = virtualTable ? VirtualTable : Table

  return (
    <TableWrapper>
      {actionBar && <ActionBar {...actionBar} onSearchChange={handleSearchChange} />}
      {groupTitle}
      <Grid columns={_columns} rows={_rows} getRowId={getRowId}>
        {(inlineEditing || rowActions) && (
          <EditingState
            columnEditingEnabled
            onCommitChanges={onCommitChanges}
            columnExtensions={_editColumnsExtensions}
          />
        )}

        {search && <SearchState value={search} />}
        {search && !customFilter && <IntegratedFiltering />}

        {onSelectionChange && (
          <SelectionState selection={selection} onSelectionChange={onSelectionChange} />
        )}
        {onSelectionChange && <IntegratedSelection />}

        {sorting && <SortingState defaultSorting={defaultSorting} />}
        {sorting && <IntegratedSorting />}

        <TableComponent
          noDataCellComponent={(props) => (
            <td className="text-center" colSpan={colSpanCount}>
              {/* {noDataMessage} */}
              {t('Grid_no_data')}
            </td>
            // <Table.NoDataCell {...props} getMessage={() => noDataMessage} />
          )}
          cellComponent={TableCell}
          columnExtensions={_columnsExtensions}
        />

        {formatters.map((f, i) => (
          <CustomFormatter {...f} key={i} />
        ))}

        <SizeFormatter for={['size']} />
        <DateFormatter for={['createdAt', 'updatedAt']} />

        {showHeader && (
          <TableHeaderRow showSortingControls={sorting} sortLabelComponent={SortLabel} />
        )}

        {onSelectionChange && (
          <TableSelection
            highlightRow
            showSelectionColumn={showSelectionColumn}
            showSelectAll
            selectByRowClick={selectByRowClick}
          />
        )}

        {inlineEditing && (
          <TableInlineCellEditing
            startEditAction="click"
            selectTextOnEditStart
            cellComponent={EditCell}
          />
        )}

        {rowActions && (
          <TableEditColumn
            width={35}
            showDeleteCommand
            showEditCommand
            cellComponent={({ row, ...props }) => (
              <TableEditColumn.Cell row={row} {...props}>
                <RowActions actions={rowActions} row={row} />
              </TableEditColumn.Cell>
            )}
          />
        )}
      </Grid>
    </TableWrapper>
  )
}

GridTable.propTypes = {
  groupTitle: pt.oneOf([pt.string, pt.node]),
  columns: pt.array.isRequired,
  rows: pt.array.isRequired,
  getRowId: pt.func,
  virtualTable: pt.bool,
  showHeader: pt.bool,
  sorting: pt.bool,
  search: pt.bool,
  searchValue: pt.string,
  onSelectionChange: pt.func,
  onSearchChange: pt.func,
  selection: pt.array,
  editingRowIds: pt.array,
  selectByRowClick: pt.bool,
  inlineEditing: pt.bool,
  onCommitChanges: pt.func,
  showSelectionColumn: pt.bool,
  noDataMessage: pt.string,
  formatters: pt.arrayOf(pt.object).isRequired,
  actionBar: pt.oneOfType([
    pt.shape({
      newButtonText: pt.string,
      onNew: pt.func,
      onDelete: pt.func,
      actionButton: pt.node,
      actionButtonRight: pt.node,
      showSearch: pt.bool,
    }),
    pt.bool,
  ]),
  rowActions: pt.arrayOf(
    pt.shape({
      title: pt.string.isRequired,
      action: pt.func.isRequired,
      color: pt.oneOf(['success', 'danger', 'info', 'warning']),
    }),
  ),
  customFilter: pt.func
}
GridTable.defaultProps = {
  getRowId: (r) => r.id,
  virtualTable: false,
  search: false,
  searchValue: '',
  showHeader: true,
  sorting: true,
  defaultSorting: [],
  selectByRowClick: false,
  showSelectionColumn: true,
  selection: [],
  noDataMessage: 'No data',
  formatters: [],
  onSearchChange: () => { },
  customFilter: (_searchValue) => { }
}

GridTable.displayName = 'GridTable'

export default GridTable
