import React, { useState, useRef } from 'react'
import { Button, Input, Spinner, Col, CustomInput, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export default ({ onExpand, onClose, onSubmit, expanded, loading }) => {
  const { t } = useTranslation()
  const [showTextInput, setShowTextInput] = useState(expanded)
  const [isPublic, setIsPublic] = useState(false)
  const messageInputRef = useRef()

  const handleSend = async () => {
    setShowTextInput(false)
    onSubmit && onSubmit(messageInputRef.current.value, isPublic)
    messageInputRef.current.value = ''
  }

  if (showTextInput && expanded)
    return (
      <Row>
        <Col style={{ marginLeft: '45px' }}>
          <input
            type="text"
            className="mt-2 form-control form-control-lg"
            placeholder={t('Tender_timeline_write_a_message')}
            onKeyDown={({ keyCode }) => keyCode === 13 && handleSend()}
            ref={messageInputRef}
            disabled={loading}
          />
          <ButtonsWrapper className={'float-right mt-2'}>
            <CustomInput
              id="replyAll"
              type="checkbox"
              className={'mr-3'}
              label={t('Tender_timeline_reply_to_all', 'Visible to all')}
              checked={isPublic}
              onChange={(e) => {
                setIsPublic(e.target.checked)
              }}
            />
            <Button
              color="outline-secondary"
              className={'mr-1'}
              onClick={() => {
                setShowTextInput(false)
                onClose && onClose()
              }}
            >
              {t('Tender_timeline_cancel_btn', 'Cancel')}
            </Button>
            <Button
              disabled={loading}
              color="outline-primary"
              onClick={() => {
                handleSend()
              }}
            >
              {t('Tender_timeline_send_btn', 'Send')}
            </Button>
          </ButtonsWrapper>
        </Col>
      </Row>
    )

  return (
    // <Row>
    //   <Col>
    <Button
      color="link"
      disabled={loading}
      className={'float-right btn-sm'}
      onClick={() => {
        setShowTextInput(true)
        onExpand && onExpand()
      }}
    >
      {t('Tender_timeline_reply_btn', 'Reply')} {loading && <SpinnerButton />}
    </Button>
    //   </Col>
    // </Row>
  )
}

const ButtonsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`
const SpinnerButton = styled(Spinner).attrs(() => ({
  size: 'sm',
}))`
  vertical-align: middle;
`
