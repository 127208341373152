import React from 'react'
import { Progress, Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap'

export default ({ uploads, loading }) => {
  return (
    <Modal isOpen={loading}>
      <ModalHeader>Uploading ...</ModalHeader>
      <ModalBody>
        {Object.keys(uploads).map((k) => (
          <div className="my-1">
            <Row>
              <Col className="w-auto">{uploads[k].name}</Col>
              <Col className="text-right">
                {uploads[k].loaded} / {uploads[k].total}
              </Col>
            </Row>
            <Progress animated value={uploads[k].progress}>
              {uploads[k].progress}%
            </Progress>
          </div>
        ))}
      </ModalBody>
    </Modal>
  )
}
