import { gql } from 'apollo-boost'
import * as Fragments from './fragments'

export const ME = gql`
  query {
    me {
      id
      name
      email
      rights
      currentProject {
        id
        name
      }
      activeSubscription {
        id
        plan
        expiresAt
      }
      organization {
        subscription
      }
    }
    projects {
      id
      name
    }
  }
`

export const ORGANIZATION = gql`
  query {
    organization {
      id
      name
      description
      address
      projects {
        id
        name
      }
    }
  }
`

export const DASHBOARD = gql`
  query {
    me {
      id
      currentProject {
        id
        name
        description
        address
        organization {
          id
          name
          bidders {
            id
            name
          }
        }
        tenders {
          id
          name
          status
          submissionDate
          bidders {
            id
            name
          }
        }
        users {
          id
          user {
            id
            name
          }
        }
      }
    }
  }
`

export const PROJECTS = gql`
  query {
    projects {
      id
      name
    }
  }
`

export const PROJECT = gql`
  query project($id: ID!, $role: Role) {
    project(id: $id) {
      id
      name
      description
      address
      language
      tenders {
        id
        name
        bidders {
          id
          name
        }
      }
      users(role: $role) {
        id
        role
        user {
          id
          name
          email
        }
      }
    }
  }
`

export const TENDERS = gql`
  query tenders($projectId: ID!) {
    tenders(projectId: $projectId) {
      ...TenderFragment
    }
  }
  ${Fragments.TenderFragment}
`

export const USERS = gql`
  query {
    users {
      ...UserFragment
    }
  }
  ${Fragments.UserFragment}
`

export const TAGS = gql`
  query($language: String!) {
    tags(language: $language)
  }
`

export const BIDDERS = gql`
  query {
    bidders {
      ...BidderFragment
    }
  }
  ${Fragments.BidderFragment}
`

export const BIDDER_TENDER_MESSAGES = gql`
  query($id: ID!) {
    bidderTenderMessages(id: $id) {
      ...LogFragment
      mine
      children {
        ...LogFragment
      }
    }
  }
  ${Fragments.LogFragment}
`
export const BIDDER_TENDER = gql`
  query tender($id: ID!) {
    currentBid {
      id
      createdAt
      priceBreakdown {
        id
        children {
          id
          ...PriceBreakdownNodeFragment
        }
      }
      bidder {
        id
        name
      }
      tender {
        id
        round
      }
      files {
        ...FileFragment
      }
    }
    tender(id: $id) {
      id
      name
      description
      status
      commencementDate
      submissionDate
      round
      address
      packageManager {
        id
        name
      }
      files {
        id
        filename
        size
        mimetype
      }
      priceBreakdowns {
        id
        tenderRound
        children {
          ...PriceBreakdownNodeFragment
        }
      }
    }
  }
  ${Fragments.FileFragment}
  ${Fragments.PriceBreakdownNodeFragment}
`

export const TENDER = gql`
  query tender($id: ID!, $round: Int) {
    tender(id: $id) {
      id
      name
      description
      status
      statusActions
      commencementDate
      submissionDate
      address
      hardcopyRequired
      hardcopyAddress
      round
      updatedAt
      logs {
        ...LogFragment
        children {
          ...LogFragment
        }
      }
      packageManager {
        id
      }
      bids(round: $round) {
        id
        round
        totalPrice
        bidder {
          id
          name
          contactName
        }
        files {
          ...FileFragment
        }
        updatedAt
        priceBreakdown {
          id
          children {
            ...PriceBreakdownNodeFragment
          }
        }
      }
      bidders {
        ...BidderFragment
      }
      files {
        ...FileFragment
      }
      priceBreakdowns {
        id
        tenderRound
        children {
          ...PriceBreakdownNodeFragment
        }
        file {
          id
        }
      }
      winnerBidder {
        ...BidderFragment
      }
      organization {
        subscription
      }
    }
  }
  ${Fragments.FileFragment}
  ${Fragments.BidderFragment}
  ${Fragments.LogFragment}
  ${Fragments.PriceBreakdownNodeFragment}
`

export const TENDER_NOTES = gql`
  query($id: ID!) {
    tender(id: $id) {
      id
      notes
    }
  }
`

export const FILES = gql`
  query files($directory: String) {
    files(directory: $directory) {
      ...FileFragment
    }
  }
  ${Fragments.FileFragment}
`

export const TENDER_FILES = gql`
  query tender($id: ID!) {
    tender(id: $id) {
      id
      files {
        ...FileFragment
      }
    }
  }
  ${Fragments.FileFragment}
`
export const BID_FILES = gql`
  query tender($id: ID!) {
    bid(id: $id) {
      id
      files {
        ...FileFragment
      }
    }
  }
  ${Fragments.FileFragment}
`

export const BIDDER_FOR_TENDER = gql`
  query bidder($tenderId: ID) {
    bidder {
      id
      name
      bids(tenderId: $tenderId) {
        id
        round
        prices
      }
    }
  }
`

export const PRICE_BREAKDOWN = gql`
  query priceBreakdown($id: ID!) {
    priceBreakdown(id: $id) {
      id
      children {
        ...PriceBreakdownNodeFragment
      }
    }
  }
  ${Fragments.PriceBreakdownNodeFragment}
`

export const UNIT_OPTIONS = gql`
  query unitOptions {
    unitOptions {
      value
      label
    }
  }
`

export const TENDER_ACTIVITY_LOGS = gql`
  query tenderActivityLogs($tenderId: ID!) {
    tenderActivityLogs(tenderId: $tenderId) {
    title
    description
    tender {
      name
    }
    bidder {
      name
    }
    createdAt
  }
 }
`