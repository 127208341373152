import React from 'react'
import pt from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'

const InputFileWrapper = styled.div`
  display: inline-block;
  input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    margin: 0;
  }
  
  label:not(.disabled) {
    cursor: pointer;
  }
`

const FileUpload = (p) => {
  const { name, className, size, color, label, onChange, icon, loading, disabled, rounded, ...props } = p

  const { t } = useTranslation()
  const handleChange = (e) => {
    onChange(e, e.target.files)
    e.target.value = null
  }

  return (
    <InputFileWrapper className={className}>
      <input name={name} id={name} type="file" onChange={handleChange} disabled={disabled} {...props} />
      <label
        htmlFor={name}
        className={cn(`btn btn-${color || 'success'} ${loading || disabled && 'disabled'}`, {
          [`btn-${size}`]: size,
        }, rounded && 'bt-pill')}
      >
        {loading ? (
          <Spinner
            size="sm"
            label={label || t('Organization_documents_upload_new_files_btn')}
            style={{ verticalAlign: 'middle' }}
          />
        ) : (
          <>{icon}</>
        )}{' '}
        {label || t('Organization_documents_upload_new_files_btn')}
      </label>
    </InputFileWrapper>
  )
}

FileUpload.propTypes = {
  name: pt.string.isRequired,
  onChange: pt.func.isRequired,
  className: pt.string,
  size: pt.oneOf(['lg', 'md', 'sm']),
  color: pt.string,
  label: pt.string,
  icon: pt.node,
  loading: pt.bool,
  accept: pt.string,
  disabled: pt.bool,
  rounded: pt.bool
}
FileUpload.defaultProps = {
  size: 'lg',
  name: `fileUpload_${new Date().getTime()}`,
  icon: <FontAwesomeIcon icon={faCloudUploadAlt} />,
  rounded: true
}

export default FileUpload
