import { keyCodes } from '@silevis/reactgrid'
import PriceBreakdownGrid from './PriceBreakdownGrid/PriceBreakdownGrid'
import '@silevis/reactgrid/styles.css'

export const ReactGrid = ({ priceRows, parentId, unitOptions, canChangeCells }) => {
  return (
    <PriceBreakdownGrid
      createChildRowKeyCombination={{ keyCode: keyCodes.KEY_R, shift: true }}
      createSiblingRowKeyCombination={{ keyCode: keyCodes.INSERT, shift: true }}
      createSiblingRowAboveKeyCombination={{ keyCode: keyCodes.INSERT, shift: false }}
      expandChildrenKeyCombination={{ keyCode: keyCodes.SPACE, shift: true }}
      priceRows={priceRows}
      parentId={parentId}
      unitOptions={unitOptions}
      canChangeCells={canChangeCells}
    />
  )
}

export default ReactGrid
