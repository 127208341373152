import React from 'react'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'

const LoaderOverlayWrapper = styled.div.attrs({
  className: 'd-flex',
})`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 7% 0 0;
`

const SpinnerStyled = styled(Spinner).attrs({ color: 'primary' })`
  z-index: 999;
`

const Loader = () => (
  <LoaderOverlayWrapper>
    <SpinnerStyled />
  </LoaderOverlayWrapper>
)

export default Loader
