import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation, Trans } from 'react-i18next'

export default ({ onChange, onClose, bidderId, bidderName, isOpen }) => {
  const { t } = useTranslation()
  const onConfirm = () => {
    onChange(bidderId)
  }
  return (
    <Modal isOpen={isOpen} className={'modal-sm'}>
      <ModalHeader>{t('tender_summary_award_modal_confirm_title', 'Confirm action')}</ModalHeader>
      <ModalBody>
        <Trans i18nKey="tender_summary_award_modal_text" name={bidderName}>
          Do you really want to mark the bid from <b>{{ name: bidderName }}</b> as the winning one?
        </Trans>
        <p>
          <b>{t('tender_summary_award_modal_warning', 'Warning: This action cannot be undone!')}</b>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onClose}>
          {t('tender_summary_award_modal_cancel_btn', 'Cancel')}
        </Button>
        <Button color="secondary" onClick={() => onConfirm()}>
          {t('tender_summary_award_modal_confirm_btn', 'Confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
