import React from 'react'
import Select from 'react-select'
import './styles.css'

export const SelectStyles = ({ isInvalid }) => ({
  styles: {
    menu: (provided) => ({ ...provided, zIndex: 3 }),
    singleValue: (c, state) => ({
      ...c,
      color: '#495057',
    }),
    control: (c, state) => {
      return {
        ...c,
        border: isInvalid
          ? '1px solid red'
          : state.isFocused
          ? '1px solid #fae292'
          : state.isDisabled
          ? '1px solid #ced4da'
          : '1px solid #ced4da',
        backgroundColor: state.isDisabled ? '#f8f9fa' : c.backgroundColor,
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(245, 193, 24, 0.25)' : c.boxShadow,
      }
    },
  },
  theme: (theme) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        text: 'rgb(73, 80, 87)',
        primary: '#f7ce49',
      },
    }
  },
})

export default ({ isInvalid, props }) => <Select {...props} {...SelectStyles({ isInvalid })} />
