import {
  CellTemplate,
  Uncertain,
  Compatible,
  getCellProperty,
  UncertainCompatible,
  getCharFromKeyCode,
  isAlphaNumericKey,
  keyCodes,
  isNavigationKey,
  CellLocation,
  Id,
  TextCell,
} from '@silevis/reactgrid';
import * as React from 'react';
import { KeyCombination } from '../types/KeyCombination';
import { KeyCombinationComparer } from './KeyCombinationComparer';

export interface ExtraTextCell extends Omit<TextCell, 'type'> {
  type: 'extraText';
  createChild?: (location: CellLocation) => void;
  createSibling?: (parentId?: Id, rowId?: Id) => void;
  createSiblingAbove?: (parentId?: Id, rowId?: Id) => void;
  expandChildren?: (location: CellLocation, recursively?: boolean) => void
  location: CellLocation;
  parentId?: Id;
}

export class ExtraTextCellTemplate extends KeyCombinationComparer implements CellTemplate<ExtraTextCell> {
  getCompatibleCell(uncertainCell: Uncertain<ExtraTextCell>): Compatible<ExtraTextCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const location = getCellProperty(uncertainCell, 'location', 'object');

    let placeholder: string | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
      placeholder = '';
    }
    const value = parseFloat(text);
    return { ...uncertainCell, text, value, placeholder, location };
  }

  update(cell: Compatible<ExtraTextCell>, cellToMerge: UncertainCompatible<ExtraTextCell>): Compatible<ExtraTextCell> {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text, placeholder: cellToMerge.placeholder });
  }

  handleKeyDown(
    cell: Compatible<ExtraTextCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<ExtraTextCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift);

    const currentCombination: KeyCombination = { keyCode, ctrl, shift, alt };

    if (this.compareChildRowKeyCombination(currentCombination)) {
      cell.createChild?.(cell.location);
      return { cell, enableEditMode: false };
    }

    if (this.compareSiblingRowKeyCombination(currentCombination)) {
      cell.createSibling?.(cell.parentId, cell.location.rowId);
      return { cell, enableEditMode: false };
    }

    if (this.compareExpandChildrenKeyCombination(currentCombination)) {
      cell.expandChildren?.(cell.location)
      return { cell, enableEditMode: false }
    }


    if (this.compareSiblingRowAboveKeyCombination(currentCombination)) {
      cell.createSiblingAbove?.(cell.parentId, cell.location.rowId)
      return { cell, enableEditMode: false }
    }


    if (!ctrl && !alt && isAlphaNumericKey(keyCode) && !(shift && keyCode === keyCodes.SPACE))
      return {
        cell: this.getCompatibleCell({ ...cell, text: shift ? char : char.toLowerCase() }),
        enableEditMode: true,
      };
    return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCodes.ENTER === keyCode };
  }

  getClassName(cell: Compatible<ExtraTextCell>, isInEditMode: boolean): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className ? cell.className : '';
    return `${isValid ? 'valid' : 'invalid'} ${cell.placeholder && cell.text === '' ? 'placeholder' : ''} ${className}`;
  }

  render(
    cell: Compatible<ExtraTextCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<ExtraTextCell>, commit: boolean) => void
  ): React.ReactNode {
    if (!isInEditMode) {
      const textToDisplay = cell.text === '' ? cell.placeholder || '' : cell.text;
      return cell.renderer ? cell.renderer(textToDisplay) : textToDisplay;
    }

    return (
      <input
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.text}
        onChange={(e) => onCellChanged(this.getCompatibleCell({ ...cell, text: e.currentTarget.value }), false)}
        onBlur={(e) => onCellChanged(this.getCompatibleCell({ ...cell, text: e.currentTarget.value }), true)}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode)) e.stopPropagation();
        }}
      />
    );
  }
}
