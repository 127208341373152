import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import cn from 'classnames'
import * as yup from 'yup'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'

import { Form, Formik, FormikProps } from 'formik'
import Input from '../../../components/form/FormikInput'

import BiddersList from './BiddersTable'
import PriceBreakdown, { GRID_MODE } from './PriceBreakdown'

import { FileManager } from '../../../components/file-manager'

import { Query, Mutation } from '../../../api'
import { useRouter, useParamSwitch, useUser } from '../../../hooks'
import TenderTimeline from './tenderTimeline'
import TenderSummary from './TenderSummary'
import BidsTable from './BidsTable'
import TenderStatus, { statuses } from './TenderStatus'

import PlacePicker from '../../../components/PlacePicker'
import AddressForm from '../../../components/form/Address'
import TenderStatusForAnalysis from './TenderStatusForAnalysis'
import * as _ from 'lodash'
import { createContext } from 'react'
import { PriceBreakdownRow } from '../../../reactgrid/PriceBreakdownGrid/types/PriceBreakdownRow'

import { FormikOnChangeComponent } from './FormikOnChangeComponent'
import styled from 'styled-components'
import { useIdle } from 'react-use'
import { PriceBreakdownCellChange } from '../../../reactgrid/PriceBreakdownGrid/types/PriceBreakdownCellChange'
import { TenderStatus as TenderStatusType } from '../../../utils/types'
import { AnalyticGridWrapper } from '../../../reactgrid/AnalyticGrid'
import TenderActivityLog from './TenderActivityLog'

const Ruler = styled.span`
  font-size: 0.875rem;
  opacity: 0;
`


const TopStickyContainer = styled.div`
  display: flex;
  align-items: center;
`

const LastUpdatedAtContainer = styled.p`
  text-align: right;
  font-size: 10px;
  margin-bottom: 0px;
`

const FixedWidthTabPane = styled(TabPane)`
  width: 100%;
  overflow-x: auto;
`

const ScrollableTabPane = styled(TabPane)`
  & > nav {
    overflow-x: auto;
  }
  .page-link {
    white-space: nowrap;
  }
`

const initialRows = []

type ContextCallbackFN = (row: PriceBreakdownRow[]) => PriceBreakdownRow[]

export const PriceBreakdownContext = createContext<{
  priceRows: PriceBreakdownRow[]
  setPriceRows: (priceRows: PriceBreakdownRow[] | ContextCallbackFN) => PriceBreakdownRow[]
  gridMode: GRID_MODE
  tenderStatus?: TenderStatusType | null,
  onChange: (changes: PriceBreakdownCellChange[] | boolean) => any,
  fetching?: boolean
}>({
  priceRows: initialRows,
  setPriceRows: () => [],
  gridMode: GRID_MODE.DEFAULT,
  tenderStatus: null,
  onChange: () => console.warn('PriceBreakdownContext.onChange not implemented!')
})

const initialValues = {
  name: '',
  description: '',
  commencementDate: new Date().toISOString(),
  submissionDate: new Date().toISOString(),

  packageManagerId: null,
  bidders: [],
  files: [],
  address: { ...AddressForm.initialValues },
  notifyBiddersOnFiles: false,
}

const TenderForm = () => {
  const user = useUser()
  const { t, i18n } = useTranslation()

  const {
    match,
    history,
    query: { projectId, id: tenderId },
  } = useRouter()

  const [{ activeTab, activeRound: round }, setParams] = useParamSwitch()
  const [selectedRound, setSelectedRound] = useState(1)
  const [formError, setFormError] = useState(null)
  const [priceBreakdowns, setPriceBreakdowns] = useState([{ tenderRound: 1 }])
  const [currentPriceBreakdown, setCurrentPriceBreakdown] = useState<any>()
  const [subscription, setSubscription] = useState(null)
  const [updatedAt, setUpdatedAt] = useState<Date>()

  const [hasChangedSinceSaved, setHasChangedSinceSaved] = useState<boolean>(false)

  const [priceRows, setPriceRows] = useState<PriceBreakdownRow[]>(initialRows)
  const projectData = useQuery(Query.PROJECT, {
    variables: { id: projectId }, // ROLE SPECIFICATION REMOVED TEMPORARY
  })

  const [loadTender, { data: tenderData, loading: tenderLoading }] = useLazyQuery(Query.TENDER, {
    variables: { id: tenderId },
  })
  const [initialized, setInitialized] = useState<{ tenderData: boolean }>({ tenderData: false })

  const [updateTender, updateTenderData] = useMutation(Mutation.UPDATE_TENDER, {
    variables: { id: tenderId },
    refetchQueries: [{ query: Query.TENDER, variables: { id: tenderId } }],
  })

  const [resendNotification, resendNotificationData] = useMutation(
    Mutation.NOTIFY_BIDDER_TENDER_RUNNING,
    {
      variables: { tenderId },
      onCompleted: () => {
        toastr.success('', t('Procurement_tenders_tender_bidder_successfully_notified'))
      },
    },
  )

  const [setTenderFiles] = useMutation(Mutation.SET_TENDER_FILES)

  const [createTender, createTenderData] = useMutation(Mutation.CREATE_TENDER, {
    refetchQueries: [{ query: Query.TENDERS, variables: { projectId } }],
    onCompleted: ({ createTender }) => {
      history.replace(`${match.url.replace('new', createTender.id)}`)
    },
  })


  const schema = yup.object().shape({
    name: yup
      .string()
      .min(3, t('Procurement_tenders_new_tender_name_min'))
      .required(t('Procurement_tenders_new_tender_name_required')),
    description: yup.string().required(t('Procurement_tenders_new_tender_description_required')),
    commencementDate: yup
      .date()
      .test(
        'commencementDate',
        t('Procurement_tenders_new_tender_commencement_date_must_be_in_future'),
        function (value) {
          if (tender.status === 'RUNNING') return true

          return moment(value).isAfter(moment())
        },
      )
      .required(t('Procurement_tenders_new_tender_commencememt_date_required')),
    submissionDate: yup
      .date()
      .test(
        'submissionDate',
        t(
          'Procurement_tenders_new_tender_submission_date_must_be_after_commencement',
          'Must be after Commencement Date',
        ),
        function (value) {
          return moment(this.parent.commencementDate).isBefore(moment(value))
        },
      )
      .required(t('Procurement_tenders_new_tender_submission_date_required')),
    packageManagerId: yup
      .string()
      .required(t('Procurement_tenders_new_tender_package_manager_required'))
      .nullable(),
    address: AddressForm.validationSchemaRequired,
  })

  useEffect(() => {
    if (tenderId && tenderId !== 'new') {
      loadTender()
    }
  }, [tenderId])

  useEffect(() => {
    if (tenderData && !initialized.tenderData) {
      const { tender } = tenderData

      let selRound = tender.round
      if (!tender.round && round) {
        selRound = round
      }
      setParams(activeTab, selRound)
      setSelectedRound(selRound)

      setSubscription(tender.organization.subscription)
      if (tender.priceBreakdowns.length) {
        const pbs = tender.priceBreakdowns
        for (let i = 1; i <= tender.round; i++) {
          if (!pbs.find((pb) => pb.tenderRound === i)) {
            pbs.push({ tenderRound: i })
          }
        }

        setPriceBreakdowns(pbs)
        let cpb = pbs.find((pb) => pb.tenderRound === selRound)
        setCurrentPriceBreakdown(cpb)

        if (cpb && cpb?.children?.length)
          setPriceRows(
            cpb.children.sort((a, b) => a.idx - b.idx).map(({ parentId, ...rest }, i) => ({
              ...rest,
              ...(parentId ? { parentId } : {}),
              expanded: false,
            })),
          )
      }
      // Hacky
      setTimeout(() => setInitialized((init) => ({ ...init, tenderData: true })), 2000)

    }
  }, [tenderData])

  useEffect(() => {
    if (!tenderData || !tenderData.tender.id) return


    setShouldAutosave(true)

  }, [priceRows])

  useEffect(() => {
    if (updateTenderData.data) {
      setUpdatedAt(updateTenderData.data.updateTender.updatedAt)
    } else if (tenderData) {
      setUpdatedAt(tenderData.tender.updatedAt)
    }
  }, [tenderData, updateTenderData.data])

  useEffect(() => {
    const priceBreakdown = priceBreakdowns.find((pb) => pb.tenderRound === selectedRound)
    if (!priceBreakdown) setPriceBreakdowns(priceBreakdowns.concat({ tenderRound: selectedRound }))
    //else setCurrentPriceBreakdown(priceBreakdown)//kdyz to tu je, tak se do analysis nenactou data
  }, [selectedRound])

  const tender = tenderData ? tenderData.tender : { statusActions: [], bids: [], files: [] }
  const { id, bidders, files, packageManager, priceBreakdown, ...formValues } = tender
  const users = projectData.data ? projectData.data.project.users.map((u) => u.user) : []
  const isPackageManager = tender?.packageManager?.id === user?.id

  const readOnly = tender.status ? ['AWARDED', 'CLOSED'].indexOf(tender.status) > -1 || !isPackageManager : false


  const biddersReadOnly = tender.status && ['AWARDED', 'CLOSED'].indexOf(tender.status) > -1
  const status = statuses[tender.status] || {}
  const isNew = tenderId === 'new'
  const hasSomeBids =
    tender.bids.filter((b) => b.round === selectedRound && b.priceBreakdown).length > 0

  const isLoading =
    tenderLoading || updateTenderData.loading || createTenderData.loading || tenderLoading

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  // For form initialization, so it doesn't trigger autosave
  // when form is first loaded
  const [shouldAutosave, setShouldAutosave] = useState<boolean>(false)

  const isIdle = useIdle(3000)

  const placePickerOnChange = (address, setFieldValue, setFieldTouched) => {
    let addressFieldValue = {
      city: address.city,
      street: address.street_name + ' ' + address.street_number,
      zip: address.zip,
      country: address.country,
      lat: address.latLng.lat,
      lng: address.latLng.lng,
    }
    setFieldValue('address', addressFieldValue)
    setFieldTouched('address')
  }

  const onRoundChange = (newRound) => {
    setParams(activeTab, newRound)
    setSelectedRound(newRound)
  }

  const autosave = async () => {
    if (!tenderData || !tenderData.tender.id) return
    if (!hasChangedSinceSaved) return

    if (!updateTenderData.loading && !isSubmitting) {
      setIsSubmitting(true)
      await formikRef?.current?.submitForm()
      setIsSubmitting(false)
      setHasChangedSinceSaved(false)
    }
  }

  useEffect(() => {
    if (!shouldAutosave) return setShouldAutosave(true)
    autosave()
  }, [isIdle])

  const onPriceRowsChanged = (changes: PriceBreakdownCellChange[] | boolean) => {
    if (typeof changes !== 'boolean' && changes.every((change) => change.type.toLowerCase().includes('chevron'))) return

    setHasChangedSinceSaved(true)
  }

  const formikRef = useRef<FormikProps<any>>(null)


  return (
    <>
      <Ruler id='ruler' />
      <Formik
        innerRef={formikRef}
        validationSchema={readOnly ? undefined : schema}
        enableReinitialize
        initialValues={{
          ...{
            ...initialValues,
            ...{ address: projectData.data && projectData.data.project.address },
          },
          ...formValues,
          bidders: bidders ? bidders.map((b) => b.id) : [],
          packageManagerId: packageManager ? packageManager.id : null,
          files: files,
          priceBreakdowns,
        }}
        validateOnChange
        onSubmit={async (values, { setTouched }) => {
          setFormError(null)

          try {
            if (id) {
              const fileInfo = values.priceBreakdownFile
                ? {
                  file: values.priceBreakdownFile,
                  size: values.priceBreakdownFile.size,
                }
                : {}

              let variables = {
                ...values,
                ...fileInfo,
                priceBreakdown: priceRows,
                tenderRound: selectedRound,
                updatedAt: Date.now(),
                hardcopyAddress: undefined,
              }

              delete variables.files

              await updateTender({
                variables,
              })
            } else {
              await createTender({ variables: { ...values, projectId } })
            }

            setTouched({})

            isNew &&
              toastr.success(
                '',
                id
                  ? t('Procurement_tenders_new_tender_tender_updated_label')
                  : t('Procurement_tenders_new_tender_tender_created_label'),
              )
          } catch (e) {
            setFormError(e.message)
          }
        }}
      >
        {({ values, setFieldValue, touched, setFieldTouched, errors, submitForm }) => {
          const format = 'DD-MM-YYYY'
          const isTouched = Object.keys(touched).length > 0
          const isSameDay = (a = values.commencementDate, b = values.submissionDate) =>
            moment(a).format(format) === moment(b).format(format)

          const setField = (name, value) => {
            if (name === 'files' && readOnly) {
              setFieldValue('notifyBiddersOnFiles', true)
            }
            setFieldValue(name, value)
            // setFieldTouched(name)
          }


          const displayAnalysisAndBids = !isNew && hasSomeBids && currentPriceBreakdown && tender.status !== 'RUNNING'


          return (
            <Form>
              {!isLoading && tenderData && initialized.tenderData && <FormikOnChangeComponent onChange={() => setHasChangedSinceSaved(true)} />}
              <Row>
                <Col>
                  <Row>
                    <Col md={['analysis', 'price'].includes(activeTab) ? 12 : 9}>
                      <>
                        <div className="h3 mb-3 sticky-top my-1 p-3 bg-white shadow rounded">
                          <TopStickyContainer>
                            <h3 className="mb-0 mr-2">
                              {isLoading
                                ? t('Procurement_tenders_tender_heading_loading', 'Loading ...')
                                : tender.name || t('Procurement_tenders_new_tender_heading')}{' '}
                            </h3>

                            <span className={`badge badge-${status.color}`}>{t(status.title)}</span>

                            <div
                              className="float-right"
                              style={{
                                position: 'absolute',
                                top: 6,
                                right: 10,
                              }}
                            >
                              <Button type={'submit'} color="success">
                                {
                                  isLoading ? t('Procurement_tenders_tender_heading_loading') :
                                    id
                                      ? t('Procurement_tenders_new_tender_update_tender_btn')
                                      : t('Procurement_tenders_new_tender_create_tender_btn')
                                }
                                {updatedAt && (
                                  <LastUpdatedAtContainer>
                                    {t('Tender_last_updated_at') + moment(updatedAt).calendar()}
                                  </LastUpdatedAtContainer>
                                )}
                              </Button>
                            </div>
                          </TopStickyContainer>


                        </div>

                        <div className="tab" style={{ position: 'relative' }}>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={cn({ active: activeTab === 'info' })}
                                style={
                                  Object.entries(errors).length > 0 &&
                                    activeTab !== 'info' &&
                                    !isLoading &&
                                    isTouched
                                    ? {
                                      borderTop: '1px solid #f44455',
                                      borderLeft: '1px solid #f44455',
                                      borderRight: '1px solid #f44455',
                                      color: '#f44455',
                                      background: '#ffe1e4',
                                    }
                                    : {}
                                }
                                onClick={() => {
                                  setParams('info', selectedRound)
                                  if (!readOnly) {
                                    setFieldTouched('name')
                                    setFieldTouched('description')
                                    setFieldTouched('commencementDate')
                                    setFieldTouched('submissionDate')
                                    setFieldTouched('packageManagerId')
                                    setFieldTouched('address')
                                  }
                                }}
                              >
                                {t('Tender_form_general')}
                              </NavLink>
                            </NavItem>
                            {!isNew && (
                              <NavItem>
                                <NavLink
                                  className={cn({ active: activeTab === 'bidders' })}
                                  onClick={() => setParams('bidders', selectedRound)}
                                >
                                  {t('Tender_form_bidders_list')}{' '}
                                  {values.bidders.length > 0 ? ` (${values.bidders.length})` : ''}
                                </NavLink>
                              </NavItem>
                            )}
                            {!isNew && (
                              <NavItem>
                                <NavLink
                                  className={cn({ active: activeTab === 'price' })}
                                  onClick={() => setParams('price', selectedRound)}
                                >
                                  {t('Tender_form_price_breakdown')}
                                </NavLink>
                              </NavItem>
                            )}
                            {!isNew && (
                              <NavItem>
                                <NavLink
                                  className={cn({ active: activeTab === 'documents' })}
                                  onClick={() => setParams('documents', selectedRound)}
                                >
                                  {t('Tender_form_documents')}
                                  {values.files.length > 0 ? ` (${values.files.length})` : ''}
                                </NavLink>
                              </NavItem>
                            )}

                            {displayAnalysisAndBids && (
                              <NavItem>
                                <NavLink
                                  className={cn({ active: activeTab === 'bids' })}
                                  onClick={() => setParams('bids', selectedRound)}
                                >
                                  {t('Tender_form_bids')}{' '}
                                  {tender.bids.filter(
                                    (b) => b.round === selectedRound && b.priceBreakdown,
                                  ).length > 0
                                    ? ` (${tender.bids.filter(
                                      (b) => b.round === selectedRound && b.priceBreakdown,
                                    ).length
                                    })`
                                    : ''}
                                </NavLink>
                              </NavItem>
                            )}
                            <NavItem>
                              <NavLink
                                onClick={() => setParams('activity-logs', selectedRound)}
                                className={cn({ active: activeTab === 'activity-logs' })}>
                                {t('Tender_form_activity_logs')}
                              </NavLink>
                            </NavItem>
                            {displayAnalysisAndBids && (
                              <NavItem>
                                <NavLink
                                  className={cn({ active: activeTab === 'analysis' })}
                                  onClick={() => setParams('analysis', selectedRound)}
                                >
                                  {t('Tender_form_analysis')}
                                </NavLink>
                              </NavItem>
                            )}

                            {['analysis', 'price'].includes(activeTab) && (
                              <TenderStatusForAnalysis
                                onRoundChange={onRoundChange}
                                initialRound={selectedRound}
                                noItemsInBreakdown={
                                  !(currentPriceBreakdown && currentPriceBreakdown.children) &&
                                  !priceRows.length
                                }
                                noBidders={values.bidders.length === 0}
                                tenderFormTouched={isTouched && !readOnly}
                                // tenderFormTouched={false /*isTouched && !readOnly*/} //autosave
                                commencementDateInvalid={
                                  moment().diff(values.commencementDate) > 0 &&
                                  tender.status &&
                                  ['RUNNING', 'CLOSED'].indexOf(tender.status) === -1
                                }
                                submissionDateInvalid={
                                  moment().diff(values.submissionDate) > 0 &&
                                  tender.status &&
                                  ['RUNNING', 'CLOSED'].indexOf(tender.status) === -1
                                }
                              />
                            )}

                          </Nav>

                          <TabContent activeTab={activeTab}>
                            <TabPane tabId="info">
                              <p className="h4">{t('Tender_form_general_information')}</p>
                              <Input
                                label={t('Procurement_tenders_new_tender_name_label')}
                                name="name"
                                placeholder={t('Procurement_tenders_new_tender_name_placeholder')}
                                readOnly={readOnly}
                              />
                              <Input
                                label={t('Procurement_tenders_new_tender_description_label')}
                                type="textarea"
                                name="description"
                                placeholder={t(
                                  'Procurement_tenders_new_tender_description_placeholder',
                                )}
                                rows={5}
                                readOnly={readOnly}
                              />
                              <Row>
                                <Col sm={6}>
                                  <Input
                                    label={t(
                                      'Procurement_tenders_new_tender_commencement_date_label',
                                    )}
                                    type="datetime"
                                    name="commencementDate"
                                    placeholder={t(
                                      'Procurement_tenders_new_tender_commencement_date_placeholder',
                                    )}
                                    readOnly={readOnly || tender.status === 'RUNNING'}
                                    minDate={new Date()}
                                    minTime={
                                      isSameDay(values.commencementDate, moment().toDate())
                                        ? moment().toDate()
                                        : moment()
                                          .hours(0)
                                          .minutes(0)
                                          .seconds(0)
                                          .toDate()
                                    }
                                    maxTime={moment()
                                      .hours(23)
                                      .minutes(59)
                                      .seconds(59)
                                      .toDate()}
                                    onChange={(value) => {
                                      setField('commencementDate', moment(value))
                                    }}
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Input
                                    minDate={new Date(values.commencementDate)}
                                    minTime={
                                      isSameDay()
                                        ? moment(values.commencementDate)
                                          .add(1, 'minutes')
                                          .toDate()
                                        : moment()
                                          .hours(0)
                                          .minutes(0)
                                          .seconds(0)
                                          .toDate()
                                    }
                                    maxTime={moment()
                                      .hours(23)
                                      .minutes(30)
                                      .seconds(0)
                                      .toDate()}
                                    onChange={(value) => {
                                      if (moment(value).isBefore(moment(values.commencementDate))) {
                                        setFieldTouched('submissionDate')
                                      }
                                    }}
                                    onBlur={() => {
                                      setFieldTouched('submissionDate')
                                    }}
                                    label={t(
                                      'Procurement_tenders_new_tender_submission_date_label',
                                    )}
                                    type="datetime"
                                    name="submissionDate"
                                    placeholder={t(
                                      'Procurement_tenders_new_tender_submission_date_placeholder',
                                    )}
                                    readOnly={readOnly && tender.status !== 'RUNNING'}
                                  />
                                </Col>
                              </Row>
                              <Input
                                label={t('Procurement_tenders_new_tender_package_manager_label')}
                                type="select"
                                name="packageManagerId"
                                labelField="name"
                                valueField="id"
                                placeholder={t(
                                  'Procurement_tenders_new_tender_package_manager_placeholder',
                                )}
                                options={users}
                                readOnly={readOnly}
                              />
                              <Row>
                                <Col>
                                  <p className="h4">
                                    {t('Tender_form_general_information_address')}
                                  </p>
                                  <PlacePicker
                                    readOnly={readOnly}
                                    onChange={(address, e) => {
                                      placePickerOnChange(address, setFieldValue, setFieldTouched)
                                    }}
                                  />
                                  <AddressForm readOnly={readOnly} name="address" />
                                </Col>
                              </Row>
                            </TabPane>
                            {!isNew && (
                              <TabPane tabId="bidders">
                                <BiddersList
                                  readOnly={biddersReadOnly}
                                  tenderStatus={tender.status}
                                  selection={values.bidders}
                                  onResendNotification={() => {
                                    resendNotification({
                                      variables: {
                                        tenderId,
                                      },
                                    })
                                  }}
                                  onSelectionChange={(s) => setField('bidders', s)}
                                  winnerId={tender.winnerBidder && tender.winnerBidder.id}
                                />
                              </TabPane>
                            )}
                            {!isNew && (
                              <ScrollableTabPane tabId="price">
                                <PriceBreakdownContext.Provider
                                  value={{
                                    priceRows,
                                    fetching: !initialized.tenderData,
                                    onChange: onPriceRowsChanged,
                                    // @ts-ignore
                                    setPriceRows,
                                    tenderStatus: tender.status,
                                    gridMode: GRID_MODE.DEFAULT,
                                  }}
                                >
                                  {initialized && tenderData &&
                                    <PriceBreakdown readOnly={readOnly} />
                                  }
                                </PriceBreakdownContext.Provider>
                              </ScrollableTabPane>
                            )}
                            {!isNew && (
                              <TabPane tabId="documents">
                                {/* @ts-ignore */}
                                <FileManager
                                  readOnly={readOnly}
                                  files={values.files}
                                  showFileSelect
                                  onSelected={(s) => {
                                    setTenderFiles({
                                      variables: {
                                        id: tenderId,
                                        files: [...values.files.map((f) => f.id), ...s],
                                      },
                                    })
                                  }}
                                  onRemoved={(s) => {
                                    setTenderFiles({
                                      variables: {
                                        id: tenderId,
                                        files: [...values.files.map((f) => f.id)].filter(
                                          (f) => s.indexOf(f) === -1,
                                        ),
                                      },
                                    })
                                  }}
                                  directory={'general'}
                                  uploadDirectory={`tender/${tenderId}`}
                                />
                              </TabPane>
                            )}
                            {!isNew && hasSomeBids && tender.status !== 'running' && (
                              <TabPane tabId="bids">
                                <BidsTable
                                  bids={tender.bids.filter(
                                    (b) => b.round === selectedRound && b.priceBreakdown,
                                  )}
                                  winningBidId={
                                    tender.winnerBidder &&
                                    tender.bids.filter(
                                      (b) =>
                                        b.round === tender.round &&
                                        b.bidder.id === tender.winnerBidder.id,
                                    )[0].id
                                  }
                                />
                              </TabPane>
                            )}

                            {!isNew && hasSomeBids && currentPriceBreakdown && activeTab === 'analysis' && tender.status !== 'running' && (
                              <>
                                <PriceBreakdownContext.Provider
                                  value={{
                                    // priceRows,
                                    fetching: !initialized.tenderData,
                                    onChange: onPriceRowsChanged,
                                    // @ts-ignore
                                    setPriceRows,
                                    tenderStatus: tender.status,
                                    gridMode: GRID_MODE.DEFAULT,
                                  }}
                                >
                                  {initialized && tenderData &&
                                    <AnalyticGridWrapper tender={tenderData.tender} activeRound={selectedRound} />
                                  }
                                </PriceBreakdownContext.Provider>
                                <FixedWidthTabPane tabId="analysis" >
                                </FixedWidthTabPane>
                              </>
                            )}

                            <TabPane tabId='activity-logs'>
                              <TenderActivityLog tenderId={tenderId} />
                            </TabPane>

                          </TabContent>
                        </div>

                        <div className="mt-3">
                          <TenderTimeline />
                        </div>
                      </>
                    </Col>
                    {!['analysis', 'price'].includes(activeTab) && (
                      <Col md={3}>
                        <TenderStatus
                          onRoundChange={onRoundChange}
                          initialRound={selectedRound}
                          noItemsInBreakdown={
                            !(currentPriceBreakdown && currentPriceBreakdown.children) &&
                            !priceRows.length
                          }
                          noBidders={values.bidders.length === 0}
                          tenderFormTouched={isTouched && !readOnly}
                          commencementDateInvalid={
                            moment().diff(values.commencementDate) > 0 &&
                            tender.status &&
                            ['RUNNING', 'CLOSED'].indexOf(tender.status) === -1
                          }
                          submissionDateInvalid={
                            moment().diff(values.submissionDate) > 0 &&
                            tender.status &&
                            ['RUNNING', 'CLOSED'].indexOf(tender.status) === -1
                          }
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default TenderForm
