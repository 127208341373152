import { gql } from 'apollo-boost'

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    name
    email
    rights
  }
`

export const BidderFragment = gql`
  fragment BidderFragment on Bidder {
    id
    name
    contactName
    email
    tags
    phone
    address
    deleted
    tenders {
      id
      name
    }
  }
`
export const PriceBreakdownNodeContentFragment = gql`
  fragment PriceBreakdownNodeContentFragment on PriceBreakdownNode {
    id
    name
    price
    unit
    acreage
    unitPrice
  }
`

export const PriceBreakdownNodeFragment = gql`
  fragment PriceBreakdownNodeFragment on PriceBreakdownNode {
    id
    name
    price
    unit
    acreage
    unitPrice
    quantity
    parentId
    rowId
    idx
  }
`

export const PriceBreakdownDataFragment = gql`
  fragment PriceBreakdownDataFragment on PriceBreakdown {
    disableUpload
    data {
      ...PriceBreakdownNodeFragment
      children {
        ...PriceBreakdownNodeFragment
        children {
          ...PriceBreakdownNodeFragment
          children {
            ...PriceBreakdownNodeFragment
            children {
              ...PriceBreakdownNodeFragment
            }
          }
        }
      }
    }
  }
  ${PriceBreakdownNodeFragment}
`

export const TenderBidderFragment = gql`
  fragment TenderBidderFragment on Bidder {
    id
    name
    contactName
    email
    address
  }
`

export const LogFragment = gql`
  fragment LogFragment on Log {
    id
    message
    data
    createdAt
    type
    children {
      id
      message
      createdAt
      public
      parent {
        id
      }
    }
    user {
      id
      name
      email
    }
    bidder {
      id
      name
      contactName
      email
    }
    parent {
      id
    }
  }
`

export const FileFragment = gql`
  fragment FileFragment on File {
    id
    filename
    size
    key
    mimetype
    createdAt
    user {
      id
      name
    }
    bidder {
      id
      name
    }
  }
`

export const BidFragment = gql`
  fragment BidFragment on Bid {
    id
    round
    bidder {
      id
      name
      contactName
      email
    }
    prices
    priceBreakdown {
      id
      file {
        id
      }
      disableUpload
    }
  }
`

export const TenderFragment = gql`
  fragment TenderFragment on Tender {
    id
    name
    description
    address
    status
    statusActions
    commencementDate
    submissionDate
    round
    hardcopyRequired
    hardcopyAddress
    updatedAt
    bids {
      id
      prices
      round
      priceBreakdown {
        id
        disableUpload
      }
    }
    packageManager {
      ...UserFragment
    }
    bidders {
      ...TenderBidderFragment
    }
    files {
      ...FileFragment
    }

    priceBreakdowns {
      id
      file {
        id
      }
      #data {
      # id
      # }
    }
  }

  ${UserFragment}
  ${TenderBidderFragment}
  ${FileFragment}
`

export const ProjectFragment = gql`
  fragment ProjectFragment on Project {
    id
    name
    description
    address
    tenders {
      id
      name
      bidders {
        id
        name
      }
    }
    users {
      id
      user {
        id
        name
      }
    }
  }
`
