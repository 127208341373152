import React, { useState, useRef } from 'react'
import { Button, Media, Spinner } from 'reactstrap'
import moment from 'moment'
import { statuses } from './../TenderStatus'
import { useRouter } from '../../../../hooks'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Query, Mutation } from '../../../../api'
import md5 from 'md5'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import MessageReply from './components/MessageReply'

const LogItem = ({ log, children }) => {
  const { t } = useTranslation()
  const sender = log.user || log.bidder || { email: '', name: 'System' }
  return (
    <Media style={{ width: '100%' }} className={'mt-4'}>
      <img
        src={`https://www.gravatar.com/avatar/${md5(sender.email)}?d=mp`}
        width="36"
        height="36"
        className="rounded-circle mr-2 mt-1"
      />
      <Media body className="mb-2">
        <small className="float-right text-navy">
          {t('tender_timeline_date_from_now', { date: new moment(log.createdAt) })}
        </small>
        <strong>{sender.name}</strong> {children || log.message}
        <div>
          <small className="text-muted">
            {t('formatter_dateTime', { date: new moment(log.createdAt) })}
          </small>
        </div>
        {log.children &&
          log.children.map((log, i) => {
            return (
              <LogItem log={log}>
                <div className="bg-white border rounded shadow p-2">
                  {/* children:{log.public ? 'public' : 'private'} */}
                  {log.message}
                </div>
              </LogItem>
            )
          })}
      </Media>
    </Media>
  )
}

export default () => {
  const { t } = useTranslation()
  const [count, setCount] = useState(5)
  const [expandedReplyInputKey, setExpandedReplyInputKey] = useState(null)
  const messageInputRef = useRef()
  const {
    query: { id },
  } = useRouter()
  const { data, loading } = useQuery(Query.TENDER, { variables: { id } })
  const [sendMessage, sendMessageData] = useMutation(Mutation.TENDER_MESSAGE, {
    variables: { tenderId: id },
    refetchQueries: [{ query: Query.TENDER, variables: { id } }],
  })

  const handleSend = async () => {
    await sendMessage({ variables: { message: messageInputRef.current.value } })
    messageInputRef.current.value = ''
  }

  if (!data) return null
  if (!data.tender) return null

  return (
    <div>
      <div className="mb-3 input-group">
        <input
          type="text"
          ref={messageInputRef}
          disabled={sendMessageData.loading}
          className="form-control form-control-lg"
          onKeyDown={({ keyCode }) => keyCode === 13 && handleSend()}
          placeholder={t('Tender_timeline_write_a_message')}
        />
        <div className="input-group-append">
          <Button
            size="lg"
            color="outline-primary"
            disabled={sendMessageData.loading}
            onClick={handleSend}
          >
            {t('Tender_timeline_send_btn')} {sendMessageData.loading && <SpinnerButton />}
          </Button>
        </div>
      </div>
      <div>
        {data.tender.logs.slice(0, count).map((l, i) => (
          <React.Fragment key={i}>
            {
              <Message
                log={l}
                tenderId={id}
                onReplyInputExpand={() => {
                  setExpandedReplyInputKey(i)
                }}
                onReplyInputClose={() => {
                  setExpandedReplyInputKey(null)
                }}
                onReplySubmit={() => {
                  setExpandedReplyInputKey(null)
                }}
                expanded={
                  expandedReplyInputKey !== null && expandedReplyInputKey === i ? true : false
                }
              />
            }
          </React.Fragment>
        ))}
      </div>

      {data.tender.logs.length > 5 && (
        <Button color="link" className="btn-block" onClick={() => setCount(count === 5 ? 1000 : 5)}>
          {`${t('Tender_timeline_show_label')} ${
            count === 5 ? t('Tender_timeline_more_label') : t('Tender_timeline_less_label')
          } ...`}
        </Button>
      )}
    </div>
  )
}

const Message = ({
  log,
  tenderId,
  onReplyInputExpand,
  onReplyInputClose,
  onReplySubmit,
  expanded,
}) => {
  const { t } = useTranslation()

  const [sendMessageReply, sendMessageReplyData] = useMutation(Mutation.TENDER_MESSAGE, {
    variables: { tenderId: tenderId },
    refetchQueries: [{ query: Query.TENDER, variables: { id: tenderId } }],
  })
  const handleReply = async (message, isPublic, parentId) => {
    await sendMessageReply({
      variables: { message: message, parentId: parentId, isPublic: isPublic },
    })
  }

  switch (log.type) {
    case 'MESSAGE':
      return (
        <LogItem log={log}>
          <div className="bg-white border rounded shadow p-2">{log.message}</div>
          {log.children.length === 0 && (
            <MessageReply
              loading={sendMessageReplyData.loading}
              onExpand={() => {
                onReplyInputExpand && onReplyInputExpand()
              }}
              onClose={() => {
                onReplyInputClose && onReplyInputClose()
              }}
              onSubmit={(message, isPublic) => {
                handleReply(message, isPublic, log.id)
                onReplySubmit && onReplySubmit(message, isPublic)
              }}
              expanded={expanded}
            />
          )}
        </LogItem>
      )
    case 'EVENT':
      if (log.message && log.data.round && log.message === 'BID_SENT') {
        return (
          <LogItem log={log}>
            <strong className="text-truncate">
              <Trans i18nKey="Tender_timeline_message_event_BID_SENT" round={log.data.round}>
                sent bid for round {{ round: log.data.round }}
              </Trans>
            </strong>
          </LogItem>
        )
      }
      return (
        <LogItem log={log}>
          <strong className="text-truncate">
            {t('Tender_timeline_message_event_' + log.message)}
          </strong>
        </LogItem>
      )
    case 'STATE_CHANGE':
      return (
        <LogItem log={log}>
          <strong className="text-truncate">{t('Tender_timeline_state_change_by')}</strong>
          <br />
          <span className={`text-${statuses[log.data.before.status].color}`}>
            {t(statuses[log.data.before.status].title)}
          </span>
          {' -> '}
          <span className={`text-${statuses[log.data.after.status].color}`}>
            {t(statuses[log.data.after.status].title)}
          </span>
        </LogItem>
      )
  }
}

const SpinnerButton = styled(Spinner).attrs(() => ({
  size: 'sm',
}))`
  vertical-align: middle;
`
