import React, { useState } from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import TendersTable from './components/TendersTable'
import { useTranslation } from 'react-i18next'

const Tenders = () => {
  const { t } = useTranslation()

  return (
    <Container fluid className="p-0">
      <h1 className="h2 mb-3">{t('Procurement_tenders_tenders_label')}</h1>
      <Card>
        <CardBody>
          <TendersTable />
        </CardBody>
      </Card>
    </Container>
  )
}

export default Tenders
