import React from 'react'
import { Container, Row, Spinner } from 'reactstrap'
import cn from 'classnames'

interface ILoaderProps {
  className?: string
}

const Loader = ({ className }: ILoaderProps) => (
  <Container fluid className={cn(className, 'h-50 d-flex')}>
    <Row className="justify-content-center align-self-center w-100 text-center">
      <Spinner color="primary" />
    </Row>
  </Container>
)

export default Loader
