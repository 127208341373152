import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

export const FormikOnChangeComponent = ({ onChange }) => {
  const { values } = useFormikContext()
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (isInitialized) onChange(values)
    else if (values) setIsInitialized(true)
  }, [values])

  return null
}
