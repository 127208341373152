import React from 'react'
import Grid from '../../../components/grid'
import { useTranslation } from 'react-i18next'
import BidsFiles from './BidsFiles'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { numberFormat } from '../../../utils'


const BidsTable = ({ bids, winningBidId }) => {
  const { t } = useTranslation()

  const rows = bids.map((b) => ({
    ...b,
    name: b.bidder.contactName ? `${b.bidder.name} (${b.bidder.contactName})` : b.bidder.name,
    totalPrice: b.priceBreakdown.children.reduce((acc, curr) => acc + curr.price ?? 0, 0)
  }))

  return (
    <Grid
      columns={[
        { name: 'name', title: t('Tender_bids_table_column_name', 'Name') },
        { name: 'totalPrice', title: t('Tender_bids_table_column_price', 'Total price') },
        { name: 'updatedAt', title: t('Tender_bids_table_column_updated_at', 'Last modification') },
        { name: 'files', title: t('Tender_bids_table_column_files', 'Files') },
        { name: 'winner', width: 30 },
      ]}
      formatters={[
        {
          formatter: ({ value, row }) => numberFormat.format(value),
          for: ['totalPrice'],
        },
        {
          formatter: ({ value, row }) => <BidsFiles files={value} />,
          for: ['files'],
        },
        {
          formatter: ({ value, row }) => {
            if (row.id === winningBidId) return <Icon icon={faTrophy} className={'text-warning'} />
            return null
          },
          for: ['winner'],
        },
      ]}
      rows={rows}
    />
  )
}

export default BidsTable
