import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import Grid from '../../../components/grid'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { HOST_URL } from '../../../utils'
import { FileGrid } from '../../../components/file-manager'
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons'

const DocumentsTable = ({ tender }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState([])
  const [selectedTotalSize, setSelectedTotalSize] = useState(0)
  const maxDownloadSize = 104857600 // 100mb

  useEffect(() => {
    let selectedFiles = tender.files.filter((f) => selected.indexOf(f.id) > -1)
    setSelectedTotalSize(selectedFiles.reduce((sum, file) => sum + file.size, 0))
  }, [selected])

  const COLUMNS = [
    { title: t('Organization_documents_file_name_label'), name: 'filename', width: 300 },
    { title: t('Organization_documents_size_label'), name: 'size', align: 'right' },
  ]

  return (
    <FileGrid
      actionBar={{
        showSearch: false,
        actionButtonRight: (
          <Button
            color="outline-warning"
            className="btn-pill"
            size="lg"
            disabled={selected.length === 0}
            onClick={() => {
              window.location.href = `${HOST_URL}/download/zip?files=${selected.join('&files=')}`
            }}
          >
            <Icon icon={faDownload} /> {t('FileManager_download_btn')}
          </Button>
        ),
      }}
      selectByRowClick
      selection={selected}
      onSelectionChange={setSelected}
      columns={COLUMNS}
      rows={tender.files}
      rowActions={[
        {
          title: t('Organization_documents_upload_download_btn'),
          action: (r) => window.open(`${HOST_URL}/download/${r.id}`),
        },
      ]}
    />
  )
}

export default DocumentsTable
