import TreeModel from 'tree-model'
import { PriceBreakdownRow } from './PriceBreakdownGrid/types/PriceBreakdownRow'
import ExcelJS from 'exceljs'
import getSortedPriceRows from './PriceBreakdownGrid/functions/getSortedPriceRows'
import { nanoid } from 'nanoid'
import { Id } from '@silevis/reactgrid'
import { numberFormat } from '../utils'
import { GRID_MODE } from '../pages/tendering/components/PriceBreakdown'

const isNullOrUndefined = (prop) => prop === null || prop === undefined

const nestedSearch = (array: any[], key: string, value: any) => {
  let o
  array.some(function iter(a) {
    if (a[key] === value) {
      o = a
      return true
    }
    return Array.isArray(a.children) && a.children.some(iter)
  })
  return o
}

export const parseBreakdownToTreeModel = (priceBreakdownRows: PriceBreakdownRow[]) => {
  priceBreakdownRows = getSortedPriceRows(priceBreakdownRows)
  const rootRows: PriceBreakdownRow[] = priceBreakdownRows.filter(({ parentId }) => !parentId)
  const childRows: PriceBreakdownRow[] = priceBreakdownRows.filter(({ parentId }) => !!parentId)

  const treeData = rootRows.map((row) => ({ ...row, children: [] }))
  let idx = 0
  let iterationsCnt = 0
  const maxIterations = Math.pow(priceBreakdownRows.length + 1, 2) * 2

  try {
    while (childRows.length) {
      let current = childRows[idx]
      if (current) {
        const parent = nestedSearch(treeData, 'rowId', current.parentId)
        if (parent) {
          if (!parent.children) parent.children = []
          const existingIndex = parent.children.findIndex((child) => child.rowId === current.rowId)
          if (existingIndex === -1) parent.children.push(...childRows.splice(idx, 1))
          else parent.children[existingIndex] = childRows.splice(idx, 1)
        }
      }
      idx++
      iterationsCnt++

      if (idx > childRows.length) idx = 0
      if (iterationsCnt >= maxIterations)
        throw new Error(
          `Max iterations (${maxIterations}) exceeded in function parseBreakdownToTreeModel.`,
        )
    }
  } catch (e) {
    console.error(e)
  }
  const tree = new TreeModel().parse<{ children: PriceBreakdownRow }>({ children: treeData as any })

  tree.walk((node) => {
    if (node.children) node.children.sort((a, b) => a.model.idx - b.model.idx)
    return true
  })

  return tree
}


function setCharAt(str: string, index: number, chr: string): string {
  if (index > str.length - 1) return str
  return str.substring(0, index) + chr + str.substring(index + 1)
}

interface PriceRowWithOutline extends PriceBreakdownRow {
  _outlineLevel?: number
}
export const toNumericValue = (stringValue: string): number | string => {
  if (typeof stringValue === 'number') return numberFormat.format(stringValue)


  if (isNullOrUndefined(stringValue)) return '0.00'

  try {
    let indexOfDecimalSeparator = stringValue.indexOf('.', stringValue.length - 3)

    if (indexOfDecimalSeparator === -1) indexOfDecimalSeparator = stringValue.indexOf(',', stringValue.length - 3)

    // Note: if there is some problem with this, it might be with encoding of emoji char. 
    // Then use some other symbol thats unlikely to be present in number (~, @...)

    if (indexOfDecimalSeparator !== -1) stringValue = setCharAt(stringValue, indexOfDecimalSeparator, '🙃')
    const parsed = parseFloat(parseFloat(stringValue.replaceAll(',', '').replaceAll('.', '').replaceAll(' ', '').replace('🙃', '.')).toFixed(4).replace(/0{0,2}$/, ""))
    return parsed
  } catch (e) {
    console.error(`Failed parsing: ${stringValue}; type: ${typeof stringValue} ${e.message}`)
    return -1
  }
}

export const parseExcelToPricebreakdowns = (excel: ExcelJS.Workbook, gridMode: GRID_MODE): PriceBreakdownRow[] => {
  const priceRows: PriceRowWithOutline[] = []
  let idx = 0
  const findParent = (outlineLevel?: number) => {
    for (let i = priceRows.length - 1; i >= 0; i--) {
      if (outlineLevel && priceRows[i]._outlineLevel === outlineLevel - 1) return priceRows[i]
    }
  }

  excel.worksheets.forEach((worksheet) => {
    worksheet.eachRow((row, i) => {
      // Ignore headers. Index starts with 1 as its sparse array
      if (i !== 1) {

        // Its an array. For formula purposes store only result.
        Object.entries(row.values)
          .forEach(([index, value]) => {
            // @ts-ignore
            if (value?.formula) row.getCell(parseInt(index)).value = value?.result
          })


        //@ts-ignore
        let [_, _idxString, name, unit, quantity, price, parentId, rowId] = row.values

        try {
          if (typeof name !== 'string' && !!name) {
            if (Object.keys(name).length) {
              name = Object.values(name).map((val) => {
                if (typeof val === 'string') return val

                else if (Array.isArray(val)) return val.map(({ text }) => text).join(' ')
              }).join(' ')
            }
            // TODO - else array??
          }
        }
        catch (e) {
          console.error('Error!', e, row.values)
        }

        priceRows.push({
          name,
          unit,
          quantity: parseFloat(quantity),
          rowId: (gridMode === GRID_MODE.BIDDER && rowId) ? rowId : nanoid(),
          parentId: row.outlineLevel === 0 ? undefined : (gridMode === GRID_MODE.BIDDER && parentId) ? parentId : findParent(row.outlineLevel)?.rowId,
          idx: idx++,
          price,
          _outlineLevel: row.outlineLevel,
        })
      }
    })
  })

  return priceRows
}

export const isUploadValid = (
  priceRows: PriceBreakdownRow[],
  uploadRows: PriceBreakdownRow[],
): boolean => {
  if (priceRows.length !== uploadRows.length) return false

  const propsToCheck = [/* 'name', 'quantity',*/ 'rowId', 'parentId', 'unit']

  priceRows.sort((a, b) => a.idx - b.idx)
  uploadRows.sort((a, b) => a.idx - b.idx)

  for (let i = 0; i < priceRows.length; i++) {
    for (const prop of propsToCheck) {
      if (!isNullOrUndefined(priceRows[i][prop]) && priceRows[i][prop] != uploadRows[i][prop]) {
        return false
      }
    }
  }

  return true
}

export const readFileDataAsBase64 = (e: any): Promise<Buffer> => {
  const file = e.target.files[0]

  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as Buffer)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}


export const getPriceRowChildren = (rows: Partial<PriceBreakdownRow[]>, rowIds: Id[], children: PriceBreakdownRow[] = []): PriceBreakdownRow[] => {
  if (!rowIds) return children

  if (!Array.isArray(rowIds)) rowIds = [rowIds]

  if (!rowIds.length) return children


  // @ts-ignore
  let childRows: PriceBreakdownRow[] = rows.map((row) => {
    if (row?.parentId && rowIds.includes(row.parentId)) {
      return row
    }

    return null
  }).filter((row) => !!row)


  // @ts-ignore
  return getPriceRowChildren(rows, childRows.map((row) => row.rowId ?? row.id), children.concat(childRows))
}