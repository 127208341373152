import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Alert } from 'reactstrap'
import TenderActionButtons from './TenderActionButtons'
import { Query, Mutation } from '../../../api'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import RoundSelect from './RoundSelect'
import AwardModal from './awardModal'

const StatusAlert = styled(Alert)`
  padding: 0.5rem 1rem;
  color: #f44455;
  border: 1px solid #f44455;
  background: #ffffff;
  margin-bottom: 0;
  display: flex;
  align-items: baseline;
`

export const statuses = {
  PREPARATION: { title: 'Tender_statuses_in_preparation', color: 'primary' },
  ANOTHER_ROUND: { title: 'Tender_statuses_new_round', color: 'secondary' },
  HOD_APPROVAL: { title: 'Tender_statuses_waiting_for_hod_approval', color: 'warning' },
  HOD_REVISE: { title: 'Tender_statuses_hod_sent_for_revise', color: 'danger' },
  CLIENT_APPROVAL: { title: 'Tender_statuses_waiting_for_client_approval', color: 'primary' },
  CLIENT_REVISE: { title: 'Tender_statuses_client_sent_for_revise', color: 'danger' },
  APPROVED: { title: 'Tender_statuses_approved_by_hod_and_client', color: 'success' },
  RUNNING: { title: 'Tender_statuses_tender_is_running', color: 'success' },
  AWARDED: { title: 'Tender_statuses_tender_awarded', color: 'primary' },
  CLOSED: { title: 'Tender_statuses_closed', color: 'primary' },
}

export default ({
  onRoundChange,
  noItemsInBreakdown,
  noBidders,
  tenderFormTouched,
  commencementDateInvalid,
  submissionDateInvalid,
  initialRound,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data, loading } = useQuery(Query.TENDER, { variables: { id } })
  const [round, setRound] = useState(initialRound)
  const [awardModalOpen, setAwardModalOpen] = useState(false)

  const [tenderAction, { loading: tenderActionLoading }] = useMutation(Mutation.TENDER_ACTION, {
    variables: { id },
    onCompleted: () => {
      window.scrollTo(0, 0)
    },
    refetchQueries: [{ query: Query.TENDER, variables: { id } }],
  })

  useEffect(() => {
    initialRound && setRound(initialRound)
  }, [initialRound])

  const handleRoundChange = ({ target: { value } }) => {
    setRound(parseInt(value, 10))
    onRoundChange(parseInt(value, 10))
  }


  if (loading || !data || !data.tender) return null

  const { tender } = data || { tender: {} }
  const status = statuses[tender.status || 'PREPARATION']
  const readOnly = tender.status && ['RUNNING', 'AWARDED'].indexOf(tender.status) > -1

  if (tenderFormTouched) {
    return (
      <>
        {tender.status !== 'AWARDED' && (
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <StatusAlert>
              <Icon icon={faExclamation} className={'mr-2'} />
              {t('Price_breakdown_form_touched_alert')}
            </StatusAlert>
            <RoundSelect onChange={handleRoundChange} round={round} tender={tender} />
          </div>
        )}
      </>
    )
  }

  if (noBidders) {
    return (
      <>
        {tender.status !== 'AWARDED' && (
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <StatusAlert>
              <Icon icon={faExclamation} className={'mr-2'} />
              {t('Price_breakdown_no_bidders_alert')}
            </StatusAlert>
            <RoundSelect onChange={handleRoundChange} round={round} tender={tender} />
          </div>
        )}
      </>
    )
  }

  if (noItemsInBreakdown) {
    return (
      <>
        {tender.status !== 'AWARDED' && (
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <StatusAlert>
              <Icon icon={faExclamation} className={'mr-2'} />
              {t('Price_breakdown_no_items_alert')}
            </StatusAlert>
            <RoundSelect onChange={handleRoundChange} round={round} tender={tender} />
          </div>
        )}
      </>
    )
  }


  return (
    <>
      {tender.status !== 'AWARDED' && (
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          {(commencementDateInvalid || submissionDateInvalid) && (
            <StatusAlert>
              <Icon icon={faExclamation} className={'mr-2'} />
              {t('Price_breakdown_dates_not_valid_alert')}
            </StatusAlert>
          )}
          <TenderActionButtons
            bidders={tender.bidders}
            disabled={noItemsInBreakdown || noBidders || tenderFormTouched}
            datesInvalid={commencementDateInvalid || submissionDateInvalid}
            loading={tenderActionLoading}
            actions={tender.statusActions}
            inline={true}
            onAction={(t) => {
              if (t === 'award') {
                setAwardModalOpen(true)
              } else {
                tenderAction({ variables: { action: t } })
              }
            }}
          />
          <RoundSelect onChange={handleRoundChange} round={round} tender={tender} />
        </div>
      )}

      <AwardModal
        open={awardModalOpen}
        onChange={(result) => {
          console.log(result)
          tenderAction({ variables: { action: 'award', payload: { bidderId: result } } })

          setAwardModalOpen(false)
        }}
        onClose={() => {
          setAwardModalOpen(false)
        }}
        bids={tender.bids.filter((b) => b.round === round && b.priceBreakdown)}
      />
    </>
  )
}
