class Place {
  constructor(components) {
    Object.assign(this, { components })
  }

  get(type, short = false) {
    for (let component of this.components) {
      if (component.types.includes(type)) {
        return short ? component.short_name : component.long_name
      }
    }
    return null
  }
}

export const PlaceParser = ({ address_components = [] } = {}) => {
  const place = new Place(address_components)
  let street_number, street_name, city, zip

  if (place.get('street_number')) {
    street_number = place.get('street_number')
  } else if (place.get('premise')) {
    street_number = place.get('premise')
  } else {
    street_number = ''
  }

  if (place.get('route') && place.get('route') !== 'Unnamed Road') {
    street_name = place.get('route')
  } else if (place.get('neighborhood')) {
    street_name = place.get('neighborhood')
  } else {
    street_name = place.get('locality')
  }

  if (place.get('locality')) {
    city = place.get('locality')
  } else if (place.get('neighborhood')) {
    city = place.get('neighborhood')
  } else {
    city = place.get('locality')
  }
  if (place.get('postal_code')) {
    zip = place.get('postal_code')
  } else {
    zip = ''
  }

  const result = {
    street_number: street_number,
    street_name: street_name,
    city: city,
    county: place.get('administrative_area_level_2'),
    state_short: place.get('administrative_area_level_1', true),
    state_long: place.get('administrative_area_level_1'),
    country_short: place.get('country', true),
    country_long: place.get('country'),
    zip_code: zip,
  }

  result.address = result.street_number + ' ' + result.street_name

  return result
}
