import React from 'react'

import { FileManager } from '../../../components/file-manager'

import { Mutation, Query } from '../../../api'
import { useMutation } from '@apollo/react-hooks'

export default ({ bid, readOnly }) => {
  const [setBidFiles, setBidFilesData] = useMutation(Mutation.SET_BID_FILES, {
    variables: { id: bid.id },
    refetchQueries: [{ query: Query.BIDDER_TENDER, variables: { id: bid.tender.id } }],
  })

  return (
    <>
      <FileManager
        readOnly={readOnly}
        files={bid.files}
        onSelected={(s) => {
          setBidFiles({
            variables: {
              files: [...bid.files.map((f) => f.id), ...s],
            },
          })
        }}
        onRemoved={(s) => {
          setBidFiles({
            variables: {
              files: [...bid.files.map((f) => f.id)].filter((f) => s.indexOf(f) === -1),
            },
          })
        }}
        // directory={`bidder/${bid.bidder.id}/tender/${bid.tender.id}`}
        directory={`bidder/${bid.bidder.id}/tender/${bid.tender.id}/round/${bid.tender.round}`}
      />
    </>
  )
}
