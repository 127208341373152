import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Button, Card, CardBody, Alert, Spinner } from 'reactstrap'

import * as yup from 'yup'
import { Formik, Form } from 'formik'
import Input from '../../components/form/FormikInput'

import { useMutation } from '@apollo/react-hooks'
import { Mutation } from '../../api/gql'

import { useTranslation, Trans } from 'react-i18next'
import i18next from 'i18next'
import logo from '../../assets/img/logo_signin.png'
import styled from 'styled-components'
import LanguageSelect from '../../components/LanguageSelect'

const Logo = styled.img.attrs((props) => ({
  src: logo,
}))`
  height: 200px;
`

const SignUp = ({ history }) => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('Sign_up_form_validation_email_format'))
      .required(t('Sign_up_form_validation_email_required')),
    name: yup
      .string()
      .min(3, t('Sign_up_form_validation_name_min'))
      .required(t('Sign_up_form_validation_name_required')),
    organizationName: yup
      .string()
      .min(3, t('Sign_up_form_validation_organization_name_min'))
      .required(t('Sign_up_form_validation_organization_name_required')),
    agreement: yup
      .bool()
      .oneOf([true], t('Sign_up_form_validation_agreement_required', 'required')),
  })

  const [signUp] = useMutation(Mutation.SIGN_UP)
  const [formError, setFormError] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)

  return (
    <React.Fragment>
      <div className="text-center">
        <Logo />
        <h1 className="h2">{t('Sign_up_registration_heading')}</h1>
        <p className="lead">{t('Sign_up_registration_subheading')}</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Formik
              initialValues={{
                email: '',
                name: '',
                organizationName: '',
                agreement: false,
              }}
              validationSchema={validationSchema}
              formSubmitted={formSubmitted}
              setFormSubmitted={setFormSubmitted}
              onSubmit={async (values, actions, error) => {
                setFormError(null)
                try {
                  await signUp({ variables: { language: i18next.language, ...values } })
                  setFormSubmitted(true)
                } catch (e) {
                  setFormError(e.message)
                }
              }}
            >
              {({ isSubmitting, values }) => (
                <>
                  {!formSubmitted ? (
                    <Form>
                      <Input
                        label={t('Sign_up_form_name_label')}
                        bsSize="lg"
                        type="text"
                        name="name"
                        placeholder={t('Sign_up_form_name_placeholder')}
                      />
                      <Input
                        label={t('Sign_up_form_email_label')}
                        bsSize="lg"
                        type="email"
                        name="email"
                        placeholder={t('Sign_up_form_email_placeholder')}
                      />
                      <Input
                        label={t('Sign_up_form_organization_name_label')}
                        bsSize="lg"
                        type="text"
                        name="organizationName"
                        placeholder={t('Sign_up_form_organization_name_placeholder')}
                      />
                      <Input
                        label={
                          <Trans i18nKey="Sign_up_form_agreement">
                            I have read and agree with
                            <a
                              href="https://www.urkla.com/en/terms-of-use/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms of Use
                            </a>{' '}
                            and
                            <a
                              href="https://www.urkla.com/en/privacy-policy/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>
                            .
                          </Trans>
                        }
                        type="checkbox"
                        name="agreement"
                      />
                      {formError && (
                        <div>
                          <Alert color="danger">
                            <div className="alert-message">{formError}</div>
                          </Alert>
                        </div>
                      )}
                      <div className="text-center mt-3">
                        <Button disabled={isSubmitting} color="primary" size="lg" type="submit">
                          {isSubmitting && (
                            <Spinner
                              size="sm"
                              label={t('Sign_up_form_sign_up_btn_spinner_label')}
                              style={{ verticalAlign: 'middle' }}
                            />
                          )}{' '}
                          {t('Sign_up_form_sign_up_btn_label')}
                        </Button>

                        <div className="mt-3">
                          <Link to="/auth/signin">
                            <Button color="link">{t('Sign_up_form_sign_in_link')}</Button>
                          </Link>
                        </div>
                      </div>
                    </Form>
                  ) : (
                    <Trans i18nKey="Sign_up_form_email_sent_to" email={values.email}>
                      An email was sent to <strong>{{ email: values.email }}</strong>.
                    </Trans>
                  )}
                </>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
      <div className={'text-center'}>
        <LanguageSelect />
      </div>
    </React.Fragment>
  )
}

export default SignUp
