import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const Page404 = () => {
  const { t } = useTranslation()
  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">404</h1>
      <p className="h1">{t('Page404_heading')}</p>
      <p className="h2 font-weight-normal mt-3 mb-4">{t('Page404_subheading')}</p>
      <Link to="/">
        <Button color="primary" size="lg">
          {t('Page404_return_to_website_btn')}
        </Button>
      </Link>
    </div>
  )
}
export default Page404
