import { CellChange, ChevronCell, Column, Id, NumberCell, ReactGrid } from '@silevis/reactgrid';
import { FC, useEffect, useState } from 'react';
import { IndicatorCellTemplate } from './cellTemplates/IndicatorCellTemplate';
import getSortedUrklaRows from './getSortedAnalyticRows';
import { AnalyticRowPublic, AnalyticRowInternal } from './types/AnalyticRow';
import { NonEditableChevronCell, NonEditableChevronCellTemplate } from './cellTemplates/NonEditableChevronCellTemplate';
import { Collaborator } from './types/AnalyticClientOffer';
import { getPriceRowChildren } from '../utils'
import styled from 'styled-components';
import { numberFormat } from '../../utils';
import { useTranslation } from 'react-i18next';

const ScrollableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`

interface AnalyticRowPublicWithIdxString extends AnalyticRowPublic {
  idxString: string
}

interface AnalyticGridProps {
  collaborators: Collaborator[];
  initialRows: AnalyticRowPublicWithIdxString[]
  width: number
}

const AnalyticGrid: FC<AnalyticGridProps> = ({ collaborators, initialRows, width }) => {
  const { t } = useTranslation()

  const [analyticRows, setAnalyticRows] = useState<AnalyticRowPublicWithIdxString[]>(() =>
    initialRows.map((row) => ({ ...row, idxString: row.idxString, expanded: false, areChildrenLoaded: false }))
  );



  const getColumns = (generatedColumns: Column[], containerWidth: number): Column[] => {

    const generatedMaxWidth = 400
    let generatedColumnsWidth = generatedColumns.reduce((a, b) => a + (b?.width ?? 0), 0)
    let columnWidths = {
      tree: 0,
      unit: 80,
      quantity: 80,
      generated: generatedColumnsWidth > generatedMaxWidth ? generatedMaxWidth : generatedColumnsWidth,
      average: 150
    }

    columnWidths.tree = containerWidth - Object.values(columnWidths).reduce((a, b) => a + b, 0)

    return [
      { columnId: 'tree', width: columnWidths.tree < 400 ? 400 : columnWidths.tree, resizable: true },
      { columnId: 'unit', width: columnWidths.unit, resizable: true },
      { columnId: 'quantity', width: columnWidths.quantity, resizable: true },
      ...generatedColumns,
      { columnId: 'average', width: columnWidths.average, resizable: true },
    ]
  }

  const sortedRows = getSortedUrklaRows(analyticRows as any, false);

  const filteredRows = sortedRows.filter((row) => !row.hidden);

  const maxIndent = sortedRows.reduce((a, b) => Math.max(a, b.indent), 0);

  const dataRows = filteredRows.map((row) => {
    const collaboratorsWithOffer = collaborators.filter((c) => !!c.offers[row.id])

    const hexVal = Math.floor(25 / maxIndent) * row.indent + 230;
    let averageValue = collaborators.reduce((a, b) => a + (b.offers[row.id] ?? 0), 0) / collaboratorsWithOffer.length
    const childRows = getPriceRowChildren(analyticRows as any, [row.id])


    if (!averageValue) {
      averageValue =
        (childRows.reduce((acc, curr) => acc + collaborators.reduce((a, b) => a + ((curr.id ? b.offers[curr?.id] : 0) ?? 0), 0), 0) ?? 0) / collaborators.length

    }


    const background = `rgb(${hexVal},${hexVal},${hexVal})`;
    return {
      rowId: row.id,
      height: 50,
      cells: [
        {
          type: 'nonEditableChevron',
          text: row.name,
          parentId: row.parentId,
          isExpanded: row.expanded,
          hasChildren: analyticRows.some((childrow) => childrow.parentId === row.id),
          indent: row.indent,
          style: { background },
          renderer: (text: string) => `${row.idxString} - ${text}`,
        } as NonEditableChevronCell,
        { type: 'text', text: row?.unit ?? '', nonEditable: true, style: { background } },
        { type: 'number', value: row.quantity ?? 0, nonEditable: true, style: { background }, format: numberFormat } as NumberCell,
        ...collaborators.map((offer) => {
          const childrenSum = childRows.reduce((accumulator, currentValue) => {
            // @ts-ignore
            return accumulator + (offer.offers[currentValue.id] ?? 0)
          }, 0)

          let value = offer.offers[row.id]

          if (!value && childrenSum) value = childrenSum


          return {
            type: 'indicator',
            value,
            nonEditable: true,
            style: { background },
            averageValue,
            format: numberFormat
          }
        }),
        {
          type: 'number',
          value: averageValue,
          nonEditable: true,
          style: { background },
          format: numberFormat
        },
      ],
    };
  });

  const rows = [
    {
      rowId: 'header',
      height: 50,
      cells: [
        {
          type: 'header',
          text: t('ANALYTIC_COLUMNS_NAME'),
          style: { background: 'white' },
        },
        {
          type: 'header',
          text: t('ANALYTIC_COLUMNS_UNIT'),
          style: { background: 'white' },
        },
        {
          type: 'header',
          text: t('ANALYTIC_COLUMNS_QUANTITY'),
          style: { background: 'white' },
        },
        ...collaborators.map((collaborator) => ({
          type: 'header',
          text: `${collaborator.name}`,
          style: { background: 'white' },
        })),
        {
          type: 'header',
          text: t('ANALYTIC_COLUMNS_AVG_PRICE'),
          style: { background: 'white' },
        },
      ],
    },
    {
      rowId: 'total',
      height: 50,
      cells: [
        {
          type: 'header',
          text: 'Total',
          style: { background: 'rgb(221,221,221)' },
        },
        {
          type: 'header',
          text: '',
          style: { background: 'rgb(221,221,221)' },
        },
        {
          type: 'header',
          text: '',
          style: { background: 'rgb(221,221,221)' },
        },
        ...collaborators.map(
          (collaborator) =>
          ({
            type: 'number',
            nonEditable: true,
            style: { background: 'rgb(221,221,221)' },
            value: Object.values(collaborator.offers).reduce((a, b) => a + b, 0),
            format: numberFormat
          } as NumberCell)
        ),
        {
          type: 'number',
          nonEditable: true,
          style: { background: 'rgb(221,221,221)' },
          value:
            collaborators.reduce((a, b) => a + Object.values(b.offers).reduce((a, b) => a + b, 0), 0) /
            collaborators.length,
          format: numberFormat
        } as NumberCell,
      ],
    },
    ...dataRows,
  ];

  const cellChangesHandler = (changes: CellChange[]) => {
    changes.forEach(async (change) => {
      if (change.columnId === 'tree') {
        const newCell = change.newCell as ChevronCell;
        const row = analyticRows.find((row) => row.id === change.rowId);
        const childrenRows: AnalyticRowInternal[] = [];

        // lazy loading
        // @ts-ignore
        if (row?.childrenUrl && !row.areChildrenLoaded) {
          const rowsFromResponse: AnalyticRowPublic[] = await fetch(row.childrenUrl).then((r) => r.json());
          childrenRows.push(...rowsFromResponse.map((row) => ({ ...row, expanded: false, areChildrenLoaded: false })));
        }

        // @ts-ignore
        setAnalyticRows((rows) => [
          ...rows.map((row) =>
            row.id === change.rowId ? { ...row, expanded: !!newCell.isExpanded, name: newCell.text } : row
          ),
          ...childrenRows,
        ])
      }
    })
  }

  const calcColumnWidth = (collaborator: Collaborator): number => {
    const ruler = document.getElementById('ruler')

    if (ruler) {
      ruler.innerHTML = collaborator.name
      let bidderNameWidth = ruler.offsetWidth
      let priceWidth = 0

      Object.values(collaborator.offers).forEach((offer) => {
        // @ts-ignore
        ruler.innerHTML = `${offer.toFixed(3).replace('.000', '')}`

        // @ts-ignore
        if (ruler.offsetWidth > priceWidth) priceWidth = ruler.offsetWidth
      })

      // when priceWidth > bidderNameWidth add extra space for green/red circles, else just 20 for some extra space
      return priceWidth > bidderNameWidth ? priceWidth + 40 : bidderNameWidth + 20
    }

    return 200
  }

  const initialColumns = getColumns(
    collaborators.map((collaborator) => ({
      columnId: collaborator.id,
      width: calcColumnWidth(collaborator),
      resizable: true
    })),
    width
  )

  const [columns, setColumns] = useState(initialColumns)

  const onColumnResized = (columnId: Id, width: number, _selectedColIds: Id[]) => {
    setColumns(columns.map((c) => c.columnId === columnId ? ({ ...c, width }) : c))
  }

  useEffect(() => {
    setColumns(initialColumns)
  }, [collaborators])

  useEffect(() => {
    setAnalyticRows(initialRows as any)
  }, [initialRows])


  return (
    <ScrollableContainer>
      {/* @ts-ignore */}
      <ReactGrid
        onColumnResized={onColumnResized}
        enableColumnSelection
        enableRangeSelection
        enableRowSelection
        enableFillHandle
        onCellsChanged={cellChangesHandler}
        customCellTemplates={{
          indicator: new IndicatorCellTemplate(),
          nonEditableChevron: new NonEditableChevronCellTemplate(),
        }}
        stickyTopRows={1}
        stickyRightColumns={1}
        stickyLeftColumns={3}
        horizontalStickyBreakpoint={70}
        columns={columns}
        rows={rows}
      />
    </ScrollableContainer>
  );
};

export default AnalyticGrid;
