import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Grid from '../../../components/grid'
import { useQuery } from '@apollo/react-hooks'
import { Query } from '../../../api'
import Loading from '../../../components/Loading'
import { useTranslation, Trans } from 'react-i18next'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import i18next from 'i18next'
import { Badge } from 'reactstrap'
import { stringToColor } from '../../../utils/functions'

const BidderSelectInput = ({ selection, onChange }) => {
  const activeLanguage = i18next.language.toUpperCase()

  const { t } = useTranslation()
  const { data } = useQuery(Query.BIDDERS)
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const rows = (data ? data.bidders : []).map((b) => ({
    ...b,
    tags: b.tags?.[activeLanguage],
  }))

  const [filteredRows, setFilteredRows] = useState(null)

  useEffect(() => {
    setSelected(selection)
  }, [selection, open])

  const handleClose = (s?: any) => {
    setOpen(false)
    onChange(s ?? selected)
  }
  const customFilter = (val: string) => {
    const containsFieldsFilter = ['contactName', 'email', 'name', 'phone']


    const newRows = rows.filter((row) => {
      for (let i = 0; i < containsFieldsFilter.length; i++) {
        if (row[containsFieldsFilter[i]].toLowerCase().includes(val.toLowerCase())) return true
      }

      if (row.tags?.some((tag) => tag.toLowerCase().includes(val.toLowerCase()))) return true


      return false

    })
    setFilteredRows(newRows)
  }

  return (
    <>
      <Modal isOpen={open} toggle={() => handleClose()} size='xl' >
        <ModalHeader toggle={() => handleClose()}>
          {t('Procurement_tenders_tender_detail_bidders_selection_modal_heading')}
        </ModalHeader>
        <ModalBody>
          <Grid
            customFilter={customFilter}
            actionBar
            selection={selected}
            selectByRowClick
            onSelectionChange={setSelected}
            rows={(filteredRows ?? rows).map((row) => ({
              ...row,
              tags: row?.tags?.map((tag: string) => <><Badge style={{
                backgroundColor: stringToColor(tag)
              }}>{tag}</Badge>&nbsp;</>)
            }))}

            columns={[
              {
                name: 'name',
                title: t('Procurement_tenders_tender_detail_bidders_list_name_label'),
              },
              {
                name: 'contactName',
                title: t('Procurement_tenders_tender_detail_bidders_list_contact_name_label'),
              },
              {
                name: 'email',
                title: t('Procurement_tenders_tender_detail_bidders_list_email_label'),
              },
              {
                name: 'phone',
                title: t('Procurement_bidders_phone_label'),
              },
              {
                name: 'tags',
                title: t('TAGS'),
              }
            ]}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" size="lg" onClick={() => handleClose()}>
            {t('Procurement_tenders_tender_detail_modal_cancel_btn')}
          </Button>
          <Button color="success" size="lg" onClick={() => handleClose(selected)}>
            <Trans
              i18nKey="Procurement_tenders_tender_detail_modal_select_x_bidders_btn"
              count={selected.length}
            >
              Select {{ count: selected.length }} bidders
            </Trans>
          </Button>
        </ModalFooter>
      </Modal>
      <Button size="lg" className="btn-pill" color="success" onClick={() => setOpen(true)}>
        {t('Procurement_tenders_tender_detail_bidders_list_manage_bidders_btn')}
      </Button>
    </>
  )
}

export default ({
  onSelectionChange,
  onResendNotification,
  selection,
  readOnly,
  tenderStatus,
  winnerId,
}) => {
  const activeLanguage = i18next.language.toUpperCase()

  const { t } = useTranslation()
  const { data, loading } = useQuery(Query.BIDDERS)

  const rows = data ? data.bidders.filter((b) => selection.indexOf(b.id) > -1) : []
  const [filteredRows, setFilteredRows] = useState(null)


  const customFilter = (val: string) => {
    const containsFieldsFilter = ['contactName', 'email', 'name']


    const newRows = rows.filter((row) => {
      for (let i = 0; i < containsFieldsFilter.length; i++) {
        if (row[containsFieldsFilter[i]].toLowerCase().includes(val.toLowerCase())) return true
      }


      if (row.tags?.[activeLanguage]?.some((tag) => tag.toLowerCase().includes(val.toLowerCase()))) return true


      return false

    })
    setFilteredRows(newRows)
  }

  return (
    <>
      <Grid
        customFilter={customFilter}
        actionBar={{
          actionButton: readOnly ? null : (
            <BidderSelectInput selection={selection} onChange={onSelectionChange} />
          ),
        }}
        rows={filteredRows ?? rows}
        columns={[
          { name: 'name', title: t('Procurement_tenders_tender_detail_bidders_list_name_label') },
          {
            name: 'contactName',
            title: t('Procurement_tenders_tender_detail_bidders_list_contact_name_label'),
          },
          { name: 'email', title: t('Procurement_tenders_tender_detail_bidders_list_email_label') },
          { name: 'winner', width: 30 },
        ]}
        formatters={[
          {
            formatter: ({ value, row }) => {
              if (row.id === winnerId) return <Icon icon={faTrophy as any} className={'text-warning'} />
              return null
            },
            for: ['winner'],
          },
        ]}
        noDataMessage={t(
          'Procurement_tenders_tender_detail_documents_no_bidders_selected_placeholder',
        )}
        rowActions={
          !readOnly
            ? [
              {

                title: t('Procurement_tenders_tender_detail_documents_remove_btn'),
                color: 'danger',
                action: ({ id }) => {
                  onSelectionChange(selection.filter((b) => b !== id))
                },
              },
              tenderStatus === 'RUNNING' && {
                title: t('Procurement_tenders_tender_detail_documents_resend_btn'),
                action: ({ id }) => onResendNotification(id),
              },
            ] : null as any
        }
      />
      {loading && <Loading />}
    </>
  )
}
