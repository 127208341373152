import React, { useState, useEffect } from 'react'
import { useRouter } from '../hooks'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Query, Mutation } from '../api'
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import styled from 'styled-components'

import Grid from './grid'
import { useTranslation } from 'react-i18next'

const DropdownToggleButon = styled(DropdownToggle)`
  height: 65px;
  margin: -14px 0;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-width: 0 1px 0 1px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
`

const ProjectSelector = () => {
  const { t } = useTranslation()
  const { history } = useRouter()

  const [open, setOpen] = useState(false)
  const { data, loading } = useQuery(Query.ME)
  const [updateUser, updateUserData] = useMutation(Mutation.UPDATE_USER, {
    refetchQueries: [{ query: Query.DASHBOARD }, { query: Query.ME }],
  })

  const handleClick = async (id) => {
    setOpen(false)
    await updateUser({ variables: { projectId: id } })
    history.replace('/dashboard')
  }

  if (loading) return null

  const currentProject = data.me.currentProject

  return (
    <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
      <DropdownToggleButon
        caret
        color="link"
        style={{ fontSize: '120%' }}
        className="btn-lg text-dark font-weight-bold bg-transparent text-decoration-none dropdown-toggle btn btn-link py-0"
      >
        {currentProject ? currentProject.name : t('Header_Project_Name_label')}{' '}
      </DropdownToggleButon>
      <DropdownMenu className="p-2">
        <Grid
          actionBar
          columns={[{ title: t('Header_Project_Name_placeholder'), name: 'name' }]}
          rows={data.projects}
          search
          getRowId={(r) => r.id}
          selectByRowClick
          showSelectionColumn={false}
          onSelectionChange={([id]) => {
            handleClick(id)
          }}
        />
        {/* {data.projects.map((p) => (
          <DropdownItem key={p.id} onClick={() => handleClick(p.id)}>
            {p.name}
          </DropdownItem>
        ))} */}
      </DropdownMenu>
    </Dropdown>
  )
}

export default ProjectSelector
