import React from 'react'
import FileIcon, { defaultStyles } from 'react-file-icon'

export default ({ filename }) => {
  const ext = /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : null

  return (
    <span style={{ margin: '-10px 0' }}>
      <FileIcon extension={ext} {...defaultStyles[ext]} size={25} />
    </span>
  )
}
