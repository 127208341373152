import React from 'react'
import { Button } from 'reactstrap'
import { PriceBreakdownRow } from '../PriceBreakdownGrid/types/PriceBreakdownRow'
import saveAs from 'file-saver'
import ExcelJS, { Worksheet, Column } from 'exceljs'
import { parseBreakdownToTreeModel, toNumericValue } from '../utils'
import { useTranslation } from 'react-i18next'
import getSortedPriceRows from '../PriceBreakdownGrid/functions/getSortedPriceRows'

interface IProps {
  priceRows: PriceBreakdownRow[]
}

const worksheetColumns: Partial<Column>[] = [
  { header: '#', key: 'idxString', width: 10 },
  { header: 'Name', key: 'name', width: 60 },
  { header: 'Unit', key: 'unit', width: 10 },
  { header: 'Quantity', key: 'quantity', width: 10 },
  { header: 'Price', key: 'price', width: 10 },
  { header: 'parentId', key: 'parentId', width: 1, hidden: true },
  { header: 'rowId', key: 'rowId', width: 1, hidden: true },
]

export const DownloadXLSX = ({ priceRows }: IProps) => {
  const { t } = useTranslation()
  const sortedRows = getSortedPriceRows(priceRows)

  const maxIndent = sortedRows.reduce((a, b) => Math.max(a, b.indent), 0)

  const generateAndDownloadXLSX = async () => {
    const workbook = new ExcelJS.Workbook()
    const priceTree = parseBreakdownToTreeModel(sortedRows)

    let currentWorksheet: Worksheet

    workbook.creator = 'Urkla'

    priceTree.walk((node) => {
      if (node.isRoot()) return true

      if (!node.model.parentId) {
        currentWorksheet = workbook.addWorksheet(node.model.name?.replace(/[?,*,:,/,\[,\]]/g, ''))
        currentWorksheet.columns = worksheetColumns

        currentWorksheet.views = [{ state: 'frozen', ySplit: 1 }]
      }
      const { name, unit, quantity, price, rowId, parentId, id, idxString } = node.model

      if (currentWorksheet) {
        const unvalidatedHex = Math.floor(50 / maxIndent) * node.model.indent + 205

        const hexVal = isNaN(unvalidatedHex) ? 255 : unvalidatedHex

        const background = `80${hexVal.toString(16)}${hexVal.toString(16)}${hexVal.toString(16)}`
        const currentRow = currentWorksheet.addRow({
          name,
          unit,
          quantity: toNumericValue(quantity),
          price: toNumericValue(price),
          parentId,
          rowId,
          idxString,
        })

        currentRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: background },
          bgColor: { argb: 'ff000000' },
        }

        const cellBorder: ExcelJS.Border = {
          color: { argb: 'ffdadbda' },
          style: 'thin',
        }

        currentRow.outlineLevel = node.model.indent
        currentRow.border = {
          top: cellBorder,
          right: cellBorder,
          bottom: cellBorder,
          left: cellBorder,
        }
      }

      return true
    })

    const blob = new Blob([await workbook.xlsx.writeBuffer()], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    saveAs(blob, 'Analysis.xlsx')
  }

  return (
    <Button className="btn-pill mr-1" size="md" onClick={generateAndDownloadXLSX} color="primary">
      {t('Button_XSLX_download')}
    </Button>
  )
}

export default DownloadXLSX
