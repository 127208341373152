import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Query, Mutation, Enums } from '../../../api'
import { useRouter, useUser } from '../../../hooks'
import Grid from '../../../components/grid'
import Loading from '../../../components/Loading'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const UserSelectModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const {
    query: { projectId },
  } = useRouter()
  const usersQuery = useQuery(Query.USERS)
  const projectQuery = useQuery(Query.PROJECT, { variables: { id: projectId } })
  const [addProjectUsers, { loading: addProjectUserLoading }] = useMutation(
    Mutation.ADD_PROJECT_USERS,
    {
      variables: { id: projectId },
      refetchQueries: [
        { query: Query.PROJECT, variables: { id: projectId } },
        { query: Query.PROJECTS },
      ],
    },
  )
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setSelected([])
  }, [isOpen, usersQuery.data])

  const getUsers = () => {
    return usersQuery.loading || projectQuery.loading
      ? []
      : usersQuery.data.users.filter(
        (u) => projectQuery.data.project.users.map((i) => i.user.id).indexOf(u.id) === -1,
      )
  }

  const handleClose = () => {
    onClose()
  }

  const handleSave = async () => {
    await addProjectUsers({
      variables: {
        users: selected.map((id) => ({
          id,
          role: 'COLLABORATOR',
        })),
      },
    })
    handleClose()
  }

  const getContent = () => {
    const users = getUsers()

    return (
      <>
        <Grid
          onSelectionChange={setSelected}
          selection={selected}
          selectByRowClick
          actionBar
          columns={[
            { title: t('Project_users_add_user_modal_name_label'), name: 'name' },
            { title: t('Project_users_add_user_modal_email_label'), name: 'email' },
          ]}
          rows={users}
        />
        {(usersQuery.loading || projectQuery.loading || addProjectUserLoading) && <Loading />}
      </>
    )
  }

  return (
    <Modal isOpen={isOpen} size="md" toggle={() => handleClose()}>
      <ModalHeader toggle={() => handleClose()}>
        {t('Project_users_add_user_modal_user_selection_heading')}
      </ModalHeader>
      <ModalBody className="p-3">{getContent()}</ModalBody>
      <ModalFooter>
        <Button color="success" disabled={selected.length === 0} onClick={handleSave}>
          {t('Project_users_add_user_modal_add_to_project_btn')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const Users = () => {
  const { t } = useTranslation()
  const {
    query: { projectId },
  } = useRouter()

  const [usersSelectOpen, setUsersSelectOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const { data, loading } = useQuery(Query.PROJECT, { variables: { id: projectId } })
  const [removeProjectUser, { loading: removeProjectUserLoading }] = useMutation(
    Mutation.REMOVE_PROJECT_USERS,
    {
      variables: { id: projectId },
      refetchQueries: [
        { query: Query.PROJECT, variables: { id: projectId } },
        { query: Query.PROJECTS },
      ],
    },
  )
  const [updateUserProjectRole] = useMutation(Mutation.UPDATE_USER_PROJECT_ROLE)

  useEffect(() => {
    setSelected([])
  }, [data])

  const handleRemove = (ids) => {
    removeProjectUser({ variables: { users: ids } })
  }

  const handleRightsChange = ({ target: { value } }, { roleId, rights, ...user }) => {
    console.log({ roleId, user, data })
    updateUserProjectRole({
      variables: { id: roleId, role: value },
      refetchQueries: [
        { query: Query.PROJECT, variables: { id: projectId, role: 'PACKAGE_MANAGER' } },
      ],
      optimisticResponse: {
        updateUserProjectRole: {
          __typename: 'UserToProject',
          id: roleId,
          role: value,
          user,
        },
      },
    })

    return false
  }

  const rows = data
    ? data.project.users.map((r) => ({
      ...r.user,
      role: r.role,
      roleId: r.id,
    }))
    : []

  return (
    <Row>
      <Col>
        <Grid
          actionBar={{
            actionButton: (
              <Button
                color="primary"
                className="btn-pill"
                size="lg"
                onClick={() => {
                  setUsersSelectOpen(true)
                }}
              >
                <Icon icon={faUserPlus} /> {t('Project_users_add_user_to_project_btn')}
              </Button>
            ),
            actionButtonRight: (
              <Button
                color="outline-danger"
                className="btn-pill"
                size="lg"
                disabled={selected.length === 0}
                onClick={() => {
                  handleRemove(selected)
                }}
              >
                <Icon icon={faTrash} /> {t('Project_users_remove_from_project_btn')}
              </Button>
            ),
          }}
          selectByRowClick
          selection={selected}
          onSelectionChange={setSelected}
          columns={[
            { title: t('Project_users_name_label'), name: 'name' },
            { title: t('Project_users_email_label'), name: 'email' },
            { title: t('Project_users_role_label'), name: 'role' },
          ]}
          rows={rows}
          formatters={[
            {
              formatter: ({ value, row }) => (
                <select
                  // disabled={row.id === user.id}
                  onClick={(e) => e.stopPropagation()}
                  defaultValue={value}
                  className={`form-control w-auto`}
                  onChange={(e) => {
                    handleRightsChange(e, row)
                  }}
                >
                  {Enums.ROLES.map((o) => (
                    <option key={o} value={o}>
                      {t('role_' + o)}
                    </option>
                  ))}
                </select>
              ),
              for: ['role'],
            },
          ]}
          rowActions={[
            {
              title: t('Project_users_remove_from_project_btn'),
              color: 'danger',
              action: (r) => {
                handleRemove([r.id])
              },
            },
          ]}
        />
        {(loading || removeProjectUserLoading) && <Loading />}

        <UserSelectModal isOpen={usersSelectOpen} onClose={() => setUsersSelectOpen(false)} />
      </Col>
    </Row>
  )
}

export default Users
