import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { Badge, Collapse } from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import logo from '../assets/img/logo_inline.png'
import styled from 'styled-components'
import routes from '../routes/index'
import { useMatchRoute, useRouter, useUser } from '../hooks'
import MatchRoute from 'route-parser'
import { useTranslation } from 'react-i18next'
import { Query } from '../api'
import { useQuery } from '@apollo/react-hooks'

const Logo = styled.img.attrs((props) => ({
  src: logo,
}))`
  /* width: 24px; */
  height: 31px;
`

const SidebarCategory = ({
  name,
  badgeColor,
  badgeText,
  icon: Icon,
  isOpen,
  children,
  onClick,
  to,
}) => {
  const match = useRouteMatch()
  const matched = useMatchRoute(to)

  const className =
    match.url.indexOf(to) !== -1 || (match.url === '/' && to === '/dashboard') ? 'active' : ''

  return (
    <li className={'sidebar-item ' + className}>
      <span
        data-toggle="collapse"
        className={'sidebar-link ' + (!isOpen ? 'collapsed' : '')}
        onClick={onClick}
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        <Icon size={18} className="align-middle mr-3" />
        <span className="align-middle">{name}</span>
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </span>
      <Collapse isOpen={isOpen}>
        <ul id="item" className={'sidebar-dropdown list-unstyled'}>
          {children}
        </ul>
      </Collapse>
    </li>
  )
}

const SidebarItem = ({ name, badgeColor, badgeText, icon: Icon, to }) => {
  const pathMatched = useMatchRoute(to)
  const sidebarItemClass = pathMatched ? 'active' : ''
  const { data, loading } = useQuery(Query.ME)

  const mr = new MatchRoute(to).reverse({
    projectId: data && data.me.currentProject ? data.me.currentProject.id : '',
  })

  return mr ? (
    <li className={'sidebar-item ' + sidebarItemClass}>
      <NavLink to={mr} className="sidebar-link" activeClassName="active">
        {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
        {name}
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </NavLink>
    </li>
  ) : null
}

const Sidebar = () => {
  const { t } = useTranslation()
  const sidebar = useSelector((state) => state.sidebar)
  const { match } = useRouter()
  const user = useUser()

  const [openCategory, setOpenCategory] = useState(() => {
    const pathName = match.url
    let state = {}
    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0 // !== -1
      const isOpen = !!route.open
      const isHome = route.containsHome && pathName === '/' ? true : false

      state[index] = isActive || isOpen || isHome
    })

    return state
  })

  const toggle = (index) => {
    setOpenCategory((oc) => {
      let _oc = { ...oc }
      Object.keys(_oc).forEach((item) => (_oc[item] = false))

      _oc[index] = oc[index] ? false : !_oc[index]

      return _oc
    })
  }

  return (
    <nav
      className={
        'sidebar' +
        (!sidebar.isOpen ? ' toggled' : '') +
        (sidebar.isSticky ? ' sidebar-sticky' : '')
      }
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <Logo />
            {/* <Box color="#F7CE49" className="align-middle text-primary" size={24} /> {' '} */}
            {/* <span className="align-middle">Urkla</span> */}
          </a>

          <ul className="sidebar-nav">
            {routes
              .filter((r) => !r.hidden)
              .filter((r) => !r.allowedRoles || r.allowedRoles.includes(user.rights))
              .map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    {category.header ? <li className="sidebar-header">{category.header}</li> : null}

                    {category.children && category.children.filter((r) => !r.hidden).length > 0 ? (
                      <SidebarCategory
                        name={t(category.name)}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={openCategory[index]}
                        onClick={() => toggle(index)}
                      >
                        {category.children
                          .filter((r) => !r.hidden)
                          .filter((r) => !r.allowedRoles || r.allowedRoles.includes(user.rights))
                          .map((route, index) => (
                            <SidebarItem
                              key={index}
                              name={t(route.name)}
                              to={route.path}
                              badgeColor={route.badgeColor}
                              badgeText={route.badgeText}
                            />
                          ))}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        name={t(category.name)}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                      />
                    )}
                  </React.Fragment>
                )
              })}
          </ul>

          {/* {!layout.isBoxed && !sidebar.isSticky ? (
            <div className="sidebar-bottom d-none d-lg-block">
              <div className="media">
                <img
                  className="rounded-circle mr-3"
                  src={avatar}
                  alt="Chris Wood"
                  width="40"
                  height="40"
                />
                <div className="media-body">
                  <h5 className="mb-1">Chris Wood</h5>
                  <div>
                    <FontAwesomeIcon icon={faCircle} className="text-success" /> Online
                  </div>
                </div>
              </div>
            </div>
          ) : null} */}
        </PerfectScrollbar>
      </div>
    </nav>
  )
}

export default Sidebar
