import React, { useState } from 'react'
import { Button, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'

export default ({ actions, onAction, loading, disabled, datesInvalid, inline }) => {
  const { t } = useTranslation()
  const [lastAction, setLastAction] = useState('')

  const buttons = {
    submit: {
      title: t('Tender_tender_action_submit_btn', 'Submit for approval'),
      color: 'success',
    },
    revise: {
      title: t('Tender_tender_action_send_to_revise_btn', 'Send to revise'),
      color: 'warning',
    },
    approve: { title: t('Tender_tender_action_approve_btn', 'Approve'), color: 'success' },
    run: { title: t('Tender_tender_action_run_tender_btn', 'Run the tender'), color: 'success' },
    award: { title: t('Tender_tender_action_award_btn', 'Award'), color: 'success' },
    reopen: { title: t('Tender_tender_action_reopen_btn', 'Reopen tender'), color: 'info' },
    nextRound: {
      title: t('Tender_tender_action_open_next_round_btn', 'Open next round'),
      color: 'info',
    },
  }

  const handleAction = (action) => {
    onAction(action)
    setLastAction(action)
  }

  return (
    <>
      {actions.sort().map((t, i) => (
        <Button
          key={i}
          size="lg"
          color={buttons[t].color}
          disabled={
            loading || disabled || (datesInvalid && ['nextRound', 'approve'].indexOf(t) > -1)
          }
          className={inline ? 'ml-2' : 'btn-block'}
          onClick={() => handleAction(t)}
        >
          {loading && t === lastAction && (
            <>
              <Spinner size="sm" style={{ verticalAlign: 'middle' }} />{' '}
            </>
          )}
          {buttons[t].title}
        </Button>
      ))}
    </>
  )
}
