// import async from '../components/Async'
import React from 'react'
import * as Icon from 'react-feather'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'

import GeneralDashboard from '../pages/general/dashboard'
import TendersList from '../pages/tendering/tenders'
import TenderDetail from '../pages/tendering/tender'
import TenderBidders from '../pages/tendering/bidders'
import Bidders from '../pages/tendering/bidder/index.tsx'
import ProjectSetup from '../pages/project-info'
import OrganizationSettings from '../pages/organization/settings'
import Subscription from '../pages/payment/subscription'

import TestPage from '../pages/test'
import TreeTest from '../pages/tree'

// // Landing
// import Landing from '../pages/landing/Landing'

// Auth
import SignIn from '../pages/auth/SignIn.tsx'
import SignUp from '../pages/auth/SignUp'
import SignUpFinish from '../pages/auth/SignUpFinish'
import SignOut from '../pages/auth/SignOut'
import ResetPassword from '../pages/auth/ResetPassword'
import ResetPasswordFinish from '../pages/auth/ResetPasswordFinish'
// import Page404 from '../pages/auth/Page404'
// import Page500 from '../pages/auth/Page500'

const generalRoutes = {
  path: '/dashboard/',
  name: 'Route_dashboard',
  header: null,
  icon: Icon.BarChart,
  hidden: false,
  component: GeneralDashboard,
  allowedRoles: ['OWNER', 'ADMIN', 'USER'],
}

const tenderingRoutes = {
  path: '/project/',
  name: 'Route_procurement',
  header: null,
  icon: Icon.Award,
  containsHome: true,
  allowedRoles: ['OWNER', 'ADMIN', 'USER'],
  children: [
    {
      path: '/project/:projectId/tendering/tenders',
      name: 'Route_procurement_tenders',
      component: TendersList,
      allowedRoles: ['OWNER', 'ADMIN', 'USER'],
    },
    {
      path: '/project/:projectId/tendering/tenders/:id',
      name: 'Route_procurement_tender_detail',
      component: TenderDetail,
      hidden: true,
      allowedRoles: ['OWNER', 'ADMIN', 'USER'],
    },
    {
      path: '/project/:projectId/tendering/bidders',
      name: 'Route_procurement_bidders',
      component: TenderBidders,
      allowedRoles: ['OWNER', 'ADMIN', 'USER'],
    },
    // {
    //   path: '/test',
    //   name: 'Test',
    //   component: TestPage,
    //   allowedRoles: ['OWNER', 'ADMIN', 'USER'],
    // },
    // {
    //   path: '/tree',
    //   name: 'TreeTest',
    //   component: TreeTest,
    //   allowedRoles: ['OWNER', 'ADMIN', 'USER'],
    // },
  ],
}

const biddersRoutes = {
  path: '/bidder',
  name: 'Route_bidder',
  component: Bidders,
  hidden: true,
}

const projectRoutes = {
  path: '/project-setup/:projectId',
  name: 'Route_project',
  header: null,
  icon: () => <FontAwesomeIcon icon={faProjectDiagram} />,
  hidden: false,
  component: ProjectSetup,
  allowedRoles: ['OWNER', 'ADMIN', 'USER'],
}

const organizationRoutes = {
  path: '/organization',
  icon: Icon.Globe,
  exact: true,
  name: 'Route_organization',
  header: null,
  hidden: false,
  containsHome: true,
  component: OrganizationSettings,
  children: [
    {
      name: 'Route_organization_project',
      path: '/organization/projects/:projectId',
      component: ProjectSetup,
      hidden: true,
      allowedRoles: ['OWNER'],
    },
  ],
  allowedRoles: ['OWNER'],
}

const authRoutes = {
  path: '/auth',
  name: 'Route_auth',
  header: null,
  icon: Icon.Users,
  containsHome: true,
  hidden: true,
  children: [
    { path: '/auth/signin', name: 'Route_auth_sign_in', component: SignIn },
    { path: '/auth/signup', name: 'Route_auth_sign_up', component: SignUp },
    { path: '/auth/signupfinish', name: 'Route_auth_sign_up_finish', component: SignUpFinish },
    { path: '/auth/signout', name: 'Route_auth_sign_ou', component: SignOut },
    { path: '/auth/reset', name: 'Route_auth_reset', component: ResetPassword },
    {
      path: '/auth/resetpasswordfinish',
      name: 'Route_auth_reset_password_finish',
      component: ResetPasswordFinish,
    },
  ],
}

const paymentRoutes = {
  path: '/payment/subscriptions',
  name: 'Route_payment', // TODO - add translations
  header: null,
  hidden: true,
  icon: Icon.CreditCard,
  component: Subscription,
}

export const tendering = [tenderingRoutes]
export const bidders = [biddersRoutes]
export const auth = [authRoutes]
export const organization = [organizationRoutes]
export const general = [generalRoutes]
export const project = [projectRoutes]
export const payment = [paymentRoutes]

// All routes
export default [
  generalRoutes,
  tenderingRoutes,
  projectRoutes,
  organizationRoutes,
  biddersRoutes,
  authRoutes,
  paymentRoutes,
]
