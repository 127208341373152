export const getSafe = (fn: Function, defaultValue = null) => {
  try {
    let value = fn()
    if (value === null || value === undefined) return defaultValue
    else return value
  } catch (e) {
    return defaultValue
  }
}

export const stringToColor = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'

  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF
    color += ('00' + value.toString(16)).substr(-2)
  }

  return color
}