export const RIGHTS = ['OWNER', 'ADMIN', 'USER']

export const RIGHTS_COLORS = {
  OWNER: 'danger',
  ADMIN: 'warning',
  USER: 'info',
}

export const ROLES = [
  'COLLABORATOR',
  'PROJECT_ADMIN',
  'PROJECT_MANAGER',
  'PACKAGE_MANAGER',
  'INVESTOR',
  'HOD',
  'OWNER',
]

export const ROLES_COLORS = {
  COLLABORATOR: 'primary',
  PROJECT_ADMIN: 'secondary',
  PROJECT_MANAGER: 'info',
  PACKAGE_MANAGER: 'warning',
  INVESTOR: 'success',
  HOD: 'danger',
  OWNER: 'default',
}
