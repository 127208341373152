import { useState, useEffect } from 'react'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'

export default () => {
  const [token, setToken] = useState(jwt.decode(cookies.get('token')))

  useEffect(() => {
    const onStorageEvent = ({ key }) => {
      if (key === 'login') setToken(jwt.decode(cookies.get('token')))
    }
    window.addEventListener('storage', onStorageEvent)

    return () => window.removeEventListener('storage', onStorageEvent)
  }, [])

  return token
}
