import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'

const padNumber = (number) => {
  let s = String(number)
  while (s.length < 2) {
    s = '0' + s
  }
  return s
}

const Countdown = ({ date, running }) => {
  const { t } = useTranslation()
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [closed, setClosed] = useState(false)

  setInterval(() => {
    let now = new Date().getTime()
    let distance = new Date(date).getTime() - now
    if (distance <= 0) setClosed(true)
    setDays(Math.floor(distance / (1000 * 60 * 60 * 24)))
    setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
    setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
    setSeconds(Math.floor((distance % (1000 * 60)) / 1000))
  }, 1000)

  if (running && !closed)
    return (
      <h3 className={'text-warning'}>
        <Icon icon={faClock} className={'mr-2'} />
        {t('Bidders_portal_countdown_tender_closes_in', 'Closes in')}{' '}
        {days > 2 ? (
          <b>
            {days} {days === 3 && t('Bidders_portal_countdown_days3', 'days')}
            {days === 4 && t('Bidders_portal_countdown_days4', 'days')}
            {days > 4 && t('Bidders_portal_countdown_days', 'days')}
          </b>
        ) : (
          <b>
            {padNumber(hours + days * 24)}:{padNumber(minutes)}:{padNumber(seconds)}
          </b>
        )}
      </h3>
    )
  return null
}

export default Countdown
