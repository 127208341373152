import React from 'react';
import {
  CellLocation,
  CellStyle,
  CellTemplate,
  Compatible,
  getCellProperty,
  getCharFromKeyCode,
  Id,
  isAlphaNumericKey,
  isNavigationKey,
  keyCodes,
  Uncertain,
  UncertainCompatible,
  ChevronCell,
} from '@silevis/reactgrid';
import { KeyCombinationComparer } from './KeyCombinationComparer';
import { KeyCombination } from '../types/KeyCombination';

export interface ExtraChevronCell extends Omit<ChevronCell, 'type'> {
  type: 'extraChevron';
  createChild?: (location: CellLocation) => void;
  createSibling?: (parentId?: Id, rowId?: Id) => void;
  createSiblingAbove?: (parentId?: Id, rowId?: Id) => void;
  expandChildren?: (location: CellLocation, recursively?: boolean) => void
  location: CellLocation;
  parentId?: Id;
  renderer?: (text: string) => string;
}

export class ExtraChevronCellTemplate extends KeyCombinationComparer implements CellTemplate<ExtraChevronCell> {
  getCompatibleCell(uncertainCell: Uncertain<ExtraChevronCell>): Compatible<ExtraChevronCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const location = getCellProperty(uncertainCell, 'location', 'object');
    let isExpanded = false;
    try {
      isExpanded = getCellProperty(uncertainCell, 'isExpanded', 'boolean');
    } catch {
      isExpanded = true;
    }
    let indent = -1;
    try {
      indent = getCellProperty(uncertainCell, 'indent', 'number');
    } catch {
      indent = 0;
    }
    let hasChildren = false;
    try {
      hasChildren = getCellProperty(uncertainCell, 'hasChildren', 'boolean');
    } catch {
      hasChildren = false;
    }
    const value = parseFloat(text);
    return { ...uncertainCell, text, value, isExpanded, indent, hasChildren, location };
  }

  update(
    cell: Compatible<ExtraChevronCell>,
    cellToMerge: UncertainCompatible<ExtraChevronCell>
  ): Compatible<ExtraChevronCell> {
    return this.getCompatibleCell({ ...cell, isExpanded: cellToMerge.isExpanded, text: cellToMerge.text });
  }

  handleKeyDown(
    cell: Compatible<ExtraChevronCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<ExtraChevronCell>; enableEditMode: boolean } {
    let enableEditMode = keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER;
    const cellCopy = { ...cell };

    const currentCombination: KeyCombination = { keyCode, ctrl, shift, alt };

    if (this.compareChildRowKeyCombination(currentCombination)) {
      cell.createChild?.(cell.location);
      return { cell, enableEditMode: false };
    }

    if (this.compareSiblingRowKeyCombination(currentCombination)) {
      cell.createSibling?.(cell.parentId, cell.location.rowId);
      return { cell, enableEditMode: false };
    }

    if (this.compareExpandChildrenKeyCombination(currentCombination)) {
      cell.expandChildren?.(cell.location)
      return { cell, enableEditMode: false }
    }


    if (this.compareSiblingRowAboveKeyCombination(currentCombination)) {
      cell.createSiblingAbove?.(cell.parentId, cell.location.rowId)
      return { cell, enableEditMode: false }
    }

    const char = getCharFromKeyCode(keyCode, shift);
    if (keyCode === keyCodes.SPACE && cellCopy.isExpanded !== undefined && !shift) {
      cellCopy.isExpanded = !cellCopy.isExpanded;
    } else if (!ctrl && !alt && isAlphaNumericKey(keyCode) && !(shift && keyCode === keyCodes.SPACE)) {
      cellCopy.text = !shift ? char.toLowerCase() : char;
      enableEditMode = true;
    }
    return { cell: cellCopy, enableEditMode };
  }

  getClassName(cell: Compatible<ExtraChevronCell>, isInEditMode: boolean): string {
    const isExpanded = cell.hasChildren ? (cell.isExpanded ? 'expanded' : 'collapsed') : '';
    const className = cell.className ?? '';
    return `${isExpanded} ${className}`;
  }

  getStyle(cell: Compatible<ExtraChevronCell>, isInEditMode: boolean): CellStyle {
    const indent = cell.indent ?? 0;
    const elementMarginMultiplier = indent * 1.4;
    return { paddingLeft: `calc(${elementMarginMultiplier}em + 2px)` };
  }

  render(
    cell: Compatible<ExtraChevronCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<ExtraChevronCell>, commit: boolean) => void
  ): React.ReactNode {
    return !isInEditMode ? (
      <>
        {cell.hasChildren ? (
          <div
            className="chevron"
            style={{
              transform: cell.isExpanded ? 'rotate(90deg)' : '',
              margin: '0px 5px 0px 5px',
              transition: 'all 0.2s',
            }}
            onPointerDown={(e) => {
              e.stopPropagation();
              cell.expandChildren?.(cell.location, true)
              onCellChanged(this.getCompatibleCell({ ...cell, isExpanded: !cell.isExpanded }), true);
            }}
          >
            <span className="icon">❯</span>
          </div>
        ) : (
          <div className="no-child" />
        )}
        {cell.renderer?.(cell.text) ?? cell.text}
      </>
    ) : (
      <input
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.text}
        onChange={(e) => onCellChanged(this.getCompatibleCell({ ...cell, text: e.currentTarget.value }), false)}
        onBlur={(e) => onCellChanged(this.getCompatibleCell({ ...cell, text: e.currentTarget.value }), true)}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode)) e.stopPropagation();
        }}
      />
    );
  }
}
