import React from 'react'
import Grid from '../../../components/grid'
import { Badge } from 'reactstrap'
import LoaderTransparent from '../../../components/Loading'
import { Link } from 'react-router-dom'
import { useRouter } from '../../../hooks'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Query, Mutation } from '../../../api'
import { statuses } from '../components/TenderStatus'
import { useTranslation } from 'react-i18next'

const TendersTable = () => {
  const { t } = useTranslation()
  const {
    history,
    match,
    query: { projectId },
  } = useRouter()

  const { data, loading } = useQuery(Query.TENDERS, { variables: { projectId } })

  const [deleteTender, { loading: deleteLoading }] = useMutation(Mutation.DELETE_TENDER, {
    refetchQueries: [{ query: Query.TENDERS, variables: { projectId } }],

    // update: (store, { data: { deleteTender } }) => {
    //   let data = store.readQuery({ query: Query.TENDERS, variables: { projectId } })
    //   data.tenders = data.tenders.filter((t) => t.id !== deleteTender.id)
    //   store.writeData({ query: Query.TENDERS, variables: { projectId }, data })
    // },
  })

  const { tenders } = data || { tenders: [] }

  const rows = tenders.map((t) => ({
    ...t,
    packageManager: t.packageManager ? t.packageManager.name : null,
    bidsCount: t.bids.filter((b) => b.round === t.round).length / t.bidders.length,
  }))

  return (
    <>
      <Grid
        actionBar={{
          onNew: () => history.push(`${match.url}/new`),
          newButtonText: t('Procurement_tenders_new_tender_btn'),
        }}
        rows={rows}
        formatters={[
          {
            formatter: ({ value, row }) => (
              <Link className="text-dark" to={`${match.url}/${row.id}`}>
                {value}
              </Link>
            ),
            for: ['name'],
          },
          {
            formatter: ({ value, row }) => (
              <Badge color={statuses[value].color}>{t(statuses[value].title)}</Badge>
            ),
            for: ['status'],
          },
          {
            formatter: ({ value }) => t('formatter_dateTime2', { date: value }),
            for: ['commencementDate', 'submissionDate'],
          },
          {
            formatter: ({ value, row }) => {
              const sentBids = row.bids.filter((b) => b.round === row.round && b.priceBreakdown)
                .length
              const biddersCount = row.bidders.length

              return <span>{`${sentBids} / ${biddersCount}`}</span>
            },
            for: ['bidsCount'],
          },
        ]}
        rowActions={[
          {
            title: t('Procurement_tenders_show_btn'),
            action: (r) => history.push(`${match.url}/${r.id}`),
          },
          {
            title: t('Procurement_tenders_delete_btn'),
            color: 'danger',
            action: (r) => deleteTender({ variables: { id: r.id } }),
          },
        ]}
        columns={[
          { name: 'name', title: t('Procurement_tenders_name_label'), width: 290 },
          { name: 'packageManager', title: t('Procurement_tenders_package_manager_label') },
          { name: 'status', title: t('Procurement_tenders_status_label') },
          { name: 'round', title: t('Procurement_tenders_round_label'), width: 60 },
          {
            name: 'bidsCount',
            title: t('Procurement_tenders_bidsCount_label', 'Bids / Bidders'),
          },
          { name: 'commencementDate', title: t('Procurement_tenders_commencement_label') },
          { name: 'submissionDate', title: t('Procurement_tenders_submission_label') },
        ]}
      />
      {(deleteLoading || loading) && <LoaderTransparent />}
    </>
  )
}

export default TendersTable
