import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'

export const lookupLocalStorage = 'i18nextLng' // default: i18nextLng
export const fallbackLng = 'en'

const availableLanguages = ['en', 'de', 'ru', 'es', 'pl', 'cs', 'hu', 'ro', 'sr', 'th']

availableLanguages.forEach((element) => {
  if (element !== 'en') {
    import(`moment/locale/${element}`)
  }
}) // we are importing only the locales that we need.

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // lng: 'cs',
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
    },
    fallbackLng,
    lookupLocalStorage,
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    //keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: (value, format, lng) => {
        switch (format) {
          case 'fromNow':
            return moment(value)
              .locale(lng)
              .fromNow()

          case 'formatterDateTime':
            return moment(value)
              .locale(lng)
              .format('LLL')

          case 'formatterDateTime2':
            return moment(value)
              .locale(lng)
              .format('lll')

          case 'formatterDate':
            return moment(value)
              .locale(lng)
              .format('LL')

          case 'formatterDate2':
            return moment(value)
              .locale(lng)
              .calendar()

          case 'formatterDateL':
            return moment(value)
              .locale(lng)
              .format('L')

          default:
            return value
        }
      }, //if the format is 'currentDate', then take its __value__ transfom it to a moment object, translate it to the current language and show it in Month DD, YYYY format.
    },
    react: {
      wait: true,
    },
  })

export default i18n
