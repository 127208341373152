import { useState } from 'react'
import axios from 'axios'

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

interface ISignedUploadData {
  file: File
  id: string
  name?: string
  signedUrl: string
}

export const useUpload = () => {
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [uploads, setUploads] = useState<any>({})

  const upload = async (fileUploads: ISignedUploadData[]) => {
    setLoading(true)
    const result = await Promise.all(
      fileUploads.map(async (f) => {
        setUploads((u: any) => ({
          [f.signedUrl]: {
            ...u[f.signedUrl],
            name: f.name,
            id: f.id,
            progress: 0,
            loaded: 0,
            total: formatBytes(f.file.size),
          },
        }))
        await axios.put(f.signedUrl, f.file, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progress) => {
            setUploads((u: any) => {
              return {
                ...u,
                [f.signedUrl]: {
                  ...u[f.signedUrl],
                  name: f.name,
                  id: f.id,
                  progress: Math.round((progress.loaded / progress.total) * 100),
                  loaded: formatBytes(progress.loaded),
                  total: formatBytes(f.file.size),
                },
              }
            })
          },
        })

        return f.id
      }),
    )
    setLoading(false)
    setProgress(0)

    return result
  }

  return [upload, { progress, loading, uploads }]
}

export default useUpload
