import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import {
  tendering as tenderingRoutes,
  auth as authRoutes,
  organization as organizationRoutes,
  bidders as biddersRoutes,
  general as generalRoutes,
  project as projectRoutes,
  payment as paymentRoutes,
} from './index'

import DashboardLayout from '../layouts/Dashboard'
import AuthLayout from '../layouts/Auth'
import PortalLayout from '../layouts/Portal'
import Page404 from '../pages/auth/Page404'

import { useUser } from '../hooks'
import ScrollToTop from '../components/ScrollToTop'

const childRoutes = (Layout, routes, role) => {
  return routes
    .filter((r) => !r.allowedRoles || !role || r.allowedRoles.includes(role))
    .map(({ path, component: Component, children }, index) => [
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />,
      ...(children
        ? children
          .filter((r) => !r.allowedRoles || !role || r.allowedRoles.includes(role))
          .map(({ path, component: Component }, index) => (
            <Route
              key={index}
              path={path}
              exact
              render={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          ))
        : []),
    ])
  // children && children.filter((r) => !r.hidden).length > 0 ? (
  //   // Route item with children
  //   children
  //     .filter((r) => !r.allowedRoles || !role || r.allowedRoles.includes(role))
  //     .map(({ path, component: Component }, index) => (
  //       <Route
  //         key={index}
  //         path={path}
  //         exact
  //         render={(props) => (
  //           <Layout>
  //             <Component {...props} />
  //           </Layout>
  //         )}
  //       />
  //     ))
  // ) : (
  //   // Route item without children
  //   <Route
  //     key={index}
  //     path={path}
  //     exact
  //     render={(props) => (
  //       <Layout>
  //         <Component {...props} />
  //       </Layout>
  //     )}
  //   />
  // ),
}

const Routes = () => {
  const user = useUser()

  const AnonymousRoutes = () => (
    <Switch>
      {childRoutes(AuthLayout, authRoutes)}
      <Redirect to="/auth/signin" />
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  )

  const UserRoutes = ({ role }) => {
    return (
      <Switch>
        {childRoutes(DashboardLayout, generalRoutes, role)}
        {childRoutes(DashboardLayout, tenderingRoutes, role)}
        {childRoutes(DashboardLayout, projectRoutes, role)}
        {childRoutes(DashboardLayout, organizationRoutes, role)}
        {childRoutes(AuthLayout, authRoutes, role, false)}
        {childRoutes(DashboardLayout, paymentRoutes, role)}
        <Redirect exact from="/" to={'/dashboard'} />

        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    )
  }

  const BidderRoutes = () => (
    <Switch>
      {childRoutes(PortalLayout, biddersRoutes)}
      {childRoutes(AuthLayout, authRoutes)}

      <Redirect from="/" to="/auth/signin" />

      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  )

  return (
    <Router>
      <ScrollToTop>
        {!user ? (
          <AnonymousRoutes />
        ) : user.type === 'bidder' ? (
          <BidderRoutes />
        ) : (
          <UserRoutes role={user.rights} />
        )}
      </ScrollToTop>
    </Router>
  )
}

export default Routes
