import React, { useRef, useState } from 'react'
import {
  Alert,
  Card,
  Button,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Badge
} from 'reactstrap'
import * as y from 'yup'
import { Formik } from 'formik'
import Grid from '../../components/grid'
import Loading from '../../components/Loading'
import FileUpload from '../../components/form/FileUpload'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Query, Mutation } from '../../api'

import Pappa from 'papaparse'
import { useTranslation, Trans } from 'react-i18next'

import Input from '../../components/form/FormikInput'

import PlacePicker from '../../components/PlacePicker'
import AddressForm from '../../components/form/Address'
import { toastr } from 'react-redux-toastr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import ReactSelectCreatable from 'react-select/creatable'
import i18next from 'i18next'
import { stringToColor } from '../../utils/functions'

const EditBidder = (props) => {
  const activeLanguage = i18next.language.toUpperCase()

  const { data: tagsData, refetch: refetchTags } = useQuery(Query.TAGS, {
    variables: { language: activeLanguage },
  })
  const tags = tagsData?.tags

  const { isOpen, onClose } = props
  const { t } = useTranslation()

  const tagsRef = useRef<any>(null)

  const [createBidders, createBiddersData] = useMutation(Mutation.CREATE_BIDDERS, {
    onCompleted: onClose,
  })
  const [updateBidder, updateBidderData] = useMutation(Mutation.UPDATE_BIDDER, {
    onCompleted: onClose,
  })

  const [isTagsSelectDisabled, setIsTagsSelectDisabled] = useState<boolean>(false)


  const handleCreate = (bidder) =>
    createBidders({
      variables: { bidders: [bidder] },
      refetchQueries: [{ query: Query.BIDDERS }],
    })

  const handleEdit = (values) =>
    updateBidder({
      variables: { id: props.editBidderValues.id, ...values },
      refetchQueries: [{ query: Query.BIDDERS }],
    })

  const validationSchema = y.object().shape({
    name: y.string().required(t('Procurement_bidders_modal_name_required')),
    contactName: y.string(),
    phone: y
      .string()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        t('Procurement_bidders_modal_phone_invalid'),
      )
      .required(t('Procurement_bidders_modal_phone_required')),
    email: y
      .string()
      .email(t('Procurement_bidders_modal_email_type_email'))
      .required(t('Procurement_bidders_modal_email_required')),
    address: AddressForm.validationSchemaRequired,
  })
  const initialValues = props.editBidderValues
    ? props.editBidderValues
    : {
      name: '',
      contactName: '',
      email: '',
      phone: '',
      address: { ...AddressForm.initialValues },
    }



  const placePickerOnChange = (address, setFieldValue) => {
    let addressFieldValue = {
      city: address.city,
      street: address.street_name + ' ' + address.street_number,
      zip: address.zip,
      country: address.country,
      lat: address.latLng.lat,
      lng: address.latLng.lng,
    }
    setFieldValue('address', addressFieldValue)
  }

  return (
    <Formik
      onSubmit={async (values, { setSubmitting }) => {
        const tagsChanged =
          props.editBidderValues?.tags?.[activeLanguage]?.length !==
          tagsRef?.current?.getValue().length

        const submitValues = {
          ...values,
          tags: {
            [activeLanguage]: tagsRef?.current?.getValue().map(({ label }) => label),
          },
        }
        if (props?.editBidderValues?.id) await handleEdit(submitValues)
        else await handleCreate(submitValues)

        if (tagsChanged) refetchTags()

        setSubmitting(false)
      }}
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      render={({ resetForm, submitForm, setFieldValue }) => {
        const handleClose = () => {
          resetForm()
          onClose()
        }
        return (
          <Modal isOpen={isOpen} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>
              {props.editBidderValues
                ? t('Procurement_bidders_modal_edit_bidder', 'Edit Bidder')
                : t('Procurement_bidders_modal_add_new_bidder', 'Add new Bidder')}
            </ModalHeader>
            <ModalBody>
              <Input name="name" label={t('Global_name', 'Name')} />
              <Input name="contactName" label={t('Global_contact_name', 'Contact name')} />
              <Input name="email" label={t('Global_email', 'E-mail')} />
              <Input name="phone" label={t('Global_phone_number', 'Phone number')} />
              <div className='form-group'>
                <label>{t('TAGS')}</label>
                <ReactSelectCreatable
                  defaultValue={
                    props.editBidderValues?.tags?.map((t) => ({
                      label: t,
                      value: t,
                    })) ?? ''
                  }
                  onChange={(values) => {
                    const isDisabled = values.length >= 10

                    if (isDisabled !== isTagsSelectDisabled) setIsTagsSelectDisabled(isDisabled)
                  }}
                  ref={tagsRef}
                  isMulti
                  isSearchable
                  options={Array.isArray(tags) ? tags.map((t) => ({ label: t, value: t })) : []}
                  isOptionDisabled={() => isTagsSelectDisabled}
                />
              </div>
              <PlacePicker
                initialValue={initialValues?.address?.address}
                onChange={(address, e) => {
                  placePickerOnChange(address, setFieldValue)
                }}
              />
              <AddressForm readOnly={false} name="address" />
            </ModalBody>
            <ModalFooter>
              <Button color="outline-danger" onClick={handleClose}>
                {t('Global_cancel', 'Cancel')}
              </Button>
              <Button color="success" onClick={submitForm}>
                {props.editBidderValues
                  ? t('Procurement_bidders_modal_save_btn', 'Save')
                  : t('Procurement_bidders_modal_add_bidder_btn', 'Add Bidder')}
              </Button>
            </ModalFooter>
            {createBiddersData.loading && <Loading />}
          </Modal>
        )
      }}
    />
  )
}

const BiddersModal = () => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [createBidders, createBiddersData] = useMutation(Mutation.CREATE_BIDDERS, {
    onCompleted: () => setModalOpen(false),
    onError: () => toastr.error('Error', t('Procurement_bidders_error_loading_bidders_from_file')),
  })

  const handleUpload = ({ target, target: { files }, t }) => {
    Pappa.parse(files[0], {
      header: true,
      skipEmptyLines: true,
      complete: (d) => {
        createBidders({
          variables: { bidders: d.data },
          refetchQueries: [{ query: Query.BIDDERS }],
        })
        // @ts-ignore
        target.value = null
      },
    })
  }
  return (
    <>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalHeader> {t('Procurement_bidders_modal_bidders_import_heading')}</ModalHeader>
        <ModalBody>
          <p>
            {t('Download_sample_csv_prefix')}
            <a
              target="_blank"
              href="https://urkla-assets.s3.eu-central-1.amazonaws.com/bidders-import-sample.csv"
            >
              {t('Download_sample_csv_link')}
            </a>
            {t('Download_sample_csv_suffix')}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={() => setModalOpen(false)}>
            Close
          </Button>
          <FileUpload
            loading={createBiddersData.loading}
            rounded={false}
            size="md"
            label={t('Procurement_bidders_load_bidders_from_file_btn')}
            name="bidders_import"
            accept=".csv, text/csv"
            onChange={handleUpload}
          />
        </ModalFooter>
      </Modal>
      <Button size="lg" className="btn-pill" color="success" onClick={() => setModalOpen(true)}>
        <FontAwesomeIcon icon={faCloudUploadAlt as any} /> Import from File
      </Button>
    </>
  )
}

export default () => {
  const activeLanguage = i18next.language.toUpperCase()
  const [addBidder, setAddBidder] = useState(false)

  const { t } = useTranslation()
  const { data, loading, error } = useQuery(Query.BIDDERS)
  const [deleteBidders, deleteBiddersData] = useMutation(Mutation.DELETE_BIDDERS)
  const { bidders } = data || { bidders: [] }
  const initialEditValues = {
    name: '',
    contactName: '',
    email: '',
    phone: '',
    address: AddressForm.initialValues,
  }

  const [editBidderValues, setEditBidderValues] = useState(initialEditValues)

  const handleAdd = () => {
    setEditBidderValues(initialEditValues)
    setAddBidder(true)
  }

  const handleEdit = (bidder) => {
    setEditBidderValues({
      // @ts-ignore
      id: bidder.id,
      name: bidder.name,
      contactName: bidder.contactName,
      email: bidder.email,
      phone: bidder.phone,
      tags: bidder.tagsArr,
      address: {
        ...bidder.address,
        street: `${bidder?.address?.street_name} ${bidder?.address?.street_number}`,
      },
    })
    setAddBidder(true)
  }

  const handleDelete = (bidders) => {
    deleteBidders({ variables: { bidders }, refetchQueries: [{ query: Query.BIDDERS }] })
  }
  const isLoading = loading || deleteBiddersData.loading

  if (error) return <Alert>{error.message}</Alert>

  const rows = bidders
    .map((b) => ({
      ...b,
      tags: b.tags?.[activeLanguage],
      tendersCount: b.tenders.length,
    }))
    .filter((bidder) => !bidder.deleted)

  const [filteredRows, setFilteredRows] = useState(null)


  const customFilter = (val: string) => {
    const containsFieldsFilter = ['contactName', 'email', 'name', 'phone']


    const newRows = rows.filter((row) => {
      for (let i = 0; i < containsFieldsFilter.length; i++) {
        if (row[containsFieldsFilter[i]].toLowerCase().includes(val.toLowerCase())) return true
      }


      if (row.tags?.some((tag) => tag.toLowerCase().includes(val.toLowerCase()))) return true
      return false

    })
    setFilteredRows(newRows)
  }

  return (
    <Container fluid className="p-0">
      <h1 className="h2 mb-3">{t('Procurement_bidders_bidders_label')}</h1>
      <Card>
        <CardBody>
          <Grid
            customFilter={customFilter}
            actionBar={{
              actionButton: <BiddersModal />,
              newButtonText: t('Procurement_bidders_modal_add_new_bidder', 'Add new Bidder'),
              onNew: () => handleAdd(),
            }}
            rows={(filteredRows ?? rows).map((row) => ({
              ...row,
              tags: row?.tags?.map((tag: string) => <><Badge style={{
                backgroundColor: stringToColor(tag)
              }}>{tag}</Badge>&nbsp;</>),
              tagsArr: row?.tags
            }))}
            rowActions={[
              { title: t('Procurement_bidders_edit_btn'), action: (r) => handleEdit(r) },
              {
                title: t('Procurement_bidders_delete_btn'),
                color: 'danger',
                action: (r) => handleDelete([r.id]),
              },
            ]}
            columns={[
              { name: 'name', title: t('Procurement_bidders_name_label') },
              { name: 'contactName', title: t('Procurement_bidders_contact_name_label') },
              { name: 'email', title: t('Procurement_bidders_email_label') },
              { name: 'phone', title: t('Procurement_bidders_phone_label') },
              { name: 'tags', title: t('TAGS') },
              { name: 'tendersCount', title: t('Procurement_bidders_tenders_label') },
            ]}
          />
          <EditBidder
            isOpen={addBidder}
            onClose={() => setAddBidder(false)}
            editBidderValues={editBidderValues}
          />
          {isLoading && <Loading />}
        </CardBody>
      </Card>
    </Container>
  )
}
