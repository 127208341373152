import React, { useState, useEffect, useCallback } from 'react'
import {
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Button,
  Col,
  Row,
  Spinner,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

import { numberFormat } from '../../../utils'


import Loading from '../../../components/Loading'
import { useTabSwitch } from '../../../hooks'
import styled from 'styled-components'
import cn from 'classnames'
import { Query, Mutation } from '../../../api'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useUser } from '../../../hooks'
import InfoForm from './InfoForm'
import DocumentsTable from './DocumentsTable'

import BidderDocuments from './BidderDocuments'
import BidderMessages from './BidderMessages'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import Countdown from './Countdown'
import { statuses } from '../components/TenderStatus'
import logo from '../../../assets/img/logo_signin.png'
import { PriceBreakdownRow } from '../../../reactgrid/PriceBreakdownGrid/types/PriceBreakdownRow'
import PriceBreakdown, { GRID_MODE } from '../components/PriceBreakdown'
import { PriceBreakdownContext } from '../components/TenderForm'
import * as _ from 'lodash'
import { CellChange } from '@silevis/reactgrid'


const LOCAL_STORAGE_PB_KEY = 'bidderPriceBreakdownDraft'

const ScrollableTabPane = styled(TabPane)`
  & > nav {
    overflow-x: auto;
  }
  .page-link {
    white-space: nowrap;
  }
`


const mapFromChildren = (children) =>
  children.map(({ parentId, ...rest }) => ({
    ...rest,
    expanded: false,
    ...(parentId ? { parentId } : {}),
  }))

export default () => {
  const { t } = useTranslation()
  const user = useUser()
  const [bidder, setBidder] = useState({ bidder: {} })

  const [submitConfirmModalOpen, setSubmitConfirmModalOpen] = useState<boolean>(false)

  const [activeTab, setActiveTab] = useTabSwitch()
  const [tenderRunning, setTenderRunning] = useState(false)
  const [formTouched, setFormTouched] = useState(false)
  const [resubmit, setResubmit] = useState(false)
  const [status, setStatus] = useState<{ title: string; color: string }>({ title: '', color: '' })

  const [priceRows, setPriceRows] = useState<PriceBreakdownRow[]>([])

  const { data, loading, error, called } = useQuery(Query.BIDDER_TENDER, {
    variables: { id: user?.tenderId },
  })

  const [submitBid, submitBidData] = useMutation(Mutation.BIDDER_SUBMIT_BID, {
    refetchQueries: [{ query: Query.BIDDER_TENDER, variables: { id: user?.tenderId } }],
    onCompleted: () => {
      toastr.success('', t('Bidders_portal_bid_successfully_sent', 'Bid successfully sent'))
    },
  })

  const totalPrice = priceRows.reduce((acc, curr) => (curr.price ? curr.price : 0) + acc, 0)



  useEffect(() => {
    if (data?.currentBid?.priceBreakdown?.children.length) {
      setPriceRows(mapFromChildren(data.currentBid.priceBreakdown.children))
    } else {
      const pb = data?.tender.priceBreakdowns.find((pb) => pb.tenderRound === data.tender.round)

      if (pb) {
        setPriceRows(mapFromChildren(pb.children))
      }
    }
  }, [loading, called])

  useEffect(() => {
    if (data) {
      setTenderRunning(data.tender.status === 'RUNNING')
      setBidder(data?.currentBid?.bidder)
      setResubmit(data.tender ? data.currentBid.priceBreakdown : false)
      setStatus(statuses[data.tender.status] || {})
    }
  }, [data])

  const handleSubmit = async () => {
    await submitBid({ variables: { data: priceRows, totalPrice } })
    setFormTouched(false)
    setResubmit(true)
    setSubmitConfirmModalOpen(false)
  }

  useEffect(() => {
    if (!loading) {
      try {
        const priceRowsFromStorage = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_PB_KEY}_${data?.tender.id}`) as string)
        if (priceRowsFromStorage) setPriceRows(priceRowsFromStorage)
      } catch (_e) { }
    }
  }, [loading])

  const handleChanges = (changes: CellChange[]) => {
    let newPriceRows = priceRows
    changes.forEach((change) => {
      let idx = newPriceRows.findIndex((row) => row.rowId === change.rowId)
      console.log({ change })
      if (idx !== -1) {
        // @ts-ignore
        newPriceRows[idx] = { ...newPriceRows[idx], [change.columnId]: change.newCell.value }
      }
    })
    localStorage.setItem(`${LOCAL_STORAGE_PB_KEY}_${data?.tender.id}`, JSON.stringify(newPriceRows))
  }



  if (!bidder)
    return (
      <PageWrapper>
        <h1>{t('Bidders_portal_heading', 'Bidders portal')}</h1>
        <Title>
          {t('Bidders_portal_you_cannot_use_this_tender', 'You cannot use this tender.')}
        </Title>
      </PageWrapper>
    )

  if (error) return <h1>{t('Bidders_portal_data_load_error', 'Could not load data')}</h1>

  return (
    <PageWrapper>
      <Modal isOpen={submitConfirmModalOpen} onClose={() => setSubmitConfirmModalOpen(false)}>
        <ModalHeader>{resubmit
          ? t('Bidders_portal_resubmit_btn', 'Re-Submit existing offer')
          : t('Bidders_portal_submit_btn', 'Submit offer')}{' '}
        </ModalHeader>
        <ModalBody>
          <p>
            {t('Bidders_portal_confirm_modal_body_confirm')}
          </p>
          <p>
            {t('Bidders_portal_confirm_modal_body_bid_sum')} <b>{numberFormat.format(totalPrice)}</b>
          </p>
        </ModalBody>

        <ModalFooter>
          <Button color='primary' onClick={handleSubmit}>
            {t('Bidders_portal_confirm_modal_footer_submit')}
          </Button>
          <Button color='secondary' onClick={() => setSubmitConfirmModalOpen(false)}>
            {t('Bidders_portal_confirm_modal_footer_cancel')}
          </Button>
        </ModalFooter>


      </Modal>
      <div className="text-center">
        <Logo />
        <p className="lead mb-5">{t('Bidders_portal_heading', 'Bidders portal')}</p>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <h1 className="h3 mb-3 sticky-top my-1 p-3 bg-white shadow rounded">
            {data.tender.name}{' '}
            <span className="badge badge-info mx-3">
              {t('bidder_info_form_round_label', 'Round')} {data.tender.round}.
            </span>
            <span className={`badge badge-${status.color}`}>{t(status.title)}</span>
            <Button
              type={'submit'}
              color="success"
              size="lg"
              className="float-right"
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
              }}
              // disabled={!priceRows.length || !tenderRunning || !formTouched} TODO - put back
              onClick={(e) => {
                setSubmitConfirmModalOpen(true)
              }}
            >
              {resubmit
                ? t('Bidders_portal_resubmit_btn', 'Re-Submit existing offer')
                : t('Bidders_portal_submit_btn', 'Submit offer')}{' '}
              {loading ? <Spinner size="sm" style={{ verticalAlign: 'middle' }} /> : ''}
            </Button>
          </h1>

          <TabContainer>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={cn({ active: activeTab === 'info' })}
                  onClick={() => setActiveTab('info')}
                >
                  {t('Bidders_portal_tender_info_navlink', 'Tender info')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={cn({ active: activeTab === 'price' })}
                  onClick={() => setActiveTab('price')}
                >
                  {t('Bidders_portal_price_breakdown_navlink', 'Price breakdown')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={cn({ active: activeTab === 'documents' })}
                  onClick={() => setActiveTab('documents')}
                >
                  {t('Bidders_portal_documents_navlink', 'Documents')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={cn({ active: activeTab === 'messages' })}
                  onClick={() => setActiveTab('messages')}
                >
                  {t('Bidders_portal_messages_navlink', 'Messages')}
                </NavLink>
              </NavItem>
              <div
                style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
                className={'mr-2'}
              >
                {tenderRunning ? (
                  <Countdown
                    date={data.tender.submissionDate}
                    running={data.tender.status === 'RUNNING'}
                  />
                ) : (
                  <StatusAlert>
                    <Icon icon={faExclamation as any} className={'mr-2'} />
                    {t('Bidders_portal_tender_not_running_alert', 'Tender is not running.')}
                  </StatusAlert>
                )}
              </div>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="info">
                <Row>
                  <Col>
                    <Title>{t('Bidders_portal_tender_info_title', 'Tender information')}</Title>
                    <InfoForm tender={data.tender} bidder={bidder} />
                  </Col>
                  <Col>
                    <Title>{t('Bidders_portal_documents_title', 'Related documentation')}</Title>
                    <DocumentsTable tender={data.tender} />
                  </Col>
                </Row>
              </TabPane>
              <ScrollableTabPane tabId="price">
                <Title>{t('Bidders_portal_tab_price_breakdown_title', 'Price breakdown')}</Title>
                <PriceBreakdownContext.Provider
                  value={{
                    tenderStatus: data.tender ? data.tender.status : '',
                    fetching: loading,
                    gridMode: GRID_MODE.BIDDER,
                    priceRows,
                    onChange: handleChanges,
                    //@ts-ignore
                    setPriceRows,
                  }}
                >
                  <PriceBreakdown />
                </PriceBreakdownContext.Provider>
              </ScrollableTabPane>
              <TabPane tabId="documents">
                <Title>{t('Bidders_portal_tab_documents_title', 'Documents')}</Title>
                {data.currentBid && (
                  <BidderDocuments bid={data.currentBid} readOnly={!tenderRunning} />
                )}
              </TabPane>
              <TabPane tabId="messages">
                <Title>{t('Bidders_portal_tab_messages_title', 'Messages')}</Title>
                <BidderMessages tender={data.tender} />
              </TabPane>
            </TabContent>
          </TabContainer>
        </>
      )}
    </PageWrapper>
  )
}

const PageWrapper = styled.div.attrs(() => ({}))``

const Title = styled.p.attrs(() => ({ className: 'h4' }))``

const TabContainer = styled.div.attrs((props) => ({
  className: 'tab mt-3',
}))``

const StatusAlert = styled(Alert)`
  padding: 0.5rem 1rem;
  color: #f44455;
  border: 1px solid #f44455;
  background: #ffffff;
  margin-bottom: 0;
  display: flex;
  align-items: baseline;
`

const Logo = styled.img.attrs((props) => ({
  src: logo,
}))`
  width: 200px;
  margin-bottom: -50px;
`
