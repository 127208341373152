import React from 'react'
import {
  UncontrolledDropdown as Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { languages } from '../constants/language.ts'
import styled from 'styled-components'

const FlagIcon = styled.i`
  background: transparent url(${(props) => props.src}) 0% 0% no-repeat padding-box;
  background-position: center center;
  background-size: cover;
  height: 16px;
  line-height: 16px;
  width: 24px;
  display: inline-block;
  margin-right: 10px;
`

const LanguageSelect = () => {
  const { i18n } = useTranslation()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  let currentLanguage = languages.find((l) => i18n.language.startsWith(l.code))

  if (currentLanguage.code !== i18n.language) changeLanguage(currentLanguage.code)

  return (
    <Dropdown>
      <DropdownToggle caret color="btn-link">
        {currentLanguage.name}
      </DropdownToggle>
      <DropdownMenu>
        {languages.map((lang) => (
          <DropdownItem
            key={lang.code}
            onClick={() => {
              changeLanguage(lang.code)
            }}
          >
            <FlagIcon src={require('../assets/img/flags/' + lang.code + '.png')} />
            {lang.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default LanguageSelect
