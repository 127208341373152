import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Grid from '../../../../components/grid'
import numeral from 'numeral'
import AwardModalConfirmAlert from './components/AwardModalConfirmAlert.js'

export default ({ onChange, onClose, bids, open, ...props }) => {
  const { t } = useTranslation()
  const [alertModal, setAlertModal] = useState(false)
  const [selectedWinnerId, setSelectedWinnerId] = useState(null)
  const [selectedWinnerName, setSelectedWinnerName] = useState(null)

  const rows = bids.map((b) => ({
    ...b,
    name: b.bidder.name,
  }))

  const onSelectWinnerClick = (id, name) => {
    setSelectedWinnerId(id)
    setSelectedWinnerName(name)
    setAlertModal(true)
  }

  return (
    <Modal isOpen={open} className={'modal-lg'} toggle={onClose} {...props}>
      <ModalHeader toggle={onClose}>
        {t('tender_summary_award_modal_select_winner_title', 'Select winner')}
      </ModalHeader>
      <ModalBody>
        <Grid
          columns={[
            { name: 'name', title: t('Tender_bids_table_column_name', 'Name') },
            { name: 'totalPrice', title: t('Tender_bids_table_column_price', 'Total price') },
            {
              name: 'updatedAt',
              title: t('Tender_bids_table_column_updated_at', 'Last modification'),
            },
            { name: 'markAsWinner' },
          ]}
          formatters={[
            {
              formatter: ({ value, row }) => numeral(value).format('0,0,-'),
              for: ['totalPrice'],
            },
            {
              formatter: ({ row }) => (
                <Button
                  id={row.bidder.id}
                  color="primary"
                  outline
                  className={'float-right'}
                  onClick={(e) => onSelectWinnerClick(row.bidder.id, row.name)}
                >
                  {t('tender_summary_award_modal_mark_as_winner_btn', 'Mark as winner')}
                </Button>
              ),
              for: ['markAsWinner'],
            },
          ]}
          rows={rows}
        />

        <AwardModalConfirmAlert
          isOpen={alertModal}
          onChange={onChange}
          onClose={() => setAlertModal(false)}
          bidderId={selectedWinnerId}
          bidderName={selectedWinnerName}
        />
      </ModalBody>
    </Modal>
  )
}
