import React, { useState, useEffect } from 'react'
import { Card, CardBody, Alert, CardText } from 'reactstrap'
import TenderActionButtons from './TenderActionButtons'
import { Query, Mutation } from '../../../api'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faExclamation, faTrophy } from '@fortawesome/free-solid-svg-icons'
import RoundSelect from './RoundSelect'
import AwardModal from './awardModal'
import BidsFiles from './BidsFiles'
import { numberFormat } from '../../../utils'

const StatusAlert = styled(Alert)`
  padding: 0.55rem 0.95rem;
  margin-top: 1rem;
  color: #f44455;
  border: 1px solid #f44455;
  background: #ffffff;
  align-items: center;
`

export const statuses = {
  PREPARATION: { title: 'Tender_statuses_in_preparation', color: 'primary' },
  ANOTHER_ROUND: { title: 'Tender_statuses_new_round', color: 'secondary' },
  HOD_APPROVAL: { title: 'Tender_statuses_waiting_for_hod_approval', color: 'warning' },
  HOD_REVISE: { title: 'Tender_statuses_hod_sent_for_revise', color: 'danger' },
  CLIENT_APPROVAL: { title: 'Tender_statuses_waiting_for_client_approval', color: 'primary' },
  CLIENT_REVISE: { title: 'Tender_statuses_client_sent_for_revise', color: 'danger' },
  APPROVED: { title: 'Tender_statuses_approved_by_hod_and_client', color: 'success' },
  RUNNING: { title: 'Tender_statuses_tender_is_running', color: 'success' },
  AWARDED: { title: 'Tender_statuses_tender_awarded', color: 'primary' },
  CLOSED: { title: 'Tender_statuses_closed', color: 'primary' },
}

export default ({
  onRoundChange,
  noItemsInBreakdown,
  noBidders,
  tenderFormTouched,
  commencementDateInvalid,
  submissionDateInvalid,
  initialRound,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data, loading } = useQuery(Query.TENDER, { variables: { id } })
  const [round, setRound] = useState(initialRound)
  const [awardModalOpen, setAwardModalOpen] = useState(false)

  const [tenderAction, { loading: tenderActionLoading }] = useMutation(Mutation.TENDER_ACTION, {
    variables: { id },
    onCompleted: () => {
      window.scrollTo(0, 0)
    },
    refetchQueries: [{ query: Query.TENDER, variables: { id } }],
  })

  useEffect(() => {
    initialRound && setRound(initialRound)
  }, [initialRound])

  const handleRoundChange = ({ target: { value } }) => {
    setRound(parseInt(value, 10))
    onRoundChange(parseInt(value, 10))
  }

  if (loading || !data || !data.tender) return null

  const { tender } = data || { tender: {} }

  if (tender.status !== 'AWARDED')
    return (
      <>
        <div className="sticky-top pt-2">
          <h2 className="h3">{t('Tender_status_tender_actions_heading')}</h2>
          <Card>
            <CardBody>
              <TenderActionButtons
                disabled={noItemsInBreakdown || noBidders /*|| tenderFormTouched*/}
                datesInvalid={commencementDateInvalid || submissionDateInvalid}
                loading={tenderActionLoading}
                actions={tender.statusActions}
                onAction={(t) => {
                  if (t === 'award') {
                    setAwardModalOpen(true)
                  } else {
                    tenderAction({ variables: { action: t } })
                  }
                }}
              />

              {noItemsInBreakdown && (
                <StatusAlert>
                  <Icon icon={faExclamation} className={'mr-2'} />
                  {t('Price_breakdown_no_items_alert')}
                </StatusAlert>
              )}
              {noBidders && (
                <StatusAlert>
                  <Icon icon={faExclamation} className={'mr-2'} />
                  {t('Price_breakdown_no_bidders_alert')}
                </StatusAlert>
              )}
              {/* {tenderFormTouched && (
                <StatusAlert>
                  <Icon icon={faExclamation} className={'mr-2'} />
                  {t('Price_breakdown_form_touched_alert')}
                </StatusAlert>
              )} */}
              {(commencementDateInvalid || submissionDateInvalid) && (
                <StatusAlert>
                  <Icon icon={faExclamation} className={'mr-2'} />
                  {t('Price_breakdown_dates_not_valid_alert')}
                </StatusAlert>
              )}
              <RoundSelect onChange={handleRoundChange} round={round} tender={tender} />
            </CardBody>
          </Card>
        </div>
        <AwardModal
          open={awardModalOpen}
          onChange={(result) => {
            tenderAction({ variables: { action: 'award', payload: { bidderId: result } } })
            setAwardModalOpen(false)
          }}
          onClose={() => {
            setAwardModalOpen(false)
          }}
          bids={tender.bids.filter((b) => b.round === round && b.priceBreakdown)}
        />
      </>
    )

  if (tender.status === 'AWARDED') {
    if (tender.winnerBidder) {
      let winningBid = tender.bids.filter(
        (b) => b.round === tender.round && b.bidder.id === tender.winnerBidder.id,
      )[0]

      return (
        <div className="sticky-top pt-2">
          <Card body>
            <h2 className="h3 text-warning">
              <Icon icon={faTrophy} className={'float-right'} />
              {t('Tender_status_winner_heading', 'Winner')}
            </h2>
            <div class="border-top border-warning my-3" />
            <p>
              <b>{t('Tender_status_winner_name', 'Name')}:</b>
              <CardText className="float-right">{winningBid.bidder.name}</CardText>
            </p>
            <p>
              <b>{t('Tender_status_winner_total', 'Total price')}:</b>
              <CardText className="float-right">
                {numberFormat.format(winningBid.totalPrice)}
              </CardText>
            </p>
            <p>
              <b>{t('Tender_status_winner_last_change', 'Last change')}:</b>
              <CardText className="float-right">
                {t('formatter_date2', { date: winningBid.updatedAt })}
              </CardText>
            </p>
            <p>
              <b>{t('Tender_status_winner_files', 'Files')}:</b>
              <BidsFiles files={winningBid.files} className="float-right" />
            </p>
          </Card>
        </div>
      )
    } //else {
    // return (
    //   <div className="sticky-top pt-2">
    //     <Card body>
    //       <h2 className="h3 text-warning">
    //         <Icon icon={faExclamation} className={'float-right'} />
    //         {t('Tender_status_unknown_winner_heading', 'Unknown winner')}
    //       </h2>
    //     </Card>
    //   </div>
    // )
    //}
  }
  return null
}
