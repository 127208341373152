import React, { useState, useEffect } from 'react'
import {
  TreeDataState,
  SortingState,
  SelectionState,
  FilteringState,
  CustomTreeData,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedSelection,
  EditingState,
  DataTypeProvider,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableTreeColumn,
  TableColumnResizing,
  Toolbar,
  VirtualTable,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { currency, unitPrice } from './../../../tendering/components/TenderSummary'
import { useUpdate } from 'react-use'
import styled from 'styled-components'

const TestTable = ({ data }) => {
  const { t } = useTranslation()
  const update = useUpdate()
  const getChildRows = (row, rootRows) => (row ? row.children : rootRows)
  const getRowId = (row) => row.ID
  const [columns] = useState([
    { name: 'Name', title: t('Price_breakdown_column_name') },
    {
      name: 'Unit',
      title: t('Price_breakdown_column_units'),
    },

    { name: 'Acreage', title: t('Price_breakdown_column_quantity') },
    { name: 'UnitPrice', title: 'UnitPrice' },
    { name: 'Price', title: t('Price_breakdown_column_price') },
  ])
  const [defaultColumnWidths] = useState([
    { columnName: 'Name', width: 600 },
    { columnName: 'Unit', width: 80 },
    { columnName: 'UnitPrice', width: 80 },
    { columnName: 'Price', width: 80 },
    { columnName: 'Acreage', width: 80 },
  ])
  const [editingStateColumnExtensions] = useState([{ columnName: 'Price', editingEnabled: false }]) //https://supportcenter.devexpress.com/Ticket/Details/T873212/reactive-grid-disable-editing-for-a-specific-cell-or-column-on-a-mouse-click-if
  const [filteringStateColumnExtensions] = useState([
    { columnName: 'Unit', filteringEnabled: false },
    { columnName: 'Acreage', filteringEnabled: false },
    { columnName: 'UnitPrice', filteringEnabled: false },
    { columnName: 'Price', filteringEnabled: false },
  ])

  const commitChanges = ({ added, changed, deleted }) => {
    if (changed) {
      let changedId = Number(Object.keys(changed)[0])
      let changedNode = data.first(function (node) {
        return node.model.ID === changedId
      })

      if (changed[changedId]) {
        let oldModel = {
          Name: changedNode.model.Name,
          Unit: changedNode.model.Unit,
          Acreage: changedNode.model.Acreage,
          UnitPrice: changedNode.model.UnitPrice,
          Price: changedNode.model.Price,
        }

        if (changed[changedId].Name) changedNode.model.Name = changed[changedId].Name
        if (changed[changedId].Unit) changedNode.model.Unit = changed[changedId].Unit
        if (changed[changedId].Acreage)
          changedNode.model.Acreage = parseInt(changed[changedId].Acreage)
        if (changed[changedId].UnitPrice)
          changedNode.model.UnitPrice = parseInt(changed[changedId].UnitPrice)

        if (changed[changedId].Acreage || changed[changedId].UnitPrice) {
          if (changed[changedId].Acreage) {
            changedNode.model.Price = changedNode.model.Acreage * oldModel.UnitPrice
            let newParentAcreages = [0, 0, 0]
            changedNode.parent.children.forEach((ch) => {
              newParentAcreages[0] += ch.model.Acreage
            })
            changedNode.parent.model.Acreage = newParentAcreages[0]
            changedNode.parent.parent.children.forEach((ch) => {
              newParentAcreages[1] += ch.model.Acreage
            })
            changedNode.parent.parent.model.Acreage = newParentAcreages[1]
            changedNode.parent.parent.children.forEach((ch) => {
              newParentAcreages[2] += ch.model.Acreage
            })
            changedNode.parent.parent.parent.model.Acreage = newParentAcreages[2]
          }
          if (changed[changedId].UnitPrice) {
            changedNode.model.Price = oldModel.Acreage * changedNode.model.UnitPrice
            let newParentUnitPrices = [0, 0, 0]
            changedNode.parent.children.forEach((ch) => {
              newParentUnitPrices[0] += ch.model.UnitPrice
            })
            changedNode.parent.model.UnitPrice = newParentUnitPrices[0]
            changedNode.parent.parent.children.forEach((ch) => {
              newParentUnitPrices[1] += ch.model.UnitPrice
            })
            changedNode.parent.parent.model.UnitPrice = newParentUnitPrices[1]
            changedNode.parent.parent.children.forEach((ch) => {
              newParentUnitPrices[2] += ch.model.UnitPrice
            })
            changedNode.parent.parent.parent.model.UnitPrice = newParentUnitPrices[2]
          }
          let newParentPrices = [0, 0, 0]
          changedNode.parent.children.forEach((ch) => {
            newParentPrices[0] += ch.model.Price
          })
          changedNode.parent.model.Price = newParentPrices[0]
          changedNode.parent.parent.children.forEach((ch) => {
            newParentPrices[1] += ch.model.Price
          })
          changedNode.parent.parent.model.Price = newParentPrices[1]
          changedNode.parent.parent.parent.children.forEach((ch) => {
            newParentPrices[2] += ch.model.Price
          })
          changedNode.parent.parent.parent.model.Price = newParentPrices[2]
          update()
        }
      }
    }
  }

  return (
    <div className="card">
      <Grid rows={data.model.children} columns={columns} getRowId={getRowId}>
        <TreeDataState />
        <FilteringState columnExtensions={filteringStateColumnExtensions} />
        <SortingState />
        <SelectionState />
        <CustomTreeData getChildRows={getChildRows} />
        <IntegratedFiltering />
        <IntegratedSelection />
        <IntegratedSorting />
        <SelectableTypeProvider for={['Unit']} />
        <EditingState
          onCommitChanges={commitChanges}
          columnExtensions={editingStateColumnExtensions}
        />
        <VirtualTable rowComponent={TableRow} cellComponent={TableCell} />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow cellComponent={FilterCell} />
        <TableTreeColumn for="Name" cellComponent={TableCell} />
        <Toolbar />
        <TableInlineCellEditing startEditAction={'click'} selectTextOnEditStart={'true'} />
      </Grid>
    </div>
  )
}

export default TestTable

const TableRow = ({ row, ...restProps }) => (
  <CustomTableRow
    {...restProps}
    background={row.background ? row.background : '#fff'}
    onFocus={() => {
      row.disabledEditing && document.activeElement.blur()
    }}
  />
)

const TableCell = (props) => {
  if (props.column.name === 'Name') return <TableTreeColumn.Cell {...props} />

  return <Table.Cell {...props} />
}

const SelectableEditor = ({ value, onValueChange, autoFocus, onBlur }) => {
  const { t } = useTranslation()

  const handleChange = (selectedOption) => {
    onValueChange(selectedOption.value)
    setTimeout(() => onBlur())
  }

  return (
    <Select
      options={[
        { value: 'none', label: t('unit_none', 'none') },
        { value: 'm2', label: t('unit_m2', 'm2') },
        { value: 'm3', label: t('unit_m3', 'm3') },
        { value: 'No', label: t('unit_No', 'No') },
        { value: 'kg', label: t('unit_kg', 'kg') },
        { value: 'tons', label: t('unit_tons', 'tons') },
        { value: 'item', label: t('unit_item', 'item') },
      ]}
      onChange={handleChange}
      value={value}
      autoFocus={autoFocus}
      onBlur={onBlur}
    />
  )
}

const SelectableTypeProvider = (props) => {
  return <DataTypeProvider editorComponent={SelectableEditor} {...props} />
}

const FilterCell = (props) => {
  if (props.column.name === 'Name') {
    return <TableFilterRow.Cell {...props} />
  }
  return <th></th>
}

const CustomTableRow = styled(Table.Row)`
  background-color: ${(props) => props.background};
`
