import React, { useState, useEffect } from 'react'
import { HOST_URL } from '../../utils'
import { Query, Mutation } from '../../api'
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks'
import { Row, Col, Button, Progress, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useTranslation, Trans } from 'react-i18next'
import FileUpload from '../form/FileUpload'
import LoadingTransparent from '../Loading'
import FileGrid from './grid'
import FileSelect from './selector'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons'
import groupBy from 'lodash/groupBy'

import { useUpload } from './hooks/useUpload.ts'
import ProgressUpload from './progress'

const FileManager = ({
  files,
  directory,
  uploadDirectory,
  onUploaded,
  onSelected,
  onRemoved,
  readOnly,
  showFileSelect,
}) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState([])
  const [uploading, setUploading] = useState(false)

  const [createFile, createFileData] = useMutation(Mutation.CREATE_FILE)
  const [setFileUploaded] = useMutation(Mutation.SET_FILE_UPLOADED, {
    refetchQueries: [{ query: Query.FILES, variables: { directory } }],
  })
  const [deleteFile, { loading: deleteFileLoading }] = useMutation(Mutation.DELETE_FILE, {
    refetchQueries: [{ query: Query.FILES, variables: { directory } }],
  })

  const [loadFiles, loadFilesData] = useLazyQuery(Query.FILES, { variables: { directory } })

  const [upload, uploadData] = useUpload({ directory, uploadDirectory })

  useEffect(() => {
    if (!files) loadFiles()
  }, [])

  const handleUpload = async ({ target: { files: filesToUpload }, target }) => {
    setUploading(true)
    const filesResult = await Promise.all(
      Array.from(filesToUpload).map(async (f) => {
        const { data } = await createFile({
          variables: {
            input: {
              filename: f.name,
              size: f.size,
              mimetype: f.type,
              encoding: 'utf8',
              directory: uploadDirectory || directory,
            },
          },
        })

        return {
          file: f,
          id: data.createFile.file.id,
          name: f.name,
          signedUrl: data.createFile.uploadUrl,
        }
      }),
    )

    const result = await upload(filesResult)

    await setFileUploaded({ variables: { ids: result } })

    if (onUploaded) onUploaded()
    if (onSelected) onSelected([...files.map((f) => f.id), ...result])

    setSelected([])
    setUploading(false)
    target.value = null
  }

  const rows = (files ? files : loadFilesData.data ? loadFilesData.data.files : []).map((f) => ({
    ...f,
    localFile: uploadDirectory ? f.key.indexOf(uploadDirectory) > -1 : true,
  }))

  return (
    <>
      <ProgressUpload {...uploadData} />

      <FileGrid
        actionBar={{
          actionButton: !readOnly && (
            <>
              <span className="mr-2">
                <FileUpload name="documents_upload" size="lg" multiple onChange={handleUpload} />
              </span>
              {showFileSelect && (
                <span>
                  <FileSelect directory={directory} onSelected={onSelected} files={files} />
                </span>
              )}
            </>
          ),
          actionButtonRight: !readOnly && (
            <Button
              color="outline-danger"
              className="btn-pill mr-1"
              size="lg"
              disabled={selected.length === 0}
              onClick={() => {
                const grouped = groupBy(rows.filter((f) => selected.includes(f.id)), 'localFile')
                if (onRemoved) onRemoved(selected)
                deleteFile({ variables: { ids: grouped[true].map((f) => f.id) } })
                setSelected([])
              }}
            >
              <Icon icon={faTrash} /> {t('FileManager_delete_btn')}
            </Button>
          ),
        }}
        rowActions={
          !readOnly ? [
            {
              title: t('FileManager_delete_btn'),
              color: 'danger',
              action: ({ id, localFile }) => {
                if (localFile) {
                  if (onRemoved) onRemoved([id])
                  deleteFile({ variables: { ids: [id] } })
                } else {
                  if (onRemoved) onRemoved([id])
                }
                setSelected([])
              },
            },
          ] : null
        }
        onSelectionChange={!readOnly ? setSelected : null}
        selection={selected}
        rows={rows}
      />
      {(uploading || loadFilesData.loading || deleteFileLoading) && <LoadingTransparent />}
    </>
  )
}

export default FileManager
