import useRouter from './useRouter'

export default (): [string, (tab: string) => any] => {
  const {
    history,
    match,
    query: { tab },
  } = useRouter()

  const activeTab = tab || 'info'
  const setActiveTab = (tab) => {
    history.replace(`${match.url}?tab=${tab}`)
  }

  return [activeTab, setActiveTab]
}
