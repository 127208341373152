import React from 'react'
import * as xlsx from './xlsx.js'
import * as excelProcessor from './excelProcessor.js'
import styled from 'styled-components'

const App = () => {
  let inputData = []
  let files = []

  const loadInputs = async (e) => {
    if (e.target[0].files[0] !== undefined) files.push(e.target[0].files[0])
    if (e.target[1].files[0] !== undefined) files.push(e.target[1].files[0])
    if (e.target[2].files[0] !== undefined) files.push(e.target[2].files[0])

    for await (const file of files) {
      let data = await xlsx.parseData(file)
      inputData.push(data)
    }
  }

  const go = (e) => {
    e.preventDefault()

    loadInputs(e).then((res) => {
      let workBook = excelProcessor.processBids(inputData)
      excelProcessor.downloadExcel(workBook)
      inputData = [] //promazeme
      files = [] //promazeme
    })
  }

  const mergePrices = (prices) => {
    let result = [
      {
        name: 'Etapa 1',
        rows: [
          { id: 1, name: 'okna', quantity: 30, unit: 'pcs', prices: [] },
          { id: 2, name: 'dvere', quantity: 20, unit: 'pcs', prices: [] },
        ],
        subtotal: [],
      },
      {
        name: 'Etapa 2',
        rows: [
          { id: 1, name: 'omitka', quantity: 10, unit: 'tons', prices: [] },
          { id: 2, name: 'dlazba', quantity: null, unit: 'none', prices: [] },
        ],
        subtotal: [],
      },
    ]

    let groupTotal
    prices.forEach((priceList) => {
      priceList.groups.forEach((group) => {
        groupTotal = 0
        group.rows.forEach((row) => {
          groupTotal += row.price

          result
            .find((g) => g.name === group.name)
            .rows.find((r) => r.name === row.name)
            .prices.push(row.price)
        })

        result.find((g) => g.name === group.name).subtotal.push(groupTotal)
      })
    })

    return result
  }

  let prices1 = {
    groups: [
      {
        name: 'Etapa 1',
        rows: [
          { id: 1, name: 'okna', price: 5 },
          { id: 2, name: 'dvere', price: 6 },
        ],
      },
      {
        name: 'Etapa 2',
        rows: [
          { id: 1, name: 'omitka', price: 7 },
          { id: 2, name: 'dlazba', price: 8 },
        ],
      },
    ],
  }

  let prices2 = {
    groups: [
      {
        name: 'Etapa 1',
        rows: [
          { id: 1, name: 'okna', price: 11 },
          { id: 2, name: 'dvere', price: 12 },
        ],
      },
      {
        name: 'Etapa 2',
        rows: [
          { id: 1, name: 'omitka', price: 13 },
          { id: 2, name: 'dlazba', price: 14 },
        ],
      },
    ],
  }

  let prices3 = {
    groups: [
      {
        name: 'Etapa 1',
        rows: [
          { id: 1, name: 'okna', price: 30 },
          { id: 2, name: 'dvere', price: 40 },
        ],
      },
      {
        name: 'Etapa 2',
        rows: [
          { id: 1, name: 'omitka', price: 50 },
          { id: 2, name: 'dlazba', price: 60 },
        ],
      },
    ],
  }

  let prices = [prices1, prices2, prices3]

  let groups = mergePrices(prices)

  let priceColumns = []
  prices.forEach((price, index) => {
    priceColumns.push({
      title: 'Bidder ' + (index + 1),
    })
  })

  return (
    <div className="App">
      <form onSubmit={(e) => go(e)}>
        <input type="file" id="input1" />
        <input type="file" id="input2" />
        <input type="file" id="input3" />
        <input type="submit" id="inputSubmit" value="zpracovat" />
      </form>
      {groups.map((g, i) => (
        <>
          <div className="d-flex p-0 m-0 pt-3">
            <GroupTitle>{g.name}</GroupTitle>
          </div>
          <table className={'table'}>
            <TableHead priceColumns={priceColumns} />
            <tbody>
              {g.rows.map((row) => {
                return <TableRow data={row} />
              })}
              <SubtotalRow subtotals={g.subtotal} />
            </tbody>
          </table>
        </>
      ))}
    </div>
  )
}

export default App

const GroupTitle = styled.h4`
  font-size: 130%;
  flex: 1 1 auto;
`
const TableHead = ({ priceColumns }) => {
  return (
    <thead>
      <tr>
        <th class="position-relative text-nowrap" scope="col" width="auto">
          <div class="d-flex flex-direction-row align-items-center">#</div>
        </th>
        <th class="position-relative text-nowrap" scope="col" width="auto">
          <div class="d-flex flex-direction-row align-items-center">Name</div>
        </th>
        <th class="position-relative text-nowrap" scope="col" width="auto">
          <div class="d-flex flex-direction-row align-items-center">Quantity</div>
        </th>
        <th class="position-relative text-nowrap" scope="col" width="auto">
          <div class="d-flex flex-direction-row align-items-center">Units</div>
        </th>
        {priceColumns.map((column) => {
          return (
            <th class="position-relative text-nowrap bg-success" scope="col">
              <div class="d-flex flex-direction-row align-items-center text-bold">
                {column.title}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
const TableRow = ({ data }) => {
  return (
    <tr>
      <td class="text-nowrap">{data.id}</td>
      <td class="text-nowrap">{data.name}</td>
      <td class="text-nowrap">{data.quantity}</td>
      <td class="text-nowrap">{data.unit}</td>
      {data.prices.map((price) => {
        return <td class="text-nowrap">{price}</td>
      })}
    </tr>
  )
}
const SubtotalRow = ({ subtotals }) => {
  return (
    <tr>
      <td class="text-nowrap"></td>
      <td class="text-nowrap"></td>
      <td class="text-nowrap"></td>
      <td class="text-nowrap">
        <b>Subtotal: </b>
      </td>
      {subtotals.map((subtotal) => {
        return (
          <td class="text-nowrap">
            <b>{subtotal}</b>
          </td>
        )
      })}
    </tr>
  )
}
