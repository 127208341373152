import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap'
import { useTranslation, Trans } from 'react-i18next'

import { useRouter } from '../hooks'

const TrialModal = ({ daysLeft }) => {
  const { t } = useTranslation()
  const { history } = useRouter()
  let wasClosed = !!sessionStorage.getItem('trialModalClosed')

  const [modal, setModal] = useState(true)
  const toggle = () => {
    setModal(!modal)
    if (canContinue) {
      sessionStorage.setItem('trialModalClosed', 'true')
      wasClosed = true
    }
  }

  let canContinue = daysLeft > 0

  return (
    <div>
      {!wasClosed || !canContinue && (
        <Modal isOpen={modal}>
          <ModalHeader>{t('trial_modal_header', 'Warning')}</ModalHeader>
          {canContinue ? (
            <ModalBody>
              <Trans i18nKey="trial_modal_body" count={daysLeft} />
              <Progress color={'danger'} value={100 - (100 / 30) * daysLeft} className={'mt-3'} />
            </ModalBody>
          ) : (
            <ModalBody>
              <strong>{t('trial_modal_trial_ended', 'Your trial has ended.')}</strong>
            </ModalBody>
          )}
          <ModalFooter>
            <Button
              color="success"
              onClick={() => {
                history.push('/payment/subscriptions')
                toggle()
              }}
            >
              {t('trial_modal_buy_btn', 'Buy full version')}
            </Button>{' '}
            {canContinue && (
              <Button color="outline-warning" onClick={toggle}>
                {t('trial_modal_continue_btn', 'Continue')}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}
export default TrialModal
