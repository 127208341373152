import React, { useState, useMemo } from 'react'
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

const RowActions = ({ row, actions }) => {
  const [dropdownOpen, setDropdwonOpen] = useState(false)
  const toggle = () => setDropdwonOpen(!dropdownOpen)
  const _actions = useMemo(
    () =>
      actions.map(({ title, color, action }, i) => (
        <DropdownItem key={i} className={`text-${color}`} onClick={() => action(row)}>
          {title}
        </DropdownItem>
      )),
    [actions, row],
  )

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} onClick={(e) => e.stopPropagation()}>
      <DropdownToggle color="none" tabIndex={-1}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </DropdownToggle>
      <DropdownMenu>{_actions}</DropdownMenu>
    </Dropdown>
  )
}

export default RowActions
