import React from 'react'
import cn from 'classnames'
import { useTabSwitch } from '../../hooks'
import { Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap'
import UsersTable from './components/UsersTable.tsx'
import ProjectsTable from './components/ProjectsTable'
import OrganizationInfo from './components/OrganizationInfo'
import Documents from './components/Documents'

import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useTabSwitch()

  return (
    <>
      <h1 className="h2 mb-3">{t('Organization_screen _organization_settings_label')}</h1>
      <div className="tab">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={cn({ active: activeTab === 'info' })}
              onClick={() => setActiveTab('info')}
            >
              {t('Organization_info_info_tablabel')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={cn({ active: activeTab === 'projects' })}
              onClick={() => setActiveTab('projects')}
            >
              {t('Organization_projects_projects_tablabel')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={cn({ active: activeTab === 'users' })}
              onClick={() => setActiveTab('users')}
            >
              {t('Organization_users_users_tablabel')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={cn({ active: activeTab === 'documents' })}
              onClick={() => setActiveTab('documents')}
            >
              {t('Organization_documents_documents_tablabel')}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="info">
            <h1 className="h3 mb-3">{t('Organization_info_info_label')}</h1>
            <OrganizationInfo />
          </TabPane>
          <TabPane tabId="projects">
            <h1 className="h3 mb-3">{t('Organization_projects_projects_label')}</h1>
            <ProjectsTable />
          </TabPane>
          <TabPane tabId="users">
            <h1 className="h3 mb-3">{t('Organization_users_users_label')}</h1>
            <UsersTable />
          </TabPane>
          <TabPane tabId="documents">
            <h1 className="h3 mb-3">{t('Organization_documents_documents_label')}</h1>
            <Documents />
            {/* <FileManager directory={'general'} /> */}
            {/* <FileManagerTable directory={'general'} /> */}
          </TabPane>
        </TabContent>
      </div>
    </>
  )
}
