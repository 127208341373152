import React from 'react';
import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  getCellProperty,
  Id,
  keyCodes,
  Uncertain,
  UncertainCompatible,
} from '@silevis/reactgrid';

export interface NonEditableChevronCell extends Cell {
  type: 'nonEditableChevron';
  text: string;
  isExpanded?: boolean;
  hasChildren?: boolean;
  parentId?: Id;
  indent?: number;
  renderer?: (text: string) => string;
}

export class NonEditableChevronCellTemplate implements CellTemplate<NonEditableChevronCell> {
  getCompatibleCell(uncertainCell: Uncertain<NonEditableChevronCell>): Compatible<NonEditableChevronCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let isExpanded = false;
    try {
      isExpanded = getCellProperty(uncertainCell, 'isExpanded', 'boolean');
    } catch {
      isExpanded = true;
    }
    let indent = -1;
    try {
      indent = getCellProperty(uncertainCell, 'indent', 'number');
    } catch {
      indent = 0;
    }
    let hasChildren = false;
    try {
      hasChildren = getCellProperty(uncertainCell, 'hasChildren', 'boolean');
    } catch {
      hasChildren = false;
    }
    const value = parseFloat(text);
    return { ...uncertainCell, text, value, isExpanded, indent, hasChildren };
  }

  update(
    cell: Compatible<NonEditableChevronCell>,
    cellToMerge: UncertainCompatible<NonEditableChevronCell>
  ): Compatible<NonEditableChevronCell> {
    return this.getCompatibleCell({ ...cell, isExpanded: cellToMerge.isExpanded, text: cellToMerge.text });
  }

  handleKeyDown(
    cell: Compatible<NonEditableChevronCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<NonEditableChevronCell>; enableEditMode: boolean } {
    const cellCopy = { ...cell };
    if (keyCode === keyCodes.SPACE && cellCopy.isExpanded !== undefined && !shift) {
      cellCopy.isExpanded = !cellCopy.isExpanded;
    }
    return { cell: cellCopy, enableEditMode: false };
  }

  getClassName(cell: Compatible<NonEditableChevronCell>, isInEditMode: boolean): string {
    const isExpanded = cell.hasChildren ? (cell.isExpanded ? 'expanded' : 'collapsed') : '';
    const className = cell.className ?? '';
    return `${isExpanded} ${className}`;
  }

  getStyle(cell: Compatible<NonEditableChevronCell>, isInEditMode: boolean): CellStyle {
    const indent = cell.indent ?? 0;
    const elementMarginMultiplier = indent * 1.4;
    return { paddingLeft: `calc(${elementMarginMultiplier}em + 2px)` };
  }

  render(
    cell: Compatible<NonEditableChevronCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<NonEditableChevronCell>, commit: boolean) => void
  ): React.ReactNode {
    return (
      <>
        {cell.hasChildren ? (
          <div
            className="chevron"
            style={{
              transform: cell.isExpanded ? 'rotate(90deg)' : '',
              margin: '0px 5px 0px 5px',
              transition: 'all 0.2s',
            }}
            onPointerDown={(e) => {
              e.stopPropagation();
              onCellChanged(this.getCompatibleCell({ ...cell, isExpanded: !cell.isExpanded }), true);
            }}
          >
            <span className="icon">❯</span>
          </div>
        ) : (
          <div className="no-child" />
        )}
        {cell.renderer?.(cell.text) ?? cell.text}
      </>
    );
  }
}
