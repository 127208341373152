import useRouter from './useRouter.js'
import { useSearchParam } from 'react-use'

export default (): [
  { activeTab: string; activeRound: number },
  (activeTab: string, activeRound: number) => any
] => {
  const {
    history,
    match,
    query: { tab },
  } = useRouter()
  //const activeTab = useSearchParam('tab') || 'info'
  const activeRound = parseInt(useSearchParam('round') || '1')

  const activeTab = tab || 'info'
  //const activeRound = parseInt(round) || 1

  const setParams = (activeTab, activeRound) => {
    const newTab = activeTab || 'info'
    const newRound = activeRound || 1
    history.replace(`${match.url}?tab=${newTab}&round=${newRound}`)
  }
  return [{ activeTab, activeRound }, setParams]
}
