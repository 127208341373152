import React, { useState, useEffect } from 'react'
import Grid from '../../../components/grid'
import { HOST_URL } from '../../../utils'
import FileIcon from '../../../components/file-manager/FileIcon'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const BidsFiles = ({ files, ...props }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState([])
  const [selectedTotalSize, setSelectedTotalSize] = useState(0)
  const maxDownloadSize = 104857600
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    let selectedFiles = files.filter((f) => selected.indexOf(f.id) > -1)
    setSelectedTotalSize(selectedFiles.reduce((sum, file) => sum + file.size, 0))
  }, [selected])

  const COLUMNS = [
    { title: t('Tender_bids_table_file_column_name_label'), name: 'filename', width: 300 },
    { title: t('Tender_bids_table_file_column_size_label'), name: 'size', align: 'right' },
  ]

  return (
    <div {...props}>
      {files.length > 0 ? (
        <Button onClick={toggle} outline color="primary" size="sm" disabled={files.length === 0}>
          {t('Tender_bids_table_show_files_btn', 'Show files')} ({files.length})
        </Button>
      ) : (
        t('Tender_bids_table_no_files_label', 'No files')
      )}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Grid
            actionBar={{
              actionButtonRight: (
                <Button
                  color="outline-warning"
                  className="btn-pill"
                  size="lg"
                  disabled={selected.length === 0 || selectedTotalSize > maxDownloadSize}
                  onClick={() => {
                    window.location.href = `${HOST_URL}/download/zip?files=${selected.join(
                      '&files=',
                    )}`
                  }}
                >
                  <Icon icon={faDownload} /> {t('Tender_bids_table_files_modal_download_all_btn')}
                </Button>
              ),
            }}
            selectByRowClick
            columns={COLUMNS}
            rows={files}
            rowActions={[
              {
                title: t('Tender_bids_table_files_modal_download_btn'),
                action: (r) => window.open(`${HOST_URL}/download/${r.id}`),
              },
            ]}
            formatters={[
              {
                formatter: ({ value, row }) => (
                  <a
                    className="text-dark"
                    onClick={(e) => e.stopPropagation()}
                    href={`${HOST_URL}/download/${row.id}`}
                  >
                    <FileIcon filename={row.filename} /> {value}
                  </a>
                ),
                for: ['filename'],
              },
            ]}
            selection={selected}
            onSelectionChange={setSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            {t('Tender_bids_table_files_modal_close_btn', 'Close')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
export default BidsFiles
