import { KeyCombination } from '../types/KeyCombination'

interface ConstructorParams {
  expandChildrenComb: KeyCombination
  siblingAboveComb?: KeyCombination
  siblingComb?: KeyCombination
  childComb?: KeyCombination
}

export class KeyCombinationComparer {
  private createSiblingRowKeyCombination?: KeyCombination
  private createChildRowKeyCombination?: KeyCombination
  private createSiblingRowAboveKeyCombination?: KeyCombination
  private expandCellChildrenKeyCombination: KeyCombination

  constructor({ expandChildrenComb, siblingComb, childComb, siblingAboveComb }: ConstructorParams) {
    this.createSiblingRowKeyCombination = siblingComb
    this.createChildRowKeyCombination = childComb
    this.expandCellChildrenKeyCombination = expandChildrenComb
    this.createSiblingRowAboveKeyCombination = siblingAboveComb
  }

  compareKeyCombinations(combination: KeyCombination, config: KeyCombination) {
    let isCombinationValid = true
    Object.keys(config).forEach((key) => {
      if ((config as Record<string, any>)[key] !== (combination as Record<string, any>)[key]) {
        isCombinationValid = false
      }
    })
    return isCombinationValid
  }

  compareSiblingRowKeyCombination(currentCombination: KeyCombination) {
    if (this.createSiblingRowKeyCombination)
      return this.compareKeyCombinations(currentCombination, this.createSiblingRowKeyCombination)
  }
  compareSiblingRowAboveKeyCombination(currentCombination: KeyCombination) {
    if (this.createSiblingRowAboveKeyCombination)
      return this.compareKeyCombinations(
        currentCombination,
        this.createSiblingRowAboveKeyCombination,
      )
  }

  compareChildRowKeyCombination(currentCombination: KeyCombination) {
    if (this.createChildRowKeyCombination)
      return this.compareKeyCombinations(currentCombination, this.createChildRowKeyCombination)
  }

  compareExpandChildrenKeyCombination(currentCombination: KeyCombination) {
    return this.compareKeyCombinations(currentCombination, this.expandCellChildrenKeyCombination)
  }
}
