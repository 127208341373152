import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import { TENDER_ACTIVITY_LOGS } from '../../../api/gql/query'
import GridTable from '../../../components/grid'

interface TenderActivityLogProps {
    tenderId?: string
}
export const TenderActivityLog = ({ tenderId }: TenderActivityLogProps) => {
    const { data: activityLogsData } = useQuery(TENDER_ACTIVITY_LOGS, { variables: { tenderId } })
    const activityLogs = activityLogsData?.tenderActivityLogs


    return <GridTable columns={[
        { name: 'bidder', title: 'Bidder' },
        { name: 'title', title: 'Title' },
        // { name: 'message', title: 'Message' },
        { name: 'datetime', title: 'Date' }
    ]} rows={activityLogs?.length ? activityLogs.map((log) => ({
        bidder: log.bidder?.name,
        title: log?.title,
        // message: log?.description,
        datetime: moment(log.createdAt).format('LLL')
    })) : []} />
}

export default TenderActivityLog
