import React, { useState, useEffect } from 'react'
import { Query } from '../../../api'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'
import Loading from '../../../components/Loading'
import TenderSummaryDownload from './TenderSummaryDownload'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { CustomInput, Row, Col, Alert } from 'reactstrap'
import { useMeasure } from 'react-use'
import SelectBidsModal from './SelectBidsModal.tsx'
import SummaryGrid from './summaryGrid'
import TreeModel from 'tree-model'
import { HOST_URL } from '../../../utils'
import deepCopy from '../../../functions/deepCopy'

const TenderSummary = ({ tenderId, round, template }) => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(Query.TENDER, { variables: { id: tenderId } })
  const [ref, { width: wrapperWidth }] = useMeasure()
  const [showAdjustedPrices, setShowAdjustedPrices] = useState(false)
  const [showUnitPrices, setShowUnitPrices] = useState(false)
  const [showThermometer, setShowThermometer] = useState(false)
  const [showPareto, setShowPareto] = useState(false)
  const [sortBidders, setSortBidders] = useState(true)
  const [bidsToShowIds, setBidsToShowIds] = useState([])
  const [bids, setBids] = useState([])
  const [gridData, setGridData] = useState(null)
  const [paretoGridData, setParetoGridData] = useState(null)
  const [gridLoading, setGridLoading] = useState(false)
  const [paretoCoefficient, setParetoCoefficient] = useState(0.8)
  const [itemsLevel, setItemsLevel] = useState(1)

  useEffect(() => {
    if (data && round) {
      setBids(data.tender.bids.filter((b) => b.round === round && b.priceBreakdown))
      let bidIdsTmp = data.tender.bids
        .filter((b) => b.round === round && b.priceBreakdown)
        .map((val) => {
          return val.id
        })
      setBidsToShowIds(bidIdsTmp)
    }
  }, [data, round])

  useEffect(() => {
    if (data && round && template && template.data && bids) {
      const setData = async () => {
        setGridLoading(true)
        const gdTree = await createGridData(
          template,
          bids.filter((b) => {
            return bidsToShowIds.includes(b.id)
          }),
        )
        setGridData(gdTree)
        setParetoGridData(() => {
          const resultTreeCopy = new TreeModel().parse(deepCopy(gdTree.first().model))
          const paretoTree = filterPareto(resultTreeCopy, bids, paretoCoefficient)
          return paretoTree
        })
        setGridLoading(false)
      }
      setData()
    }
  }, [bids, bidsToShowIds, template, data, round])

  const filterPareto = (resultTree, bids, coefficient) => {
    let rowsForPareto = []
    let paretoFIlteredRows = []
    let avgTotal = 0
    resultTree.all({ strategy: 'post' }, (node) => {
      if (!node.hasChildren()) {
        if (node.model['Avg']) {
          rowsForPareto = [...rowsForPareto, { id: node.model.ID, avg: node.model.Avg }]
          avgTotal += node.model.Avg
        }
      }
    })

    rowsForPareto.sort((a, b) => {
      if (a.avg > b.avg) return -1
      if (a.avg < b.avg) return 1
      return 0
    })

    let tmp = 0
    rowsForPareto.forEach((r) => {
      if (tmp < avgTotal * coefficient) paretoFIlteredRows = [...paretoFIlteredRows, r]
      tmp += r.avg
    })

    if (paretoFIlteredRows.length > 0) {
      resultTree.all({ strategy: 'breadth' }, (node) => {
        if (
          !node.hasChildren() &&
          paretoFIlteredRows.find((r) => {
            return r.id === node.model.ID
          }) === undefined
        ) {
          node.drop()
        }
      })

      for (let i = itemsLevel === null ? 4 : itemsLevel - 1; i >= 1; i--) {
        resultTree.all({ strategy: 'breadth' }, (node) => {
          if (node.getPath().length === i) {
            if (!node.hasChildren()) node.drop()
            else {
              node.model.Avg = 0
              node.model.HighestPrice = 0
              node.model.LowestPrice = 0
              bids.forEach((bid) => {
                node.model['Price_' + bid.id] = 0
              })
              node.children.forEach((ch) => {
                node.model.Avg += ch.model.Avg
                node.model.HighestPrice += ch.model.HighestPrice
                node.model.LowestPrice += ch.model.LowestPrice

                bids.forEach((bid) => {
                  node.model['Price_' + bid.id] += ch.model['Price_' + bid.id]
                })
              })
            }
          }
        })
      }
    }
    return resultTree
  }

  const createGridData = async (template, bids) => {
    if (!template.data) return
    let resultTree = new TreeModel().parse(template.data)

    setItemsLevel(resultTree.model.itemsLevel ? resultTree.model.itemsLevel + 2 : null)
    let bidTrees = {}

    const result = bids.map((b) => new TreeModel().parse(b.priceBreakdown.data))

    result.forEach((br, i) => {
      bidTrees[bids[i].id] = br
    })

    resultTree.walk((node) => {
      if (
        (itemsLevel && node.getPath().length === itemsLevel) ||
        (itemsLevel === null && !node.hasChildren())
      ) {
        let pricesSum = 0
        let unitPricesSum = 0
        let filledPricesCount = 0
        let filledUnitPricesCount = 0
        let highestPrice = 0
        let lowestPrice = 9999999999 //TODO
        let highestUnitPrice = 0
        let lowestUnitPrice = 9999999999 //TODO

        bids.forEach((bid) => {
          let btNode = bidTrees[bid.id].first((n) => {
            return n.model.ID === node.model.ID
          })
          if (!btNode) {
            return
          }
          node.model['Price_' + bid.id] = btNode.model.Price
          node.model['UnitPrice_' + bid.id] = btNode.model.UnitPrice

          if (btNode.model.Price > highestPrice) highestPrice = btNode.model.Price
          if (btNode.model.Price < lowestPrice) lowestPrice = btNode.model.Price
          if (btNode.model.UnitPrice > highestUnitPrice) highestUnitPrice = btNode.model.UnitPrice
          if (btNode.model.UnitPrice < lowestUnitPrice) lowestUnitPrice = btNode.model.UnitPrice

          if (btNode.model.Price !== null) {
            filledPricesCount++
            pricesSum += btNode.model.Price
          }
          if (btNode.model.UnitPrice !== null) {
            filledUnitPricesCount++
            unitPricesSum += btNode.model.UnitPrice
          }
        })
        node.model['Avg'] = pricesSum / filledPricesCount
        node.model['UnitAvg'] = unitPricesSum / filledUnitPricesCount
        node.model['HighestPrice'] = highestPrice
        node.model['LowestPrice'] = lowestPrice
        node.model['HighestUnitPrice'] = highestUnitPrice
        node.model['LowestUnitPrice'] = lowestUnitPrice
      } else {
        bids.forEach((bid) => {
          node.model['Price_' + bid.id] = 0
          node.model['UnitPrice_' + bid.id] = null
        })
      }
    })

    resultTree.all({ strategy: 'post' }, (node) => {
      if (
        (itemsLevel && node.getPath().length < itemsLevel) ||
        (itemsLevel === null && node.hasChildren())
      ) {
        let pricesSum = 0
        let prices = Array(bids.length)
        let highestPrice = 0
        let lowestPrice = 9999999999 //TODO
        let highestUnitPrice = 0
        let lowestUnitPrice = 9999999999 //TODO
        bids.forEach((bid, i) => {
          let price = 0
          let unitPrice = 0
          node.children.forEach((ch) => {
            price = ch.model['Price_' + bid.id] ? price + ch.model['Price_' + bid.id] : price
            unitPrice = ch.model['UnitPrice_' + bid.id]
              ? unitPrice + ch.model['UnitPrice_' + bid.id]
              : unitPrice
          })
          if (price > highestPrice) highestPrice = price
          if (price < lowestPrice) lowestPrice = price
          if (unitPrice > highestUnitPrice) highestUnitPrice = unitPrice
          if (unitPrice < lowestUnitPrice) lowestUnitPrice = unitPrice
          node.model['Price_' + bid.id] = price
          pricesSum += price
          prices[i] = price
        })
        node.model['Avg'] = pricesSum / bids.length
        node.model['HighestPrice'] = highestPrice
        node.model['LowestPrice'] = lowestPrice
        node.model['HighestUnitPrice'] = highestUnitPrice
        node.model['LowestUnitPrice'] = lowestUnitPrice
      }
    })

    return resultTree
  }

  if (loading && !data) return <p>{t('tender_summary_loading', 'Loading ...')}</p>

  return (
    <>
      {bids.length > 0 ? (
        <Row className="mb-2">
          <Col md={10}>
            <SelectBidsModal
              bids={bids}
              bidsToShowIds={bidsToShowIds}
              onModalClose={(ids) => setBidsToShowIds(ids)}
            />

            <CheckboxInput
              id="unitPricesRadio"
              name="unitPricesRadio"
              checked={showUnitPrices}
              label={t('tender_summary_unit_prices', 'Unit prices')}
              onChange={(e) => setShowUnitPrices(e.target.checked)}
            />

            <CheckboxInput
              id="adjustedPricesRadio"
              name="adjustedPricesRadio"
              checked={showAdjustedPrices}
              label={t('tender_summary_adjusted_prices', 'Adjusted prices')}
              onChange={(e) => setShowAdjustedPrices(e.target.checked)}
            />

            <CheckboxInput
              id="thermometerRadio"
              name="thermometerRadio"
              checked={showThermometer}
              label={t('tender_summary_thermometer', 'Thermometer')}
              onChange={(e) => setShowThermometer(e.target.checked)}
            />

            <CheckboxInput
              id="paretoRadio"
              name="paretoRadio"
              checked={showPareto}
              label={t('tender_summary_pareto', 'Pareto')}
              onChange={(e) => setShowPareto(e.target.checked)}
            />
          </Col>
          <Col className="text-right">
            {!gridLoading &&
              (gridData && (
                <TenderSummaryDownload
                  bids={bids.filter((b) => {
                    return bidsToShowIds.includes(b.id)
                  })}
                  data={showPareto ? paretoGridData : gridData}
                  showUnitPrices={showUnitPrices}
                  showAdjustedPrices={showAdjustedPrices}
                  showThermometer={showThermometer}
                  tenderId={tenderId}
                  itemsLevel={itemsLevel}
                />
              ))}
          </Col>
        </Row>
      ) : (
        <Alert className="mb-2 alert bg-white text-warning border-warning border" color="warning">
          <div className="alert-message">
            <Icon icon={faExclamation} className={'mr-2'} />
            {t('tender_summary_no_bids_alert')}
          </div>
        </Alert>
      )}
      {!gridLoading && gridData ? (
        <SummaryGrid
          bids={bids.filter((b) => {
            return bidsToShowIds.includes(b.id)
          })}
          data={showPareto ? paretoGridData : gridData}
          showUnitPrices={showUnitPrices}
          showAdjustedPrices={showAdjustedPrices}
          showThermometer={showThermometer}
          tenderId={tenderId}
          itemsLevel={itemsLevel}
        />
      ) : (
        <Loading />
      )}
    </>
  )
}

export const currency = (value) => `${numeral(value).format('0,0,-')}`
export const unitPrice = (value) => `${numeral(value).format('0.00')}`

const CheckboxInput = styled(CustomInput).attrs((p) => ({
  className: 'd-inline-block mr-3',
  type: 'switch',
}))``

export default TenderSummary
