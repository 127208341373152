import * as types from '../constants'

const initialState = {
  isOpen: localStorage.sideBarOpen === undefined ? true : JSON.parse(localStorage.sideBarOpen),
  isSticky: true,
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SIDEBAR_VISIBILITY_TOGGLE:
      localStorage.sideBarOpen = !state.isOpen
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    case types.SIDEBAR_VISIBILITY_SHOW:
      localStorage.sideBarOpen = true
      return {
        ...state,
        isOpen: true,
      }
    case types.SIDEBAR_VISIBILITY_HIDE:
      localStorage.sideBarOpen = false
      return {
        ...state,
        isOpen: false,
      }

    case types.SIDEBAR_STICKY_TOGGLE:
      return {
        ...state,
        isSticky: !state.isSticky,
      }
    case types.SIDEBAR_STICKY_ENABLE:
      return {
        ...state,
        isSticky: true,
      }
    case types.LAYOUT_BOXED_ENABLE:
    case types.LAYOUT_BOXED_TOGGLE:
    case types.SIDEBAR_STICKY_DISABLE:
      return {
        ...state,
        isSticky: false,
      }

    default:
      return state
  }
}
