import { Id } from '@silevis/reactgrid'
import { AnalyticRowInternal } from './types/AnalyticRow'

export const getSortedUrklaRows = (
  rows: AnalyticRowInternal[],
  hidden: boolean,
  currentIndex = '1',
  level: number = 0,
  parentId?: Id,
): (AnalyticRowInternal & { indent: number; hidden: boolean; idxString: string })[] => {
  const currentLevelRows = rows.filter((row) => row.parentId === parentId)

  return currentLevelRows.flatMap((row, idx) => [
    // @ts-ignore
    { ...row, indent: level, hidden, idxString: row.idxString },
    ...getSortedUrklaRows(
      rows,
      hidden || !row.expanded,
      `${currentIndex}.${idx + 1}`,
      level + 1,
      row.id,
    ),
  ])
}

export const assignIdxString = (
  rows: AnalyticRowInternal[],
  currentIndex = '1',
  parentId?: Id,
): (AnalyticRowInternal & { idxString: string })[] => {
  const currentLevelRows = rows.filter((row) => row.parentId === parentId)

  return currentLevelRows.flatMap((row, idx) => [
    { ...row, idxString: `${currentIndex}.${idx + 1}` },
    ...assignIdxString(rows, `${currentIndex}.${idx + 1}`, row.id),
  ])
}

export default getSortedUrklaRows
