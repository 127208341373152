import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Badge, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Query, Mutation, Enums } from '../../../api'
import { useUser } from '../../../hooks'
import Grid from '../../../components/grid'
import Loading from '../../../components/Loading'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faFileUpload } from '@fortawesome/free-solid-svg-icons'
import FileUpload from '../../../components/form/FileUpload'
import Pappa from 'papaparse'

import { Form, Formik } from 'formik'
import * as y from 'yup'
import Input from '../../../components/form/FormikInput'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'
import i18next from 'i18next'


const UserModal = ({ editUserValues, isOpen, onClose }) => {
  const { t } = useTranslation()
  const [createUser, createUserData] = useMutation(Mutation.CREATE_USER, {
    refetchQueries: [{ query: Query.USERS }],
  })
  const [updateUser, updateUserData] = useMutation(Mutation.UPDATE_USER, {
    refetchQueries: [{ query: Query.USERS }],
  })

  const handleClose = () => {
    onClose()
  }
  const handleSave = () => { }

  const isLoading = createUserData.loading || updateUserData.loading

  const initialValues = editUserValues
    ? editUserValues
    : {
      name: '',
      email: '',
      // password: '',
      // passwordConfirmation: '',
      rights: null,
    }

  return (
    <Modal isOpen={isOpen} toggle={() => handleClose()}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={y.object().shape({
          name: y
            .string()
            .min(3, t('Organization_users_new_user_modal_name_min'))
            .required(t('Organization_users_new_user_modal_name_required')),
          email: y
            .string()
            .email(t('Organization_users_new_user_modal_email_type_email'))
            .required(t('Organization_users_new_user_modal_email_required')),
          // password: y
          //   .string()
          //   .min(3, t('Organization_users_new_user_modal_password_min'))
          //   .required(t('Organization_users_new_user_modal_password_required')),
          // passwordConfirmation: y
          //   .string()
          //   .min(3, t('Organization_users_new_user_modal_password_min'))
          //   .required(t('Organization_users_new_user_modal_password_required'))
          //   .test('passwordConfirmation', t('Organization_users_new_user_modal_password_confirm_must_match'), function (value: y.TestContext) {
          //     return this.parent.password === value
          //   }),
          rights: y
            .string()
            .nullable()
            .required(t('Organization_users_new_user_modal_rights_required')),
        })}
        onSubmit={async (values) => {
          values.id
            ? await updateUser({ variables: values })
            : await createUser({
              variables: {
                ...values,
                language: i18next.language
              },
            })
          handleClose()
        }}
      >
        {() => (
          <Form>
            <ModalHeader toggle={() => handleClose()}>
              {editUserValues
                ? t('Organization_users_edit_user_modal_heading', 'Edit User')
                : t('Organization_users_new_user_modal_heading', 'New User')}
            </ModalHeader>
            <ModalBody>
              <Input name="name" label={t('Organization_users_new_user_modal_name_label')} />
              <Input name="email" label={t('Organization_users_new_user_modal_email_label')} />
              <Input
                type="select"
                name="rights"
                label={t('Organization_users_new_user_modal_rights_label')}
                placeholder={t('Organization_users_new_user_modal_rights_placeholder')}
                options={Enums.RIGHTS.map((r) => ({ label: t('rights_' + r), value: r }))}
              />
              {/* <Input
                name="password"
                type="password"
                label={t('Organization_users_new_user_modal_password_label')}
              />

              <Input
                name="passwordConfirmation"
                type="password"
                label={t('Organization_users_new_user_modal_password_confirmation_label')}
              /> */}

              {createUserData.error && <p>{createUserData.error.message}</p>}
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={handleSave} type="submit">
                {t('Organization_users_new_user_modal_save_btn')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
      {isLoading && <Loading />}
    </Modal>
  )
}

const UsersImportModal = ({ isOpen, users, onClose }) => {
  const { t } = useTranslation()
  const [createManyUsers, createManyUsersData] = useMutation(Mutation.CREATE_MANY_USERS, {
    onError: () => toastr.error(t('Procurement_bidders_error_loading_bidders_from_file'), ''),
  })

  const handleClose = () => {
    onClose()
  }

  const handleImport = async () => {
    await createManyUsers({
      variables: {
        users: users.map(({ name, email }) => ({ name, email })),
      },
      refetchQueries: [{ query: Query.USERS }],
    })

    handleClose()
  }

  const rows = users || []

  return (
    <Modal isOpen={isOpen} toggle={() => handleClose()}>
      <ModalHeader toggle={() => handleClose()}>
        {t('Organization_users_new_user_modal_new_user')}
      </ModalHeader>
      <ModalBody>
        <Grid
          getRowId={(u) => u.email}
          rows={rows}
          columns={[
            { name: 'name', title: t('Organization_users_new_user_modal_name') },
            { name: 'email', title: t('Organization_users_new_user_modal_email') },
          ]}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleImport}>
          {t('Organization_users_new_user_modal_import_all_btn')}
        </Button>
      </ModalFooter>
      {createManyUsersData.loading && <Loading />}
    </Modal>
  )
}

const Users = () => {
  const { t } = useTranslation()
  const user = useUser()
  const [usersImportData, setUsersImportData] = useState<boolean>(false)
  const [userModal, setUserModal] = useState<boolean>(false)
  const { data, loading } = useQuery(Query.USERS)
  const [deleteUser, deleteUserData] = useMutation(Mutation.DELETE_USER, {
    refetchQueries: [{ query: Query.USERS }],
  })
  const [updateUserRights, updateUserRightsData] = useMutation(Mutation.UPDATE_USER_RIGHTS, {
    refetchQueries: [{ query: Query.USERS }],
  })

  const [editUserValues, setEditUserValues] = useState<null | {
    id?: string
    name: string
    email: string
    // password: string
    rights: string[] | null
  }>({
    name: '',
    email: '',
    // password: '',
    rights: null,
  })

  const handleAdd = () => {
    setEditUserValues(null)
    setUserModal(true)
  }
  const handleDelete = ({ id }) => {
    deleteUser({ variables: { id } })
  }

  const handleEdit = (user) => {
    setEditUserValues({
      id: user.id,
      name: user.name,
      email: user.email,
      // password: '',
      rights: user.rights,
    })
    setUserModal(true)
  }

  const handleFileChange = ({ target, target: { files } }) => {
    Pappa.parse(files[0], {
      header: true,
      skipEmptyLines: true,
      complete: (d) => {
        setUsersImportData(d.data)
        target.files = null
      },
    })
  }

  const handleRightsChange = ({ target: { value } }, row) => {
    updateUserRights({
      variables: {
        id: row.id,
        rights: value,
      },
      optimisticResponse: {
        updateUserRights: {
          __typename: 'User',
          ...row,
          rights: value,
        },
      },
    })
  }

  const rows = data ? data.users : []
  const isLoading = loading || deleteUserData.loading

  return (
    <Row>
      <Col>
        <Grid
          actionBar={{
            actionButton: (
              <>
                <Button
                  color="primary"
                  className="btn-pill mr-2"
                  size="lg"
                  onClick={() => handleAdd()}
                >
                  <Icon icon={faUserPlus as any} /> {t('Organization_users_new_user_btn')}
                </Button>
                <FileUpload
                  name="users_csv"
                  accept=".csv, text/csv"
                  label={t('Organization_users_import_from_csv_btn')}
                  onChange={handleFileChange}
                  size="lg"
                  color="secondary"
                />
              </>
            ),
          }}
          columns={[
            { title: t('Organization_users_name_label'), name: 'name', width: '40%' },
            { title: t('Organization_users_email_label'), name: 'email', width: '40%' },
            { title: t('Organization_users_rights_label'), name: 'rights' },
          ]}
          rows={rows}
          rowActions={[
            { title: t('Organization_users_edit_btn'), action: handleEdit },
            { title: t('Organization_users_delete_btn'), color: 'danger', action: handleDelete },
          ]}
          formatters={[
            {
              formatter: ({ value, row }) => (
                <select
                  disabled={row.id === user?.id}
                  onClick={(e) => e.stopPropagation()}
                  defaultValue={value}
                  className={`form-control w-auto`}
                  onChange={(e) => {
                    handleRightsChange(e, row)
                  }}
                >
                  {Enums.RIGHTS.map((o) => (
                    <option key={o} value={o}>
                      {t('rights_' + o)}
                    </option>
                  ))}
                </select>
              ),
              for: ['rights'],
            },
          ]}
        />
        {isLoading && <Loading />}
        <UsersImportModal
          isOpen={!!usersImportData}
          users={usersImportData}
          onClose={() => setUsersImportData(false)}
        />
        <UserModal
          isOpen={!!userModal}
          onClose={() => setUserModal(false)}
          editUserValues={editUserValues}
        />
      </Col>
    </Row>
  )
}

export default Users
