import React from 'react'
import { PriceBreakdownRow } from '../PriceBreakdownGrid/types/PriceBreakdownRow'
import ExcelJS from 'exceljs'
import { parseExcelToPricebreakdowns, readFileDataAsBase64 } from '../utils'
import FileUpload from '../../components/form/FileUpload'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useContext } from 'react'
import { PriceBreakdownContext } from '../../pages/tendering/components/TenderForm'
interface IProps {
  onProcessed: (priceRows: PriceBreakdownRow[]) => any
  disabled?: boolean
}

export const UploadXLSX = ({ onProcessed, disabled }: IProps) => {
  const context = useContext(PriceBreakdownContext)
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)

  const processFile = async (e) => {
    setLoading(true)

    const workbook = new ExcelJS.Workbook()
    const buffer = await readFileDataAsBase64(e)
    const xlsx = await workbook.xlsx.load(buffer)

    onProcessed(parseExcelToPricebreakdowns(xlsx, context.gridMode))

    setLoading(false)
  }

  return (
    <FileUpload
      disabled={disabled}
      size="md"
      accept=".xlsx"
      loading={loading}
      name="xlsx"
      label={t('Button_XSLX_upload')}
      onChange={processFile}
    />
  )
}

export default UploadXLSX
