import React, { useState } from 'react'
import { Input } from 'reactstrap'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const SearchIcon = styled(Icon)`
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  opacity: 0.6;
`

const ClearIcon = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
`

const InputSearch = styled(Input)`
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 2rem;
`

const SearchWrapper = styled.div`
  position: relative;
`

export default ({ onChange, ...props }) => {
  const [value, setValue] = useState('')

  const handleChange = ({ target: { value } }) => {
    setValue(value)
    onChange(value)
  }

  const handleClear = () => {
    setValue('')
    onChange('')
  }

  return (
    <SearchWrapper>
      <SearchIcon icon={faSearch} />
      <InputSearch {...props} value={value} onChange={handleChange} />
      {value.length > 0 && <ClearIcon icon={faTimes} onClick={handleClear} />}
    </SearchWrapper>
  )
}
