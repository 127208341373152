import React, { useState, useContext } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import NoteContext from './NoteContext'

const Note = ({ id, showUnderRow }) => {
  const { openId, notes, readOnly, onOpen, onSave, onCancel } = useContext(NoteContext)
  const showEditor = openId === id
  const value = notes[id]

  return (
    <Wrapper>
      <AddNoteBtn
        size={'sm'}
        onClick={() => {
          onOpen(id)
          //alert(note.readOnly)
        }}
        showNote={!!value}
        className={'text-primary add-note-btn'}
      >
        <AddNoteBtnTriangle />
      </AddNoteBtn>

      {showEditor && (
        <NoteEditor
          value={value}
          readOnly={readOnly}
          onSubmit={(value) => {
            onSave({
              ...notes,
              [openId]: value,
            })
          }}
          onCancel={() => {
            onCancel()
          }}
          showUnderRow={showUnderRow}
        />
      )}
    </Wrapper>
  )
}

export default Note

const NoteEditor = ({ value, onSubmit, onCancel, showUnderRow, readOnly }) => {
  const [localValue, setLocalValue] = useState(value)

  return (
    <NoteEditorWrapper showUnderRow={showUnderRow}>
      <Input
        type="textarea"
        value={localValue}
        disabled={readOnly}
        onChange={(e) => {
          setLocalValue(e.target.value)
        }}
      />
      <EditorBtn onClick={() => onCancel()} className={'text-danger'}>
        <FontAwesomeIcon icon={faTimes} />
      </EditorBtn>
      <EditorBtn
        onClick={() => onSubmit(localValue)}
        className={'text-success'}
        disabled={readOnly}
      >
        <FontAwesomeIcon icon={faCheck} />
      </EditorBtn>
    </NoteEditorWrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 2px;
`
const AddNoteBtn = styled(Button)`
  z-index: 352;
  padding: 0;
  display: contents;
  visibility: ${({ showNote }) => (showNote ? 'visible' : 'hidden')};
`
const AddNoteBtnTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f2a43a;
`

const EditorBtn = styled(Button)`
  z-index: 352;
  padding: 0;
  float: right;
  background: transparent !important;
  width: 50%;
  border: none !important;
  box-shadow: none !important;
  padding: 0px 5px;
`
const NoteEditorWrapper = styled.div`
  z-index: 405;
  position: absolute;
  left: -100px;
  bottom: ${(props) => (!props.showUnderRow ? '10px' : 'unset')};
  top: ${(props) => (props.showUnderRow ? '45px' : 'unset')};
  width: 200px;
  padding: 2px;
  border-radius: 5px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.36);

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-bottom: ${(props) => (!props.showUnderRow ? '10px solid transparent;' : 'unset')};
    border-top: ${(props) => (props.showUnderRow ? '10px solid transparent;' : 'unset')};
    border-right: 10px solid #fff;
    top: ${(props) => (props.showUnderRow ? '-10px' : 'unset')};
    bottom: ${(props) => (!props.showUnderRow ? '-10px' : 'unset')};
    left: 100px;
    position: absolute;
  }
  & textarea {
    resize: none;
  }
`
