import React, { useEffect } from 'react'

import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useRouter, useUser } from '../../../hooks'
import { Query, Mutation } from '../../../api'
import { toastr } from 'react-redux-toastr'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { Button, Row, Col } from 'reactstrap'
import Loading from '../../../components/Loading'
import Input from '../../../components/form/FormikInput'
import AddressForm from '../../../components/form/Address'
import { useTranslation } from 'react-i18next'
import { languages } from '../../../constants/language.ts'
import PlacePicker from '../../../components/PlacePicker'

const ProjectInfo = () => {
  const { t } = useTranslation()

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(3, t('Project_info_create_project_name_min'))
      .required(t('Project_info_create_project_name_required')),
    description: yup.string().required(t('Project_info_create_project_description_required')),
    address: AddressForm.validationSchemaOptional,
  })

  const {
    history,
    match,
    query: { projectId },
  } = useRouter()

  const [loadProject, projectData] = useLazyQuery(Query.PROJECT, {
    variables: { id: projectId },
  })
  const [createProject, createProjectData] = useMutation(Mutation.CREATE_PROJECT, {
    refetchQueries: [{ query: Query.PROJECTS }],
    onCompleted: ({ createProject }) => {
      addUserToProject(createProject.id)
      history.replace(`${match.url.replace('new', createProject.id)}`)
      console.log()
      toastr.success(t('Project_info_create_project_project_created'))
    },
    onError: (e) => console.error(e.message)
  })
  const [updateProject, updateProjectData] = useMutation(Mutation.UPDATE_PROJECT, {
    refetchQueries: [{ query: Query.PROJECTS }],
    variables: { projectId },
    onCompleted: () => {
      toastr.success(t('Project_info_create_project_project_updated'))
    },
  })

  const [addProjectUsers] = useMutation(Mutation.ADD_PROJECT_USERS, {
    refetchQueries: [
      { query: Query.PROJECT, variables: { id: projectId } },
      { query: Query.PROJECTS },
    ],
  })

  useEffect(() => {
    if (projectId && projectId !== 'new') loadProject()
  }, [projectId])

  const user = useUser()
  const isNew = projectId === 'new'
  const isLoading = projectData.loading || createProjectData.loading || updateProjectData.loading
  const project = projectData.data ? projectData.data.project : {}

  let initialValues = {
    name: '',
    description: '',
    address: { ...AddressForm.initialValues },
  }

  const addUserToProject = async (projectId) => {
    await addProjectUsers({
      variables: {
        id: projectId,
        users: [{ id: user.id, role: 'OWNER' }],
      },
    })
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={{ ...initialValues, ...project }}
      enableReinitialize
      initialTouched={false}
      onSubmit={(values) => {
        projectId === 'new'
          ? createProject({
            variables: {
              ...values,
            },
          })
          : updateProject({ variables: { id: projectId, ...values } })
      }}
    >
      {({ values, touched, setFieldValue }) => (
        <Form style={{ position: 'relative' }}>
          <Row>
            <Col md={6} sm={12}>
              <Input
                type="text"
                name="name"
                label={t('Project_info_name_label')}
                placeholder={t('Project_info_name_placeholder')}
              />
              <Input
                type="textarea"
                rows={5}
                name="description"
                label={t('Project_info_description_label')}
                placeholder={t('Project_info_description_placeholder')}
              />
              <Input
                label={t('Project_info_tender_language_label', 'Tender Language')}
                type="select"
                name="language"
                labelField="name"
                valueField="code"
                placeholder={t(
                  'Project_info_tender_language_placeholder',
                  'Select Tender Language',
                )}
                options={languages}
              />
            </Col>
            <Col md={6} sm={12}>
              <PlacePicker
                onChange={(address, e) => {
                  const {
                    city,
                    street_name,
                    street_number,
                    zip,
                    latLng: { lat, lng },
                    country,
                  } = address

                  setFieldValue('address', {
                    city,
                    street: street_name + ' ' + street_number,
                    zip,
                    lat,
                    lng,
                    country,
                  })
                }}
              />
              <AddressForm name="address" />
            </Col>
          </Row>
          <Button disabled={!touched} color="success" size="bg" type={'submit'}>
            {isNew ? t('Project_info_create_project_btn') : t('Project_info_update_project_btn')}
          </Button>
          {isLoading && <Loading />}
        </Form>
      )}
    </Formik>
  )
}

export default ProjectInfo
