import React, { useState } from 'react'
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Button,
  Row,
  Col,
} from 'reactstrap'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { Mutation, Query } from '../../api'

import Loader from '../../components/Loader'
import moment from 'moment'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string)

// TODO - translations
export const Subscription = () => {
  const { t } = useTranslation()
  const { data: user, loading } = useQuery(Query.ME)
  const [products, setProducts] = useState([])
  const [createSubscription] = useMutation(Mutation.CREATE_SUBSCRIPTION)
  const [imagesLoading, setImagesLoading] = useState(true)

  const handleClick = async (product: any) => {
    const stripe = await stripePromise

    const { data } = await createSubscription({ variables: { priceId: product.prices[0].id } })

    const result = await stripe?.redirectToCheckout({
      sessionId: data.createSubscription.checkoutSessionId,
    })
  }

  const preloadImages = async (src: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image()

      img.onload = resolve
      img.onerror = reject
      img.src = src
    })
  }

  useEffect(() => {

    ; (async () => {
      const displayOrder = ['Free (30 days)', 'Starter', 'Core', 'Pro', 'Premium']
      let { data: products }: { data: [] } = await axios.get('/stripe/products')

      let sortedProducts = []

      for (const productName of displayOrder) {
        let index = products.findIndex((product: any) => product.name === productName)
        sortedProducts.push(...products.splice(index, 1))
      }

      sortedProducts.push(...products)

      setProducts(sortedProducts)
      // @ts-ignore
      await Promise.all(products.map((product) => preloadImages(product.images[0])))
      setImagesLoading(false)
    })()
  }, [])

  if (loading || products.length === 0 || imagesLoading) return <Loader />

  const daysLeft = moment(user.me.activeSubscription.expiresAt).diff(moment(), 'day')
  const userPlan = user.me.activeSubscription.plan

  return (
    <Card>
      <Row>
        {products.map((product: any, i) => {
          const price = product.prices[0]
          const { DB_PLAN, INDIVIDUAL } = product.metadata
          const canResubscribe = daysLeft <= 0 && userPlan === DB_PLAN
          if (DB_PLAN === 'FREE') return null

          return (
            <Col sm="3" key={i}>
              <Card body>
                <CardImg src={product.images[0]} />
                <CardBody>
                  <CardTitle tag="h5">
                    {/* TODO - format currency */}
                    {product.name} {price.unit_amount !== 0 ? `- ${price.unit_amount / 100} ${price.currency.toUpperCase()}` : ''}
                  </CardTitle>

                  <CardText>{product.description.split(' -').map((row) => row.replace('- ', '') ? <p>{row.replace('- ', '')}</p> : <p>&nbsp;</p>)}</CardText>
                  {INDIVIDUAL ?
                    <Button
                      onClick={() => window.open('mailto:info@urkla.com')}
                      block
                      color={userPlan === DB_PLAN ? 'success' : 'primary'}>
                      {t('Contact_us')}
                    </Button>
                    :
                    <Button
                      block
                      // disabled={!canResubscribe}
                      color={userPlan === DB_PLAN ? 'success' : 'primary'}
                      onClick={() => handleClick(product)}
                    >
                      {canResubscribe
                        ? 'Resubscribe'
                        : userPlan === DB_PLAN
                          ? 'Active'
                          : `Buy ${product.name}`}
                    </Button>
                  }
                </CardBody>
              </Card>
            </Col>
          )
        })}
      </Row>
    </Card>
  )
}

export default Subscription
