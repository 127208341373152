import React from 'react';
import {
  CellStyle,
  CellTemplate,
  Compatible,
  getCellProperty,
  getCharFromKeyCode,
  Id,
  isAlphaNumericKey,
  isNavigationKey,
  keyCodes,
  Uncertain,
  UncertainCompatible,
  Cell,
  CellLocation,
} from '@silevis/reactgrid';
import { KeyCombinationComparer } from './KeyCombinationComparer';
import { KeyCombination } from '../types/KeyCombination';

export interface NonEditableChevronCell extends Cell {
  type: 'nonEditableChevron';
  text: string;
  isExpanded?: boolean;
  hasChildren?: boolean;
  parentId?: Id;
  indent?: number;
  expandChildren?: (location: CellLocation, recursively?: boolean) => void
  createSibling?: (parentId?: Id, rowId?: Id) => void;
  createSiblingAbove?: (parentId?: Id, rowId?: Id) => void;
  location: CellLocation;
  renderer?: (text: string) => any
}

export class NonEditableChevronCellTemplate extends KeyCombinationComparer implements CellTemplate<NonEditableChevronCell> {
  getCompatibleCell(uncertainCell: NonEditableChevronCell): Compatible<NonEditableChevronCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');

    let isExpanded = false;
    try {
      isExpanded = getCellProperty(uncertainCell, 'isExpanded', 'boolean');
    } catch {
      isExpanded = true;
    }
    let indent = -1;
    try {
      indent = getCellProperty(uncertainCell, 'indent', 'number');
    } catch {
      indent = 0;
    }
    let hasChildren = false;
    try {
      hasChildren = getCellProperty(uncertainCell, 'hasChildren', 'boolean');
    } catch {
      hasChildren = false;
    }
    const value = parseFloat(text);
    return { ...uncertainCell, text, value, isExpanded, indent, hasChildren, location: uncertainCell.location };
  }

  update(
    cell: Compatible<NonEditableChevronCell>,
    cellToMerge: UncertainCompatible<NonEditableChevronCell>
  ): Compatible<NonEditableChevronCell> {
    return this.getCompatibleCell({ ...cell, isExpanded: cellToMerge.isExpanded, text: cellToMerge.text });
  }


  getClassName(cell: Compatible<NonEditableChevronCell>, isInEditMode: boolean): string {
    const isExpanded = cell.hasChildren ? (cell.isExpanded ? 'expanded' : 'collapsed') : '';
    const className = cell.className ?? '';
    return `${isExpanded} ${className}`;
  }

  getStyle(cell: Compatible<NonEditableChevronCell>, isInEditMode: boolean): CellStyle {
    const indent = cell.indent ?? 0;
    const elementMarginMultiplier = indent * 1.4;
    return { paddingLeft: `calc(${elementMarginMultiplier}em + 2px)` };
  }

  handleKeyDown(
    cell: Compatible<NonEditableChevronCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<NonEditableChevronCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift);

    const currentCombination: KeyCombination = { keyCode, ctrl, shift, alt };

    if (this.compareExpandChildrenKeyCombination(currentCombination)) {
      cell.expandChildren?.(cell.location)
      return { cell, enableEditMode: false }
    }
    if (this.compareSiblingRowKeyCombination(currentCombination)) {
      cell.createSibling?.(cell.parentId, cell.location.rowId);
      return { cell, enableEditMode: false };
    }

    if (this.compareSiblingRowAboveKeyCombination(currentCombination)) {
      cell.createSiblingAbove?.(cell.parentId, cell.location.rowId)
      return { cell, enableEditMode: false }
    }


    if (!ctrl && !alt && isAlphaNumericKey(keyCode) && !(shift && keyCode === keyCodes.SPACE))
      cell.expandChildren?.(cell.location, false)
    return {
      cell,
      enableEditMode: false,
    };

  }

  render(
    cell: Compatible<NonEditableChevronCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<NonEditableChevronCell>, commit: boolean) => void
  ): React.ReactNode {

    return !isInEditMode ? (
      <>
        {cell.hasChildren ? (
          <>

            <div
              className="chevron"
              style={{
                transform: cell.isExpanded ? 'rotate(90deg)' : '',
                margin: '0px 5px 0px 5px',
                transition: 'all 0.2s',
              }}
              onPointerDown={(e) => {
                e.stopPropagation();
                onCellChanged(this.getCompatibleCell({ ...cell, isExpanded: !cell.isExpanded }), true);
              }}
            >
              <span className="icon">❯</span>
            </div>
          </>
        ) : (
          <div className="no-child" />
        )}

        {cell.renderer?.(cell.text) ?? cell.text}
      </>
    ) : (
      <input
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.text}
      />
    );
  }
}
