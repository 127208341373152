import { gql } from 'apollo-boost'
import * as Fragments from './fragments'

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!, $remember: Boolean) {
    signIn(email: $email, password: $password, remember: $remember)
  }
`
export const SIGN_UP = gql`
  mutation signUp($email: String!, $name: String!, $organizationName: String!, $language: String!) {
    signUp(email: $email, name: $name, organizationName: $organizationName, language: $language) {
      id
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($id: ID!, $password: String) {
    updateUserPassword(id: $id, password: $password) {
      ...UserFragment
    }
  }
  ${Fragments.UserFragment}
`

export const SEND_USER_PASSWORD_RESET_LINK = gql`
  mutation sendResetUserPasswordLink($email: String!, $language: String!) {
    sendResetUserPasswordLink(email: $email, language: $language) {
      ...UserFragment
    }
  }
  ${Fragments.UserFragment}
`

export const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($id: ID!, $password: String, $language: String!) {
    resetUserPassword(id: $id, password: $password, language: $language) {
      ...UserFragment
    }
  }
  ${Fragments.UserFragment}
`

export const CREATE_USER = gql`
  mutation createUser($email: String!, $name: String!, $language: String!, $rights: AccessRights) {
    createUser(email: $email, name: $name, language: $language, rights: $rights) {
      id
      name
      email
      rights
    }
  }
`

export const CREATE_MANY_USERS = gql`
  mutation createManyUsers($users: [Json!]!) {
    createManyUsers(users: $users) {
      id
      name
      email
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`
export const UPDATE_USER_RIGHTS = gql`
  mutation updateUserRights($id: ID!, $rights: AccessRights!) {
    updateUserRights(id: $id, rights: $rights) {
      ...UserFragment
    }
  }
  ${Fragments.UserFragment}
`

export const UPDATE_USER = gql`
  mutation updateUser($id: ID, $name: String, $email: String, $projectId: ID) {
    updateUser(id: $id, name: $name, email: $email, projectId: $projectId) {
      ...UserFragment
      currentProject {
        id
        name
      }
    }
  }
  ${Fragments.UserFragment}
`

export const UPDATE_TENDER = gql`
  mutation updateTender(
    $id: ID!
    $name: String!
    $round: Int!
    $description: String!
    $address: Json
    $packageManagerId: ID!
    $commencementDate: DateTime!
    $submissionDate: DateTime!
    $hardcopyRequired: Boolean!
    $hardcopyAddress: Json
    $bidders: [ID!]!
    $priceBreakdown: [Json!]!
    $notifyBiddersOnFiles: Boolean
  ) {
    updateTender(
      id: $id
      name: $name
      description: $description
      address: $address
      packageManagerId: $packageManagerId
      commencementDate: $commencementDate
      submissionDate: $submissionDate
      hardcopyRequired: $hardcopyRequired
      hardcopyAddress: $hardcopyAddress
      bidders: $bidders
      priceBreakdown: $priceBreakdown
      notifyBiddersOnFiles: $notifyBiddersOnFiles
    ) {
      ...TenderFragment
    }
    updatePriceBreakdown(tenderId: $id, tenderRound: $round, priceBreakdown: $priceBreakdown) {
      id
    }
  }
  ${Fragments.TenderFragment}
`

export const CREATE_TENDER = gql`
  mutation createTender(
    $name: String!
    $description: String!
    $address: Json
    $packageManagerId: ID!
    $projectId: ID!
    $commencementDate: DateTime!
    $submissionDate: DateTime!
    $hardcopyRequired: Boolean
    $hardcopyAddress: Json
    $bidders: [ID!]!
  ) {
    createTender(
      name: $name
      description: $description
      address: $address
      projectId: $projectId
      packageManagerId: $packageManagerId
      commencementDate: $commencementDate
      submissionDate: $submissionDate
      hardcopyRequired: $hardcopyRequired
      hardcopyAddress: $hardcopyAddress
      bidders: $bidders
    ) {
      ...TenderFragment
    }
  }
  ${Fragments.TenderFragment}
`

export const SET_TENDER_FILES = gql`
  mutation($id: ID!, $files: [ID!]!) {
    setTenderFiles(id: $id, files: $files) {
      ...TenderFragment
    }
  }
  ${Fragments.TenderFragment}
`

export const SET_BID_FILES = gql`
  mutation($id: ID!, $files: [ID!]!) {
    setBidFiles(id: $id, files: $files) {
      ...BidFragment
    }
  }
  ${Fragments.BidFragment}
`

export const DELETE_TENDER = gql`
  mutation deleteTender($id: ID!) {
    deleteTender(id: $id) {
      id
    }
  }
`

export const TENDER_ACTION = gql`
  mutation tenderAction($id: ID!, $action: String!, $payload: Json) {
    tenderAction(id: $id, action: $action, payload: $payload) {
      ...TenderFragment
    }
  }
  ${Fragments.TenderFragment}
`

export const TENDER_MESSAGE = gql`
  mutation tenderMessage($tenderId: ID!, $message: String!, $parentId: ID, $isPublic: Boolean) {
    tenderMessage(
      tenderId: $tenderId
      message: $message
      parentId: $parentId
      isPublic: $isPublic
    ) {
      ...LogFragment
    }
  }
  ${Fragments.LogFragment}
`
export const TENDER_BIDDER_MESSAGE = gql`
  mutation tenderBidderMessage($tenderId: ID!, $bidderId: ID!, $message: String!) {
    tenderBidderMessage(tenderId: $tenderId, bidderId: $bidderId, message: $message) {
      ...LogFragment
    }
  }
  ${Fragments.LogFragment}
`

export const CREATE_BIDDERS = gql`
  mutation createBidders($bidders: [Json!]!) {
    createBidders(bidders: $bidders) {
      id
      name
      tags
      phone
      contactName
      email
    }
  }
`

export const UPDATE_BIDDER = gql`
  mutation updateBidder(
    $id: ID!
    $name: String
    $contactName: String
    $email: String
    $tags: Json
    $phone: String!
    $address: Json
  ) {
    updateBidder(
      id: $id
      name: $name
      contactName: $contactName
      email: $email
      tags: $tags
      phone: $phone
      address: $address
    ) {
      id
      name
      contactName
      email
      tags
      phone
      address
    }
  }
`

export const DELETE_BIDDERS = gql`
  mutation deleteBidders($bidders: [ID!]!) {
    deleteBidders(bidders: $bidders) {
      id
      name
      tags
      phone
      contactName
      email
    }
  }
`

export const CREATE_FILE = gql`
  mutation($input: CreateFileInput!) {
    createFile(input: $input) {
      file {
        id
      }
      uploadUrl
    }
  }
`
export const SET_FILE_UPLOADED = gql`
  mutation($ids: [ID!]!) {
    setFileUploaded(ids: $ids) {
      ...FileFragment
    }
  }
  ${Fragments.FileFragment}
`

// export const UPLOAD_FILE = gql`
//   mutation uploadFile($file: Upload!, $size: Int!, $directory: String!) {
//     uploadFile(file: $file, size: $size, directory: $directory) {
//       ...FileFragment
//     }
//   }
//   ${Fragments.FileFragment}
// `

export const DELETE_FILE = gql`
  mutation deleteFile($ids: [ID!]!) {
    deleteFile(ids: $ids) {
      id
    }
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($id: ID!, $name: String, $description: String, $address: Json) {
    updateOrganization(id: $id, name: $name, description: $description, address: $address) {
      id
      name
      description
      address
    }
  }
`

export const CREATE_PROJECT = gql`
  mutation createProject($name: String!, $description: String, $language: String, $address: Json) {
    createProject(name: $name, description: $description, language: $language, address: $address) {
      id
      name
      language
      description
      address
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $id: ID!
    $name: String!
    $description: String
    $language: String
    $address: Json
  ) {
    updateProject(
      id: $id
      name: $name
      description: $description
      language: $language
      address: $address
    ) {
      id
      name
      language
      description
      address
    }
  }
`

export const UPDATE_TENDER_NOTES = gql`
  mutation($id: ID!, $notes: Json) {
    updateTenderNotes(id: $id, notes: $notes) {
      id
      notes
    }
  }
`

export const DELETE_PROJECT = gql`
  mutation deleteProject($userId: ID!, $projectId: ID!) {
    deleteProject(userId: $userId, projectId: $projectId) {
      id
    }
  }
`

export const ADD_PROJECT_USERS = gql`
  mutation addProjectUsers($id: ID!, $users: [Json!]!) {
    addProjectUsers(id: $id, users: $users) {
      id
      users {
        id
        role
        user {
          id
          name
          email
        }
      }
    }
  }
`

export const REMOVE_PROJECT_USERS = gql`
  mutation removeProjectUsers($id: ID!, $users: [ID!]!) {
    removeProjectUsers(id: $id, users: $users) {
      id
      users {
        id
        role
        user {
          id
          name
          email
        }
      }
    }
  }
`

export const UPDATE_USER_PROJECT_ROLE = gql`
  mutation updateUserProjectRole($id: ID!, $role: Role!) {
    updateUserProjectRole(id: $id, role: $role) {
      id
      role
      user {
        id
        name
        email
      }
    }
  }
`

export const NOTIFY_BIDDER_TENDER_RUNNING = gql`
  mutation($tenderId: ID!, $bidderId: ID!) {
    notifyBidderRunningTender(tenderId: $tenderId, bidderId: $bidderId)
  }
`

export const BIDDER_SUBMIT_BID = gql`
  mutation submitBid($data: Json!, $totalPrice: Int) {
    submitBid(data: $data, totalPrice: $totalPrice) {
      id
    }
  }
`

export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($priceId: String!) {
    createSubscription(priceId: $priceId) {
      checkoutSessionId
    }
  }
`
