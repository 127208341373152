import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button } from 'reactstrap'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import Grid from '../../../components/grid'
import Loader from '../../../components/Loading'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Query, Mutation } from '../../../api'
import { useRouter, useUser } from '../../../hooks'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()
  const user = useUser()
  const { data, loading, error } = useQuery(Query.ORGANIZATION)
  const [deleteProject, deleteProjectData] = useMutation(Mutation.DELETE_PROJECT)
  const { match, history } = useRouter()
  const {
    organization: { projects },
  } = data || { organization: { projects: [] } }

  const handleDelete = ({ id }) => {
    deleteProject({
      variables: { userId: user.id, projectId: id },
      refetchQueries: [{ query: Query.ORGANIZATION }],
    })
  }

  const isLoading = loading || deleteProjectData.loading

  if (error) return <Alert>{error.message}</Alert>

  return (
    <>
      <Grid
        actionBar={{
          actionButton: (
            <Button
              color="primary"
              className="btn-pill"
              size="lg"
              onClick={() => history.push(`${match.url}/projects/new`)}
            >
              <Icon icon={faFolderPlus} /> {t('Organization_projects_new_project_btn')}
            </Button>
          ),
        }}
        formatters={[
          {
            formatter: ({ value, row }) => (
              <Link className="text-dark" to={`${match.url}/projects/${row.id}`}>
                {value}
              </Link>
            ),
            for: ['name'],
          },
        ]}
        rows={projects}
        rowActions={[
          {
            title: t('Organization_projects_delete_project_btn'),
            color: 'danger',
            action: handleDelete,
          },
        ]}
        columns={[{ name: 'name', title: t('Organization_projects_name_label') }]}
      />
      {isLoading && <Loader />}
    </>
  )
}
