import React from 'react'
import { connect } from 'react-redux'
import { toggleSidebar } from '../redux/actions/sidebarActions'
// import { useQuery } from '@apollo/react-hooks'
// import { Query } from '../api'
import { Link } from 'react-router-dom'
import md5 from 'md5'
import { useRouter, useUser } from '../hooks'
import ProjectSelector from './ProjectSelector'
import { useTranslation } from 'react-i18next'

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Badge,
} from 'reactstrap'
import styled from 'styled-components'

import {
  // AlertCircle,
  // Bell,
  // BellOff,
  // Home,
  // MessageCircle,
  // PieChart,
  // Settings,
  User,
  // User,
  // UserPlus,
} from 'react-feather'
import { capitalize } from 'lodash'

// import usFlag from '../assets/img/flags/us.png'
// import esFlag from '../assets/img/flags/es.png'
// import deFlag from '../assets/img/flags/de.png'
// import nlFlag from '../assets/img/flags/nl.png'

// import avatar1 from '../assets/img/avatars/avatar.jpg'
// import avatar3 from '../assets/img/avatars/avatar-3.jpg'
// import avatar4 from '../assets/img/avatars/avatar-4.jpg'
// import avatar5 from '../assets/img/avatars/avatar-5.jpg'

// const notifications = [
//   {
//     type: 'important',
//     title: 'Update completed',
//     description: 'Restart server 12 to complete the update.',
//     time: '2h ago',
//   },
//   {
//     type: 'default',
//     title: 'Lorem ipsum',
//     description: 'Aliquam ex eros, imperdiet vulputate hendrerit et.',
//     time: '6h ago',
//   },
//   {
//     type: 'login',
//     title: 'Login from 192.186.1.1',
//     description: '',
//     time: '6h ago',
//   },
//   {
//     type: 'request',
//     title: 'New connection',
//     description: 'Anna accepted your request.',
//     time: '12h ago',
//   },
// ]

// const messages = [
//   {
//     name: 'Ashley Briggs',
//     avatar: avatar5,
//     description: 'Nam pretium turpis et arcu. Duis arcu tortor.',
//     time: '15m ago',
//   },
//   {
//     name: 'Chris Wood',
//     avatar: avatar1,
//     description: 'Curabitur ligula sapien euismod vitae.',
//     time: '2h ago',
//   },
//   {
//     name: 'Stacie Hall',
//     avatar: avatar4,
//     description: 'Pellentesque auctor neque nec urna.',
//     time: '4h ago',
//   },
//   {
//     name: 'Bertha Martin',
//     avatar: avatar3,
//     description: 'Aenean tellus metus, bibendum sed, posuere ac, mattis non.',
//     time: '5h ago',
//   },
// ]

// const NavbarDropdown = ({ children, count, showBadge, header, footer, icon: Icon }) => (
//   <UncontrolledDropdown nav inNavbar className="mr-2">
//     <DropdownToggle nav className="nav-icon dropdown-toggle">
//       <div className="position-relative">
//         <Icon className="align-middle" size={18} />
//         {showBadge ? <span className="indicator">{count}</span> : null}
//       </div>
//     </DropdownToggle>
//     <DropdownMenu right className="dropdown-menu-lg py-0">
//       <div className="dropdown-menu-header position-relative">
//         {count} {header}
//       </div>
//       <ListGroup>{children}</ListGroup>
//       <DropdownItem header className="dropdown-menu-footer">
//         <span className="text-muted">{footer}</span>
//       </DropdownItem>
//     </DropdownMenu>
//   </UncontrolledDropdown>
// )

// const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
//   <ListGroupItem>
//     <Row noGutters className="align-items-center">
//       <Col xs={2}>{icon}</Col>
//       <Col xs={10} className={spacing ? 'pl-2' : null}>
//         <div className="text-dark">{title}</div>
//         <div className="text-muted small mt-1">{description}</div>
//         <div className="text-muted small mt-1">{time}</div>
//       </Col>
//     </Row>
//   </ListGroupItem>
// )


const StyledDropdownMenu = styled(DropdownMenu)`
  & > .dropdown-item.active {
    background-color: transparent;
    font-weight: bolder;
  }
`

const NavbarComponent = ({ dispatch, activeSubscription }) => {
  const { t } = useTranslation()
  const user = useUser()
  const avatarImg = `https://www.gravatar.com/avatar/${md5(user.email)}?d=mp`
  const router = useRouter()

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          dispatch(toggleSidebar())
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Form inline>
        {/* <Input
          type="text"
          placeholder="Search projects..."
          aria-label="Search"
          className="form-control-no-border mr-sm-2"
        /> */}
        <ProjectSelector />
      </Form>

      <Collapse navbar>
        {user && (
          <span>
            <Badge color="primary">{capitalize(activeSubscription.plan.toLowerCase())}</Badge>
          </span>
        )}
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <User size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <img
                  src={avatarImg}
                  className="avatar img-fluid rounded-circle mr-1"
                  alt="Chris Wood"
                />
                <span className="text-dark">{user.name || user.email} </span>
              </DropdownToggle>
            </span>
            <StyledDropdownMenu right>
              {/* <DropdownItem>
                <User size={18} className="align-middle mr-2" />
                Profile
              </DropdownItem>
              <DropdownItem>
                <PieChart size={18} className="align-middle mr-2" />
                Analytics
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Settings & Privacy</DropdownItem>
              <DropdownItem>Help</DropdownItem> */}
              <DropdownItem active={router.location.pathname.startsWith('/payment/subscriptions')}>
                <Link to="/payment/subscriptions">{t('Route_payment')}</Link>
              </DropdownItem>
              <DropdownItem>

                <Link to="/auth/signout">{t('Header_Sign_Out_btn')}</Link>
              </DropdownItem>
            </StyledDropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default connect((store) => ({ app: store.app }))(NavbarComponent)
