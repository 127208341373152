import * as React from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'clsx'

const EditCell = ({
  column,
  value,
  onValueChange,
  className,
  children,
  row,
  tableRow,
  tableColumn,
  editingEnabled,
  autoFocus,
  onBlur,
  onFocus,
  onKeyDown,
  ...restProps
}) => {
  const patchedChildren = children
    ? React.cloneElement(children, {
        autoFocus,
        onBlur,
        onFocus,
        onKeyDown,
      })
    : children

  const selectProps = {
    className: classNames({
      'form-control w-100': true,
      'text-right': tableColumn && tableColumn.align === 'right',
      'text-center': tableColumn && tableColumn.align === 'center',
    }),
    readOnly: !editingEnabled,
    value: value || '',
    onChange: (e) => onValueChange(e.target.value),

    autoFocus: autoFocus,
    onBlur: onBlur,
    onKeyDown: onKeyDown,
  }
  const inputProps = {
    ...selectProps,
    onFocus,
  }

  return (
    <td className={classNames('align-middle dx-g-bs4-table-edit-cell', className)} {...restProps}>
      {patchedChildren || (
        <>
          {column.enum ? (
            <select {...selectProps}>
              {column.enum.map((i) => (
                <option key={i}>{i}</option>
              ))}
            </select>
          ) : (
            <input {...inputProps} />
          )}
        </>
      )}
    </td>
  )
}
EditCell.propTypes = {
  column: PropTypes.object,
  row: PropTypes.any,
  tableColumn: PropTypes.object,
  tableRow: PropTypes.object,
  value: PropTypes.any,
  onValueChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  editingEnabled: PropTypes.bool,
  children: PropTypes.node,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
}
EditCell.defaultProps = {
  column: undefined,
  row: undefined,
  tableColumn: undefined,
  tableRow: undefined,
  className: undefined,
  children: undefined,
  editingEnabled: true,
  value: '',
  autoFocus: false,
  onBlur: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
}

export default EditCell
