import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useGeolocation } from 'react-use'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'

import LocationPicker from 'react-location-picker'

const StyledButton = styled(Button)`
  z-index: 0 !important;
`

const PlaceSelect = ({ onChange, defaultChosenPosition, addrText, readOnly = false }) => {
  const { t } = useTranslation()

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const [positionSelected, setPositionSelected] = useState(false)
  const [automaticPositionInitialized, setAutomaticPositionInitialized] = useState(false)
  const [automaticPositionLoaded, setAutomaticPositionLoaded] = useState(false)

  const automaticPosition = useGeolocation()

  const [position, setPosition] = useState(() => {
    const { latitude, longitude } = automaticPosition

    return defaultChosenPosition.longitude === null && defaultChosenPosition.latitude === null
      ? {
          address: defaultChosenPosition.address === null ? null : defaultChosenPosition.address,
          lat: latitude !== null ? latitude : 0,
          lng: longitude !== null ? longitude : 0,
        }
      : { ...defaultChosenPosition }
  })

  useEffect(() => {
    if (!automaticPosition.loading && !automaticPositionInitialized) {
      setAutomaticPositionInitialized(true)
    }
  }, [automaticPosition.loading])

  useEffect(() => {
    let coords = { latitude: 0, longitude: 0 }
    if (automaticPositionInitialized && !automaticPositionLoaded) {
      coords = automaticPosition
      setAutomaticPositionLoaded(true)
    }

    let pos =
      defaultChosenPosition.longitude === null && defaultChosenPosition.latitude === null
        ? {
            address: defaultChosenPosition.address === null ? null : defaultChosenPosition.address,
            lat: coords.latitude !== null ? coords.latitude : 0,
            lng: coords.longitude !== null ? coords.longitude : 0,
          }
        : { ...defaultChosenPosition }

    setPosition(pos)
  }, [defaultChosenPosition, automaticPositionInitialized])

  const handleLocationChange = (loc) => {
    let newPosition = loc.position
    newPosition.address = loc.address

    setPosition(newPosition)
    setPositionSelected(true)
  }

  const onSelectBtnClick = () => {
    toggle()
    setPositionSelected(false)
    onChange(position)
  }

  return (
    <>
      <StyledButton disabled={readOnly} onClick={!readOnly && toggle}>
        <FontAwesomeIcon icon={faMapMarkedAlt} />
      </StyledButton>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{position.address}</ModalHeader>
        <ModalBody>
          <LocationPicker
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '400px' }} />}
            defaultPosition={position}
            onChange={handleLocationChange}
            radius={-1}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={!positionSelected} onClick={onSelectBtnClick}>
            {t('Place_select_modal_confirm_place_btn')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default PlaceSelect
