import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useField, useFormikContext } from 'formik'
import { Input, Label, FormGroup, FormFeedback } from 'reactstrap'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { SelectStyles } from './Select'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'
import cn from 'classnames'

import { useTranslation } from 'react-i18next'
import i18n from './../../i18n'
import * as lngs from 'date-fns/locale'
import styled from 'styled-components'

import debounce from 'lodash/debounce'

const HiddenDiv = styled.div`
  display: none;
`

const InputField = ({ label, bsSize, debounceTime = 500, ...props }) => {
  const [field, meta] = useField(props)



  const [value, setValue] = useState(field.value)

  const debChange = useMemo(() => debounce(field.onChange, debounceTime), [field.onChange])

  useEffect(() => {
    setValue(field.value)
  }, [field.value])

  return (
    <FormGroup>
      <Label>{label}</Label>
      <Input
        {...field}
        onChange={(e) => {
          e.persist()
          setValue(e.target.value)
          debChange(e)
        }}
        value={value}
        {...props}
        bsSize={bsSize || 'lg'}
        invalid={!!meta.error && meta.touched}
      />
      {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
    </FormGroup>
  )
}

const DateTimeInput = ({ type, label, bsSize, onChange = null, ...props }) => {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(props)

  return (
    <FormGroup>
      <Label>{label}</Label>
      <DatePicker
        onBlur={field.onBlur}
        {...props}
        onChange={(date) => {
          setFieldValue(field.name, date ? date.toISOString() : new Date())
          if (onChange) onChange(date)
        }}
        showTimeSelect
        dateFormat="yyyy/MM/dd HH:mm"
        selected={field.value ? new Date(field.value) : null}
        className={cn(
          'form-control',
          `${meta.error && meta.touched && 'is-invalid'}`,
          `form-control-${bsSize || 'lg'}`,
        )}
        locale={lngs[i18n.language]}
        timeCaption={t('datepicker_time')}
      />
      {meta.error && meta.touched && (
        <>
          <HiddenDiv className="form-control is-invalid" />
          <FormFeedback>{meta.error}</FormFeedback>
        </>
      )}
    </FormGroup>
  )
}

const CheckboxField = ({ label, readOnly, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <FormGroup check>
      <Label check>
        <Input
          {...field}
          {...props}
          disabled={readOnly}
          checked={field.value}
          invalid={!!meta.error && meta.touched}
        />
        {label}
      </Label>
      {meta.error && meta.touched && (
        <>
          <HiddenDiv className="form-control is-invalid" />
          <FormFeedback>{meta.error}</FormFeedback>
        </>
      )}
    </FormGroup>
  )
}

const DropdownField = ({ label, type, options, labelField, valueField, readOnly, ...props }) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()
  const isInvalid = !!meta.error && meta.touched

  return (
    <FormGroup>
      <Label>{label}</Label>
      <Select
        {...field}
        {...props}
        options={options}
        placeholder={t('dropdownfield_select_placeholder', 'Select...')}
        onChange={(o) => {
          setFieldValue(field.name, o[valueField])
        }}
        isDisabled={readOnly}
        className={cn({ 'is-invalid': isInvalid }, 'form-control-select')}
        getOptionLabel={(o) => o[labelField]}
        getOptionValue={(o) => o[valueField]}
        value={options.find((o) => o[valueField] === field.value)}
        {...SelectStyles({ isInvalid })}
      />

      {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
    </FormGroup>
  )
}
DropdownField.defaultProps = {
  valueField: 'value',
  labelField: 'label',
}

const TYPES = {
  radio: CheckboxField,
  checkbox: CheckboxField,
  text: InputField,
  number: InputField,
  date: DateTimeInput,
  datetime: DateTimeInput,
  select: DropdownField,
}

export default (props) => {
  const Field = TYPES[props.type] || InputField

  return <Field {...props} />
}
