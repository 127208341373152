import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  TreeDataState,
  SortingState,
  CustomTreeData,
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  DataTypeProvider,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFixedColumns,
  TableTreeColumn,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4'

import { useTranslation } from 'react-i18next'
import { currency, unitPrice } from '../TenderSummary'
import styled from 'styled-components'
import SortLabel from '../../../../components/grid/components/SortLabel'
import { Button } from 'reactstrap'
import Note from './Note'
import TenderNotes from './TenderNotes'
import FixedHead from './FixedHead'
import debounce from 'lodash/debounce'

const SummaryGrid = ({
  data,
  search,
  bids,
  showUnitPrices,
  showAdjustedPrices,
  showThermometer,
  tenderId,
  itemsLevel,
}) => {
  const { t } = useTranslation()

  const getChildRows = (row, rootRows) => {
    return row ? row.children : rootRows
  }
  const getRowId = (row) => row.ID

  const columnsLeft = [
    { name: 'Name', title: t('tender_summary_col_name') },
    { name: 'Auto' },
    {
      name: 'Unit',
      title: t('tender_summary_col_units'),
    },
    {
      name: 'Acreage',
      title: t('tender_summary_col_quantity'),
    },
  ]

  const columnsRight = [
    { name: showUnitPrices ? 'UnitAvg' : 'Avg', title: t('tender_summary_col_average_price') },
  ]

  const [columns, setColumns] = useState(() => {
    let pricesColumns = []
    bids.forEach((bid) => {
      let column = {
        name: showUnitPrices ? 'UnitPrice_' + bid.id : 'Price_' + bid.id,
        title: bid.bidder.name,
      }
      pricesColumns = [...pricesColumns, column]
    })
    return [...columnsLeft, ...pricesColumns, ...columnsRight]
  })

  const [allRowIds] = useState(() => data.model.children.map((row) => getRowId(row)))
  const [expandedRowIds, setExpandedRowIds] = useState([])
  const [sorting, setSorting] = useState()

  const [tableColumnExtensions] = useState(() => {
    let columns = [
      { columnName: 'Name', width: 450 },
      { columnName: 'Auto', width: 'auto' }, //
      { columnName: 'Unit', width: 80 },
      { columnName: 'Acreage', width: 80 },
      { columnName: 'Avg', width: 120 },
      { columnName: 'UnitAvg', width: 120 },
    ]
    bids.forEach((bid) => {
      columns = [
        ...columns,
        { columnName: 'Price_' + bid.id, width: 120 },
        { columnName: 'UnitPrice_' + bid.id, width: 120 },
      ]
    })
    return columns
  })

  /*const createTotalRow = () => {
    let row = {}
    let pricesSum = 0
    bids.forEach((b) => {
      row['Price_' + b.id] = b.totalPrice
      row['UnitPrice_' + b.id] = null
      pricesSum += b.totalPrice
    })
    row['Avg'] = pricesSum / bids.length
    row['Name'] = t('tender_summary_total', 'Total:')
    row['background'] = '#dddddd'
    row['isTotalRow'] = true
    return row
  }*/

  const onExpandAllBtnClick = () => {
    expandedRowIds.length > 0 ? setExpandedRowIds([]) : setExpandedRowIds(allRowIds)
  }

  const [tableScrollX, setTableScrollX] = useState(0)
  const tableRef = useRef()

  const onScroll = () => {
    setTableScrollX(tableRef.current.querySelector('.table-responsive').scrollLeft)
  }

  const onScrollDebounced = useCallback(debounce(onScroll, 50), [])

  useEffect(() => {
    tableRef.current
      .querySelector('.table-responsive')
      .addEventListener('scroll', onScrollDebounced)
  })

  const scrollXTo = (value) => {
    tableRef.current.querySelector('.table-responsive').scrollLeft = value
  }

  useEffect(() => {
    let pricesColumns = []
    bids.forEach((bid) => {
      let column = {
        name: showUnitPrices ? 'UnitPrice_' + bid.id : 'Price_' + bid.id,
        title: bid.bidder.name,
      }
      pricesColumns = [...pricesColumns, column]
    })
    setColumns([...columnsLeft, ...pricesColumns, ...columnsRight])
  }, [bids])

  return (
    <>
      <FixedHead
        headScrollXOnChange={(x) => {
          scrollXTo(x)
        }}
        onExpandAllBtnClick={onExpandAllBtnClick}
        onSortingChange={(val) => {
          setSorting(val)
        }}
        expandedRowIds={expandedRowIds}
        tableScrollX={tableScrollX}
        data={data}
        search={search}
        bids={bids}
        showUnitPrices={showUnitPrices}
        showAdjustedPrices={showAdjustedPrices}
        showThermometer={showThermometer}
        tenderId={tenderId}
        itemsLevel={itemsLevel}
      />
      <TenderNotes id={tenderId}>
        <TableWrapper ref={tableRef} id="SummaryTable">
          <Grid
            rows={data.model.children}
            columns={columns}
            getRowId={getRowId}
            searchValue={search}
            rootComponent={Root}
          >
            <AcreageTypeProvider for={['Acreage']} />
            {search && <SearchState value={search} />}
            {search && <IntegratedFiltering />}

            <TreeDataState
              onExpandedRowIdsChange={setExpandedRowIds}
              expandedRowIds={expandedRowIds}
            />
            <SortingState sorting={sorting} />
            <CustomTreeData getChildRows={getChildRows} />
            <IntegratedSorting />

            <Table
              rowComponent={TableRow}
              cellComponent={(props) => (
                <TableCell
                  {...{
                    props,
                    showThermometer,
                    showAdjustedPrices,
                  }}
                />
              )}
              columnExtensions={tableColumnExtensions}
            />
            {/* <VirtualTable rowComponent={TableRow} cellComponent={TableCell} /> */}

            {/* <TableHeaderRow
              showSortingControls
              sortLabelComponent={SortLabel}
              cellComponent={TableHeaderRowCell}
            /> */}

            <TableTreeColumn
              for="Name"
              cellComponent={(props) => (
                <TableCell
                  {...{
                    props,
                    showThermometer,
                    showAdjustedPrices,
                  }}
                />
              )}
              rowComponent={TableRow}
            />

            <TableFixedColumns
              leftColumns={['Name', 'Unit', 'Acreage', 'Auto']}
              rightColumns={['Avg', 'UnitAvg']}
              cellComponent={TableFixedCell}
            />
          </Grid>
        </TableWrapper>
      </TenderNotes>
    </>
  )
}

export default SummaryGrid

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} />

const TableRow = ({ row, ...restProps }) => (
  <CustomTableRow {...restProps} background={row.background ? row.background : '#fff'} />
)

const TableCell = ({ props, showThermometer, showAdjustedPrices }) => {
  const isPriceColumn = props.column.name.startsWith('Price_')
  const isUnitPriceColumn = props.column.name.startsWith('UnitPrice_')

  const currentCellID = isPriceColumn
    ? props.row.ID + '__' + props.column.name.substr(6)
    : isUnitPriceColumn
    ? props.row.ID + '__' + props.column.name.substr(10)
    : ''

  if (props.column.name === 'Name') return <TableTreeColumn.Cell {...props} />
  if (props.row.children && (isUnitPriceColumn || props.column.name === 'UnitAvg')) {
    if (isUnitPriceColumn)
      return (
        <TableCellWithNoteWrapper>
          <TableCellStyled
            {...{
              ...props,
              style: {
                position: 'absolute',
                width: '100%',
              },
            }}
          />
          <Note showUnderRow={false /*props.row.Name.startsWith('01: ')*/} id={currentCellID} />
        </TableCellWithNoteWrapper>
      )

    if (props.column.name === 'UnitAvg') return <TableCellStyled {...props} />
  }
  if (isPriceColumn) {
    const percentage = showThermometer && props.value / (props.row.HighestPrice / 100)
    const isHighestPrice =
      props.row.HighestPrice !== props.row.LowestPrice && props.row.HighestPrice === props.value

    const isLowestPrice =
      props.row.HighestPrice !== props.row.LowestPrice && props.row.LowestPrice === props.value

    if (props.value === null) {
      return (
        <TableCellWithNoteWrapper>
          <TableCellStyled
            {...{
              ...props,
              value: showAdjustedPrices ? currency(props.row.Avg) : 'x',

              style: {
                color: showAdjustedPrices ? 'blue' : 'red',
                textAlign: 'right',
                position: 'absolute',
                width: '100%',
              },
            }}
          />
          <Note id={currentCellID} />
        </TableCellWithNoteWrapper>
      )
    }

    if (props.row.Avg * 0.8 > props.value)
      return (
        <TableCellWithNoteWrapper>
          <TableCellStyled
            {...{
              ...props,
              value: currency(props.value),
              isHighestPrice,
              isLowestPrice,
              percentage,
              style: {
                color: '#5fc27e',
                textAlign: 'right',
                position: 'absolute',
                width: '100%',
              },
            }}
          />
          <Note id={currentCellID} />
        </TableCellWithNoteWrapper>
      )
    if (props.row.Avg * 1.2 < props.value)
      return (
        <TableCellWithNoteWrapper>
          <TableCellStyled
            {...{
              ...props,
              value: currency(props.value),
              isHighestPrice,
              isLowestPrice,
              percentage,
              style: {
                color: '#f44455',
                textAlign: 'right',
                position: 'absolute',
                width: '100%',
              },
            }}
          />
          <Note id={currentCellID} />
        </TableCellWithNoteWrapper>
      )

    if (!props.row.isTotalRow) {
      return (
        <TableCellWithNoteWrapper>
          <TableCellStyled
            {...{
              ...props,
              value: currency(props.value),
              isHighestPrice,
              isLowestPrice,
              percentage,
              style: {
                textAlign: 'right',
                position: 'absolute',
                width: '100%',
              },
            }}
          />
          <Note id={currentCellID} />
        </TableCellWithNoteWrapper>
      )
    } else {
      return (
        <TableCellStyled
          {...{
            ...props,
            value: currency(props.value),
            style: {
              textAlign: 'right',
            },
          }}
        />
      )
    }
  } else if (isUnitPriceColumn) {
    const percentage = showThermometer && props.value / (props.row.HighestUnitPrice / 100)

    const isHighestPrice =
      props.row.HighestUnitPrice !== props.row.LowestUnitPrice &&
      props.row.HighestUnitPrice === props.value

    const isLowestPrice =
      props.row.HighestUnitPrice !== props.row.LowestUnitPrice &&
      props.row.LowestUnitPrice === props.value

    if (props.value === null) {
      return (
        <TableCellWithNoteWrapper>
          <TableCellStyled
            {...{
              ...props,
              percentage,
              value: showAdjustedPrices ? unitPrice(props.row.UnitAvg) : 'x',
              style: {
                color: showAdjustedPrices ? 'blue' : 'red',
                textAlign: 'right',
                position: 'absolute',
                width: '100%',
              },
            }}
          />
          <Note id={currentCellID} />
        </TableCellWithNoteWrapper>
      )
    }

    if (props.row.UnitAvg * 0.8 > props.value)
      return (
        <TableCellWithNoteWrapper>
          <TableCellStyled
            {...{
              ...props,
              value: unitPrice(props.value),
              isHighestPrice,
              isLowestPrice,
              percentage,
              style: { color: '#5fc27e', textAlign: 'right', position: 'absolute', width: '100%' },
            }}
          />

          <Note id={currentCellID} />
        </TableCellWithNoteWrapper>
      )

    if (props.row.UnitAvg * 1.2 < props.value)
      return (
        <TableCellWithNoteWrapper>
          <TableCellStyled
            {...{
              ...props,
              value: unitPrice(props.value),
              isHighestPrice,
              isLowestPrice,
              percentage,
              style: { color: '#f44455', textAlign: 'right', position: 'absolute', width: '100%' },
            }}
          />

          <Note id={currentCellID} />
        </TableCellWithNoteWrapper>
      )

    return (
      <TableCellWithNoteWrapper>
        <TableCellStyled
          {...{
            ...props,
            value: unitPrice(props.value),
            isHighestPrice,
            isLowestPrice,
            percentage,
            style: { textAlign: 'right', position: 'absolute', width: '100%' },
          }}
        />

        <Note id={currentCellID} />
      </TableCellWithNoteWrapper>
    )
  }

  if (props.column.name === 'Avg') {
    return <TableCellStyled {...{ ...props, value: currency(props.value) }} />
  }

  if (props.column.name === 'UnitAvg')
    return <TableCellStyled {...{ ...props, value: unitPrice(props.value) }} />

  return <TableCellStyled {...props} />
}

const TableFixedCell = (props) => {
  return (
    <TableFixedColumns.Cell
      {...props}
      style={{
        backgroundColor:
          props.tableRow.row && props.tableRow.row.background
            ? props.tableRow.row.background
            : '#fff',

        textAlign: ['Avg', 'UnitAvg', 'Acreage'].includes(props.tableColumn.column.name)
          ? 'right'
          : 'left',
      }}
    />
  )
}

const AcreageFormatter = ({ value }) => <>{value && parseFloat(value).toFixed(2)}</>
const AcreageTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={AcreageFormatter} {...props} />
)

const CustomTableRow = styled(Table.Row)`
  background-color: ${(props) => props.background};
`

const TableWrapper = styled.div`
  height: auto;
  display: block !important;

  .table {
    background-color: #fff !important;
    z-index: 20 !important;
  }
  .table th {
    border-top: none;
  }

  .table-responsive {
    padding-top: 90px;
    margin-top: -90px;
  }
`

const TableCellStyled = styled(Table.Cell)`
  background: ${({ percentage }) => {
    if (percentage)
      return (
        'linear-gradient(90deg, rgba(242, 164, 58, .2), rgba(242, 164, 58, 1), rgba(244, 67, 85, 1) ' +
        percentage +
        '%, transparent 0)'
      )
    return 'initial'
  }};

  background-position: left bottom, 0 0;
  background-repeat: no-repeat;
  background-size: 100% 4px;
  cursor: default !important;

  &:before {
    display: ${(props) => (props.isHighestPrice || props.isLowestPrice ? 'block' : 'none')};
    content: '';
    width: 10px;
    height: 10px;
    margin-left: -0.35rem;
    margin-top: -0.35rem;
    border-radius: 5px;
    background: ${(props) => {
      if (props.isHighestPrice) return '#f44455 !important'
      if (props.isLowestPrice) return '#5fc27e !important'
      return 'trasparent'
    }};
    position: absolute;
  }
`

const TableCellWithNoteWrapper = styled.div`
  position: relative;
  display: table-cell;
  &:hover {
    .add-note-btn {
      visibility: visible;
    }
  }
`
