import React, { useMemo, useRef, useEffect } from 'react'
import { Media, Button, Spinner } from 'reactstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import md5 from 'md5'
import { Mutation, Query } from '../../../api'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useUser } from '../../../hooks'
import styled from 'styled-components'
import { useKeyPressEvent } from 'react-use'

const LogItem = ({ log, children }) => {
  const { t } = useTranslation()

  const sender = log.bidder ? (log.mine ? log.bidder : { email: '', name: 'Anonymous' }) : log.user

  return (
    <Media>
      <img
        src={`https://www.gravatar.com/avatar/${md5(sender.email)}?d=mp`}
        width="36"
        height="36"
        className="rounded-circle mr-2 mt-2"
      />
      <Media body className="mb-2">
        <small className="float-right text-navy">
          {t('tender_timeline_date_from_now', { date: new moment(log.createdAt) })}
        </small>
        <strong>{sender.name}</strong>
        <div className="bg-white border rounded shadow p-2">{log.message}</div>
        <div>
          <small className="text-muted">
            {t('formatter_dateTime', { date: new moment(log.createdAt) })}
          </small>
        </div>
        {log.children &&
          log.children.map((log, i) => {
            return (
              <LogItem log={log}>
                <div className="bg-white border rounded shadow p-2">{log.message}</div>
              </LogItem>
            )
          })}
      </Media>
    </Media>
  )
}

const SpinnerButton = styled(Spinner).attrs(() => ({
  size: 'sm',
}))`
  vertical-align: middle;
`

export default ({ tender }) => {
  const { t } = useTranslation()
  const user = useUser()
  // const logs = useMemo(() => tender.logs.filter((l) => l.type === 'MESSAGE'), [tender])
  const messageInputRef = useRef()

  const { data, loading } = useQuery(Query.BIDDER_TENDER_MESSAGES, { variables: { id: tender.id } })
  const [sendMessage, sendMessageData] = useMutation(Mutation.TENDER_BIDDER_MESSAGE, {
    variables: { tenderId: user.tenderId, bidderId: user.id },
    refetchQueries: [{ query: Query.BIDDER_TENDER_MESSAGES, variables: { id: tender.id } }],
  })

  const handleSend = async () => {
    await sendMessage({ variables: { message: messageInputRef.current.value } })
    messageInputRef.current.value = ''
  }

  const logs = data ? data.bidderTenderMessages : []

  return (
    <div>
      <div className="mb-3 input-group">
        <input
          type="text"
          ref={messageInputRef}
          disabled={sendMessageData.loading}
          className="form-control form-control-lg"
          onKeyDown={({ keyCode }) => keyCode === 13 && handleSend()}
          placeholder={t('Tender_timeline_write_a_message')}
        />
        <div className="input-group-append">
          <Button
            size="lg"
            color="outline-primary"
            disabled={sendMessageData.loading}
            onClick={handleSend}
          >
            {t('Tender_timeline_send_btn')} {sendMessageData.loading && <SpinnerButton />}
          </Button>
        </div>
      </div>
      {logs.map((l, i) => (
        <LogItem key={i} log={l} />
      ))}
    </div>
  )
}
