import React, { useState } from 'react'
import { InputGroup, InputGroupAddon, Input, FormGroup, ListGroup, ListGroupItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import PlacesAutocomplete from 'react-places-autocomplete'
import PlaceSelect from './PlaceSelect'
import { PlaceParser } from './PlaceParser'
import styled from 'styled-components'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import propTypes from 'prop-types'

const AutocompleteDropdown = styled(ListGroup)`
  position: absolute;
  z-index: 99;
  top: 45px;
  width: 100%;
`
const AutocompleteDropdownItem = styled(ListGroupItem)``

const PlacePicker = ({ onChange, readOnly = false, initialValue }) => {
  const { t } = useTranslation()
  const [addressData, setAddressData] = useState(initialValue)
  const [defaultPosition, setDefaultPosition] = useState({
    latitude: null,
    longitude: null,
    address: t('Place_picker_select_place_by_dragging_mark'),
  })

  const placesAutocompleteOnChange = (addressData) => {
    setAddressData(addressData)
  }

  const placeSelectOnChange = async (position) => {
    setAddressData(position.address)
    let places = await geocodeByAddress(position.address)
    let place = PlaceParser(places[0])

    let address = {
      city: place.city,
      zip: place.zip_code,
      street_name: place.street_name,
      street_number: place.street_number,
      country: place.country_short,
      latLng: { lat: position.lat, lng: position.lng },
    }
    setDefaultPosition({
      address: position.address,
      lat: position.lat,
      lng: position.lng,
    })
    onChange(address)
  }

  const placesAutocompleteOnSelect = async (data, data1) => {
    setAddressData(data1)

    let address = {
      city: '',
      zip: '',
      street_name: '',
      street_number: '',
      country: '',
      latLng: { lat: 0, lng: 0 },
    }

    try {
      let results = await geocodeByAddress(data1)
      let latLng = await getLatLng(results[0])
      let place = PlaceParser(results[0])

      address = {
        city: place.city,
        zip: place.zip_code,
        street_name: place.street_name,
        street_number: place.street_number,
        country: place.country_short,
        latLng: { lat: latLng.lat, lng: latLng.lng },
      }
      setDefaultPosition({
        address: data1,
        lat: latLng.lat,
        lng: latLng.lng,
      })

      onChange(address)
    } catch (error) {
      console.error('Error', error)
    }
  }

  return (
    <FormGroup>
      <label>{t('Place_picker_address_autocomplete_label')}</label>
      <InputGroup>
        <PlacesAutocomplete
          value={addressData}
          onChange={placesAutocompleteOnChange}
          onSelect={(e) => placesAutocompleteOnSelect(addressData, e)}
          shouldFetchSuggestions={addressData.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <>
              <Input
                readOnly={readOnly}
                id="address_autocomplete"
                className={'form-control-lg'}
                {...getInputProps({
                  name: 'address_autocomplete',
                  placeholder: t('Place_picker_address_autocomplete_placeholder'),
                })}
              />
              {suggestions.length > 0 && (
                <AutocompleteDropdown className="autocomplete-dropdown-container autocomplete shadow-sm">
                  {loading && (
                    <AutocompleteDropdownItem className={'suggestion-item'}>
                      ...
                    </AutocompleteDropdownItem>
                  )}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item'
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' }
                    return (
                      <AutocompleteDropdownItem
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </AutocompleteDropdownItem>
                    )
                  })}
                </AutocompleteDropdown>
              )}
            </>
          )}
        </PlacesAutocomplete>
        <InputGroupAddon addonType="append">
          <PlaceSelect
            readOnly={readOnly}
            defaultChosenPosition={defaultPosition}
            addrText={addressData}
            onChange={placeSelectOnChange}
          />
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  )
}


PlacePicker.propTypes = {
  initialValue: propTypes.string
}

PlacePicker.defaultProps = {
  initialValue: ''
}

export default PlacePicker
