import React, { useContext } from 'react'
import { useState } from 'react'
import { useSize } from 'react-use'
import { PriceBreakdownRow } from '../PriceBreakdownGrid/types/PriceBreakdownRow'
import AnalyticGrid from './AnalyticGrid'
import _, { uniqBy } from 'lodash'
import { assignIdxString } from './getSortedAnalyticRows'
import { CustomInput, Input, Button, FormGroup, Label } from 'reactstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getPriceRowChildren } from '../utils'
import ExcelJS, { Column } from 'exceljs'
import saveAs from 'file-saver'
import { Collaborator } from './types/AnalyticClientOffer'
import { useEffect } from 'react'

const worksheetColumns: Partial<Column>[] = [
    { header: '#', key: 'idxString', width: 10 },
    { header: 'Name', key: 'name', width: 60 },
    { header: 'Unit', key: 'unit', width: 10 },
    { header: 'Quantity', key: 'quantity', width: 10 },
]

const redCellBorder: ExcelJS.Border = {
    color: { argb: 'ffff0000' },
    style: 'thin'
}

const ActionsContainer = styled.div`
    width: 100%;
    height: 35px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: right;
    
    z-index: 10;
    top: 60px;   
`

const LeftActionsContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: left;
    align-items: center;
`

const CheckboxInput = styled(CustomInput).attrs((p) => ({
    className: 'd-inline-block mr-3',
    type: 'switch',
}))``

const ParetoFilter = ({ collaborators, initialRows, filterHandler }) => {
    const { t } = useTranslation()

    const [isToggled, setIsToggled] = useState<boolean>(false)

    const filterRows = (toggle: boolean) => {
        setIsToggled(toggle)

        if (!toggle) return filterHandler(initialRows)

        let filteredRows: PriceBreakdownRow[] = []

        const totalAveragePrice = collaborators
            .reduce((currentValue, accumulator) => currentValue + Object.values(accumulator.offers).reduce((a: number, b: number) => a + b, 0), 0)
            / collaborators.length

        const paretoCoefficient = .8

        let currentCoefficient = .0

        let averagePrices = {}

        collaborators.forEach((collaborator) => {
            Object.entries(collaborator.offers)
                .forEach(([key, value]) => {
                    if (!averagePrices[key]) averagePrices[key] = 0

                    averagePrices[key] += value
                })
        })

        Object.entries(averagePrices)
            .forEach(([key, value]: [string, number]) => averagePrices[key] = value / collaborators.length)
        let nodesWithAvgPrice = initialRows
            .map((row) => ({ ...row, averagePrice: averagePrices[row.id] ?? 0 }))
            .sort((a, b) => b.averagePrice - a.averagePrice)
            .map((row) => {
                if (currentCoefficient >= paretoCoefficient) return null

                currentCoefficient += row.averagePrice / totalAveragePrice

                return row
            })
            .filter((row) => !!row)


        filteredRows = nodesWithAvgPrice as unknown as PriceBreakdownRow[]


        // Put parent rows as well
        filteredRows.forEach((node) => {
            let parentId = node?.parentId
            let parent = initialRows.find((row) => row.id === parentId)

            filteredRows.push(parent as unknown as PriceBreakdownRow)
            while (parent?.parentId) {
                parent = initialRows.find((row) => row.id === parent?.parentId)
                if (parent) filteredRows.push(parent as any)
            }
        })


        filterHandler(uniqBy(filteredRows, 'id').sort((a, b) => a.idx - b.idx) as any)
    }

    return (
        <CheckboxInput
            id="paretoRadio"
            name="paretoRadio"
            label={t('tender_summary_pareto', 'Pareto')}
            checked={isToggled}
            onChange={(e) => filterRows(e.target.checked)}
        />
    )
}


const UnitPricesFilter = ({ initialRows, collaborators, filterHandler }) => {
    const { t } = useTranslation()

    const [isToggled, setIsToggled] = useState<boolean>(false)


    const filterRows = (toggle) => {
        setIsToggled(toggle)

        if (!toggle) return filterHandler(false)

        const getQuantity = (rowId: string): number => {
            const row = initialRows.find((row) => row.id === rowId)

            return row.quantity ?? 1
        }

        collaborators.forEach((collaborator) => {
            Object.entries(collaborator.offers).forEach(([rowId, value]: [string, number]) => {
                collaborator.offers[rowId] = (value / getQuantity(rowId)) ?? null
            })
        })
        filterHandler(collaborators)
    }



    return <CheckboxInput
        id="unitPricesRadio"
        name="unitPricesRadio"
        label={t('tender_summary_unit_prices', 'Unit prices')}
        checked={isToggled}
        onChange={(e) => filterRows(e.target.checked)}
    />
}


const AdjustedPricesFilter = ({ collaborators, filterHandler }) => {
    const { t } = useTranslation()
    const [isToggled, setIsToggled] = useState<boolean>(false)

    const filterRows = (toggle) => {
        setIsToggled(toggle)

        if (!toggle) return filterHandler(false)



        const getAverage = (offerId: string): number => {
            const collaboratorsWithOffer = collaborators.filter((c) => !!c.offers[offerId])

            return collaborators.reduce((curr, acc) =>
                curr + (acc.offers[offerId] ?? 0), 0) / collaboratorsWithOffer.length
        }

        // @ts-ignore
        let offerIds = [...new Set(...collaborators.map((c) => Object.keys(c.offers)))]


        collaborators.forEach((c) => {
            offerIds.forEach((offerId) => {
                if (!c.offers[offerId]) c.offers[offerId] = getAverage(offerId)
            })
        })

        filterHandler(collaborators)
    }


    return <CheckboxInput
        id="adjustedPricesRadio"
        name="adjustedPricesRadio"
        label={t('tender_summary_adjusted_prices', 'Adjusted prices')}
        checked={isToggled}
        onChange={(e) => filterRows(e.target.checked)}
    />
}

interface CompareRoundsFilterProps {
    collaborators: Collaborator[]
    tender: any
    changeHandler: (collaborators: any) => any
}
const CompareRoundsFilter = ({ collaborators, tender, changeHandler }: CompareRoundsFilterProps) => {
    const { t } = useTranslation()

    const biddersWithBids = Object.values(_.groupBy(tender.bids, 'bidder.id'))
        .filter((bidders) => bidders.length > 1 && bidders.every((b) => !!b?.priceBreakdown?.children))

    if (!biddersWithBids?.length) return null

    const selectOptions = [{
        value: 'placeholder', label: 'Select'
    }, ...biddersWithBids.map((bids) => ({
        value: bids[0].bidder.id,
        label: bids[0].bidder.name
    }))]

    const handleChange = (e) => {
        const { value } = e.target

        if (value === 'placeholder') return changeHandler(false)


        return changeHandler(collaborators.filter((c => c.id === value)).sort((a: any, b: any) => a.tenderRound - b.tenderRound).map((c) => ({ ...c, name: `Round ${c.tenderRound}` })))
    }


    return <FormGroup>
        <Label>{t('ANALYTIC_COMPARE_BIDDERS')}</Label>
        <Input placeholder={t('ANALYTIC_COMPARE_BIDDERS')} type='select' onChange={handleChange}>
            {selectOptions.map(({ value, label }) =>
                <option id={value} value={value} key={value} >{label}</option>)}
        </Input>
    </FormGroup>
}

interface XLSXDownloadProps {
    priceRows: PriceBreakdownRow[],
    collaborators: any[]
}

const AnalyticXLSXDownload = ({ collaborators, priceRows }: XLSXDownloadProps) => {
    const { t } = useTranslation()

    const generateAndDownloadXLSX = async () => {
        collaborators.forEach((collaborator) => {
            worksheetColumns.push(
                { header: collaborator.name, key: collaborator.id, width: 15 },
            )
        })


        const priceRowsWithCollaborators = priceRows.map((row) => {
            let offers: any[] = []
            collaborators.forEach((collaborator) => {
                if (row.id) {
                    let offer = collaborator.offers[row?.id]
                    if (!offer) {
                        const children = getPriceRowChildren(priceRows, [row.id])
                        offer = children.reduce((acc, curr) => acc + (curr.id ? collaborator.offers[curr.id] ?? 0 : 0), 0)
                    }
                    offers.push({
                        collaboratorName: collaborator.name,
                        collaboratorId: collaborator.id,
                        offer
                    })
                }
            })

            return { ...row, offers }
        })

        const workbook = new ExcelJS.Workbook()


        const worksheet = workbook.addWorksheet('Analytic')

        worksheet.views = [{ state: 'frozen', ySplit: 1 }]

        worksheet.columns = worksheetColumns.concat({ key: 'average', header: 'Average Price', width: 15 })

        priceRowsWithCollaborators.forEach((row) => {

            const worksheetRowData = {
                // @ts-ignore
                idxString: row.idxString,
                name: row.name,
                unit: row.unit,
                quantity: row.quantity,
                average: 0
            }

            const average = (row.offers.reduce((acc, curr) => acc + curr.offer, 0) / row.offers.length) ?? 0

            row.offers.forEach((offer) => worksheetRowData[offer.collaboratorId] = offer.offer)

            worksheetRowData.average = average

            const worksheetRow = worksheet.addRow(worksheetRowData)


            row.offers.forEach((offer) => {
                if (offer.offer >= (0.85 * average)) {
                    worksheetRow.getCell(offer.collaboratorId).border = {
                        top: redCellBorder,
                        right: redCellBorder,
                        bottom: redCellBorder,
                        left: redCellBorder,
                    }
                }
            })

            // @ts-ignore
            worksheetRow.outlineLevel = row.idxString ? row.idxString.split('.').length - 1 ?? 0 : 0

        })


        const blob = new Blob([await workbook.xlsx.writeBuffer()], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        saveAs(blob, 'Analysis.xlsx')

    }

    return <div>
        <Button className="btn-pill mr-1" size="md" onClick={generateAndDownloadXLSX} color="primary">
            {t('Button_XSLX_download')}
        </Button>
    </div>

}

export const AnalyticGridWrapper = ({ tender, activeRound }) => {
    const [filterSelectedRoundOnly, setFilterSelectedRoundOnly] = useState<boolean>(true)

    const priceBreakdowns = tender?.priceBreakdowns?.filter((pb) => filterSelectedRoundOnly ? pb.tenderRound === activeRound : true)

    let priceRows = _.uniqBy(_.flatten(priceBreakdowns.map((pb) => pb.children)), 'rowId')
        .sort((a: any, b: any) => a.idx - b.idx)
        .map(({ parentId, ...rest }, i) => ({
            ...rest,
            ...(parentId ? { parentId } : {}),
            expanded: false,
        }))

    const [sizedElement, { width }] = useSize(({ width }) => <div style={{ height: 1 }}> </div>, {
        width: 1152,
        height: 1,
    })

    const initialCollaborators = tender.bids
        .filter((bid) => !!bid?.priceBreakdown?.children)
        .map((bid) => {
            let offers = {}

            bid.priceBreakdown.children.forEach((node) => offers[node.rowId] = node.price)

            Object.entries(offers).forEach(([key, value]) => {
                if (!value) delete offers[key]
            })

            return {
                id: bid.bidder.id,
                name: bid.bidder.name,
                offers,
                tenderRound: bid.round
            }
        })
    const [collaborators, setCollaborators] = useState(initialCollaborators)
    const [compareRoundsFilterActive, setCompareRoundsFilterActive] = useState(false)

    const collaboratorsToRender = collaborators.filter((c) => filterSelectedRoundOnly ? c.tenderRound === activeRound : true)

    const initialRows = assignIdxString(priceRows.map(({ rowId, name, ...rest }: any) => ({ ...rest, id: rowId, name: name ?? '', expanded: true, hidden: true })))
    const [rowsToRender, setRowsToRender] = useState(initialRows)

    const paretoHandler = (rows: PriceBreakdownRow[]) => {
        setRowsToRender(rows as any)
    }

    const unitPricesHandler = (collaborators: any[] | false) => {
        if (collaborators === false) {
            if (!compareRoundsFilterActive) setCollaborators(initialCollaborators)
        }
        else setCollaborators(collaborators)
    }

    const adjustedPricesHandler = (collaborators: any[] | false) => {
        if (collaborators === false) {
            if (!compareRoundsFilterActive) setCollaborators(initialCollaborators)
        }
        else setCollaborators(collaborators)
    }

    const compareRoundsHandler = (collaborators: any[] | false) => {
        if (collaborators === false) {
            setCompareRoundsFilterActive(false)
            setCollaborators(initialCollaborators)
            setFilterSelectedRoundOnly(true)
        }
        else {
            setCompareRoundsFilterActive(true)
            setCollaborators(collaborators)
            setFilterSelectedRoundOnly(false)
        }
    }

    useEffect(() => {
        setRowsToRender(initialRows)
    }, [activeRound])

    return (
        <>
            {sizedElement}
            <ActionsContainer className='sticky-top bg-white'>
                <LeftActionsContainer>
                    <CompareRoundsFilter collaborators={initialCollaborators} tender={tender} changeHandler={compareRoundsHandler} />
                </LeftActionsContainer>
                <ParetoFilter collaborators={compareRoundsFilterActive ? collaborators : initialCollaborators} initialRows={initialRows} filterHandler={paretoHandler} />
                <UnitPricesFilter initialRows={initialRows} collaborators={compareRoundsFilterActive ? collaborators : initialCollaborators} filterHandler={unitPricesHandler} />
                <AdjustedPricesFilter collaborators={compareRoundsFilterActive ? collaborators : initialCollaborators} filterHandler={adjustedPricesHandler} />
                <AnalyticXLSXDownload collaborators={compareRoundsFilterActive ? collaborators : initialCollaborators} priceRows={rowsToRender as any} />
            </ActionsContainer>
            <AnalyticGrid
                width={width}
                initialRows={rowsToRender}
                collaborators={collaboratorsToRender}
            />
        </>
    )
}