import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import LanguageSelect from './LanguageSelect'
import { languages } from '../constants/language.ts'

const Footer = () => {
  const { t, i18n } = useTranslation()
  let currentLanguage = languages.find((l) => i18n.language.startsWith(l.code))

  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs="6" className="text-left">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a className="text-muted" href="mailto:info@urkla.com">
                  {t('Footer_support')}
                </a>
              </li>
              <li className="list-inline-item">
                <a className="text-muted" href="mailto:info@urkla.com">
                  {t('Footer_help_center')}
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="text-muted"
                  href={
                    currentLanguage.code === 'cs'
                      ? 'https://urkla.com/ochrana-osobnich-udaju'
                      : 'https://urkla.com/en/privacy-policy'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Footer_privacy')}
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="text-muted"
                  href={
                    currentLanguage.code === 'cs'
                      ? 'https://urkla.com/smluvni-podminky'
                      : 'https://urkla.com/en/terms-of-use'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Footer_terms_of_service')}
                </a>
              </li>
            </ul>
          </Col>

          <Col xs="6" className="text-right">
            <LanguageSelect />
            <p className="mb-0">
              &copy; {new Date().getFullYear()} -{' '}
              <span href="/" className="text-muted">
                Urkla
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
export default Footer
