import React, { useState, useEffect } from 'react'
import pt from 'prop-types'
import { Query } from '../../api'
import { useQuery } from '@apollo/react-hooks'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import FileGrid from './grid'

const FileSelector = ({ directory, onSelected, files }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const { data, loading } = useQuery(Query.FILES, { variables: { directory } })

  const handleSubmit = () => {
    onSelected(selected)
    setOpen(false)
  }

  useEffect(() => setSelected(files.map((f) => f.id)), [files])

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true)
        }}
        color="primary"
        size="lg"
        className="btn-pill"
      >
        <Icon icon={faCheckSquare} /> {t('FileManager_manage_documents_btn')}
      </Button>
      <Modal isOpen={open} size="lg" toggle={() => setOpen(false)}>
        <ModalHeader>{t('FileManager_file_selector_select_file')}</ModalHeader>
        <ModalBody>
          <FileGrid
            actionBar={true}
            onSelectionChange={setSelected}
            selection={selected}
            rows={data ? data.files : []}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit}>
            {t('FileManager_file_selector_select_files_btn')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default FileSelector
