import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import Cookies from 'js-cookie'
import { Query, Mutation } from './gql'
import * as Enums from './gql/enums'

import { toastr } from 'react-redux-toastr'
import { getSafe } from '../utils/functions.ts'

import { fallbackLng, lookupLocalStorage } from '../i18n'
import moment from 'moment'

const cache = new InMemoryCache()
const hostUrl = process.env.NODE_ENV === 'production' ? 'https://app.urkla.com' : '/'
const link = createUploadLink({ uri: hostUrl, fetchOptions: { credentials: 'include' } }) // instead of HttpLink to enable uploads

const request = async (operation) => {
  const token = Cookies.get('token')
  let language = getSafe(() => localStorage.getItem(lookupLocalStorage), fallbackLng)
  moment.locale(language)

  if (token) {
    operation.setContext({
      headers: {
        Authorization: token || '',
        Language: language,
      },
    })
  }
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    }),
)

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }, index) => {
          message = message.replace('GraphQL error: ', '')
          toastr.clean()
          toastr.error('Alert', message, { timeOut: 1.5 * 1000 })

          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        })
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    requestLink,
    link,
  ]),
  cache,
})

export { Query, Mutation }
export { Enums }
export default client
