import React from 'react'
import { CellLocation, Id } from '@silevis/reactgrid'
import { nanoid } from 'nanoid'
import { useMemo } from 'react'
import { ExtraChevronCell } from '../cellTemplates/ExtraChevronCellTemplate'
import { ExtraNumberCell } from '../cellTemplates/ExtraNumberCellTemplate'
import { ExtraTextCell } from '../cellTemplates/ExtraTextCellTemplate'
import { PriceBreakdownRow } from '../types/PriceBreakdownRow'
import getDescendantIds from './getDescendantIds'
import getSortedPriceRows from './getSortedPriceRows'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { GRID_MODE } from '../../../pages/tendering/components/PriceBreakdown'
import { NonEditableChevronCell } from '../cellTemplates/NonEditableChevronCell'
import styled from 'styled-components'
import { ExtraDropdownCell } from '../cellTemplates/ExtraDropdownCellTemplate'
import { PriceBreakdownCellChange } from '../types/PriceBreakdownCellChange'
import { TenderStatus } from '../../../utils/types'
import { getPriceRowChildren } from '../../utils'
import { numberFormat } from '../../../utils'

export const IconWrapperLabel = styled.label`
  cursor: pointer;
  margin: 0;
`

interface UseDataRowsProps {
  priceRows: PriceBreakdownRow[]
  setPriceRows: React.Dispatch<React.SetStateAction<PriceBreakdownRow[]>>
  openedDropdownId?: Id
  gridMode?: GRID_MODE
  tenderStatus?: TenderStatus | null
  onChange?: (changes: PriceBreakdownCellChange[] | boolean) => any
  addChildRow: (rowId: Id) => any
  unitOptions: { value: string; label: string }[]
  expandCellChildren: (location: CellLocation) => void
  createSibling: (parentId?: Id, rowId?: Id, direction?: 'above' | 'bellow') => any
  canChangeCells: boolean
  // unitOptions: { value: string; label: string }[];
}

const useDataRows = (props: UseDataRowsProps) => {
  const {
    priceRows,
    setPriceRows,
    createSibling,
    openedDropdownId,
    tenderStatus,
    onChange,
    addChildRow,
    unitOptions,
    expandCellChildren,
    canChangeCells,
  } = props

  let gridMode = props.gridMode ?? GRID_MODE.DEFAULT

  const sortedRows = useMemo(() => getSortedPriceRows(priceRows), [priceRows])
  const filteredRows = useMemo(() => sortedRows.filter((row) => !row.hidden), [sortedRows])

  const maxIndent = sortedRows.reduce((a, b) => Math.max(a, b.indent), 0)

  const createChild = (location: CellLocation) => {
    if (!canChangeCells) return

    const newRowId = nanoid()
    setPriceRows((rows) => [
      ...rows.map((row) => (row.rowId === location.rowId ? { ...row, expanded: true } : row)),
      {
        rowId: newRowId,
        parentId: location.rowId,
        idx: rows.length,
      },
    ])
  }

  const liftRow = (rowId: Id) => {
    if (!canChangeCells) return

    const liftedRow = sortedRows.find((row) => row.rowId === rowId)
    const liftedRowIndex = sortedRows.findIndex((row) => row.rowId === rowId)

    let parentRow = sortedRows.find((row) => row.rowId === liftedRow?.parentId)

    const previousSameIndentRow = sortedRows
      .slice(0, liftedRowIndex)
      .reverse()
      .find((row) => row.indent === liftedRow?.indent)

    if (!liftedRow) return
    else if (!parentRow) {
      if (previousSameIndentRow) {
        parentRow = previousSameIndentRow
        parentRow.parentId = parentRow.rowId
        parentRow.expanded = true
      } else return
    }

    setPriceRows((rows) =>
      rows.map((row) =>
        row.rowId === rowId
          ? { ...row, parentId: parentRow?.parentId }
          : { ...row, expanded: row.rowId === parentRow?.parentId || row.expanded },
      ),
    )
  }

  const createSiblingAbove = (parentId?: Id, rowId?: Id) => createSibling(parentId, rowId, 'above')

  const removeWithDescendants = (id: Id) => {
    const descendantIds = getDescendantIds(id, priceRows)
    setPriceRows((rows) =>
      rows.filter((row) => !descendantIds.includes(row.rowId) && row.rowId !== id),
    )
  }

  // const removeAndLiftDescendants = (id: Id) => {
  //   const descendants = priceRows.filter((row) => row.parentId === id);
  //   const removedRow = priceRows.find((row) => row.rowId === id);
  //   if (!removedRow) {
  //     return;
  //   }
  //   setPriceRows((rows) =>
  //     rows
  //       .filter((row) => row.rowId !== id)
  //       .map((row) =>
  //         descendants.some((descendant) => descendant.rowId === row.rowId) ? { ...row, parentId: removedRow.parentId } : row
  //       )
  //   );
  // };

  return filteredRows.map((uRow) => {
    const unvalidatedHex = Math.floor(50 / maxIndent) * uRow.indent + 205

    const hexVal = isNaN(unvalidatedHex) ? 255 : unvalidatedHex

    const background = `rgb(${hexVal},${hexVal},${hexVal})`

    const isLeaf = !priceRows.some((row) => row.parentId && row.parentId === uRow.rowId)

    const cells = [
      gridMode === GRID_MODE.BIDDER
        ? ({
          type: 'nonEditableChevron',
          text: uRow.name ?? '',
          style: { background },
          isExpanded: uRow.expanded,
          hasChildren: priceRows.some((row) => row.parentId === uRow.rowId),
          parentId: uRow.parentId,
          renderer: (text: string) => `${uRow.idxString} - ${text}`,
          expandChildren: expandCellChildren,
          createSiblingAbove,
          location: { rowId: uRow.rowId, columnId: 'name' },
        } as NonEditableChevronCell)
        : ({
          type: 'extraChevron',
          text: uRow.name ?? '',
          style: { background },
          indent: uRow.indent,
          isExpanded: uRow.expanded,
          createChild,
          location: { rowId: uRow.rowId, columnId: 'name' },
          hasChildren: priceRows.some((row) => row.parentId === uRow.rowId),
          parentId: uRow.parentId,
          createSibling,
          renderer: (text: string) => `${uRow.idxString} - ${text}`,
          expandChildren: expandCellChildren,
          createSiblingAbove,
        } as ExtraChevronCell),
      isLeaf
        ? ({
          type: 'extraDropdown',
          text: uRow.unit ?? '',
          isOpen: openedDropdownId === uRow.rowId,
          selectedValue: uRow.unit,
          values: unitOptions,
          nonEditable:
            gridMode === GRID_MODE.BIDDER ||
            (tenderStatus !== 'PREPARATION' && tenderStatus !== 'ANOTHER_ROUND'),
          createChild,
          createSibling,
          style: { background },
          location: { rowId: uRow.rowId, columnId: 'unit' },
          expandChildren: expandCellChildren,
          createSiblingAbove,
        } as ExtraDropdownCell)
        : ({
          type: 'extraText',
          text: '',
          nonEditable: true,
          createChild,
          createSibling,
          style: { background },
          location: { rowId: uRow.rowId, columnId: 'controls' },
          expandChildren: expandCellChildren,
          createSiblingAbove,
        } as ExtraTextCell),
      isLeaf
        ? ({
          type: 'number' as any,
          value: uRow?.quantity,
          format: numberFormat,
          location: { rowId: uRow.rowId, columnId: 'quantity' },
          createChild,
          nonEditable:
            gridMode === GRID_MODE.BIDDER ||
            (tenderStatus !== 'PREPARATION' && tenderStatus !== 'ANOTHER_ROUND'),
          // nonEditable: tenderStatus !== 'RUNNING',
          style: { background },
          createSibling,
          expandChildren: expandCellChildren,
          createSiblingAbove,
        } as ExtraNumberCell)
        : ({
          type: 'extraText',
          text: '',
          nonEditable: true,
          createChild,
          createSibling,
          style: { background },
          location: { rowId: uRow.rowId, columnId: 'controls' },
          expandChildren: expandCellChildren,
          createSiblingAbove,
        } as ExtraTextCell),
    ]

    if (gridMode === GRID_MODE.BIDDER) {
      const children = getPriceRowChildren(priceRows, [uRow.rowId])
      const hasChildren = children.length > 0

      cells.push({
        type: 'number' as any,
        format: numberFormat,
        value: hasChildren
          ? children.reduce((acc, val) => acc + (val.price || 0), 0)
          : uRow.price
            ? parseFloat(uRow.price as any)
            : 0,

        location: { rowId: uRow.rowId, columnId: 'price' },
        createChild,
        nonEditable: tenderStatus !== 'RUNNING' || !isLeaf,
        style: { background },
        createSibling,
        expandChildren: expandCellChildren,
        createSiblingAbove,
      } as ExtraNumberCell)
    } else if (
      (gridMode === GRID_MODE.DEFAULT && canChangeCells)
    ) {

      cells.push({
        type: 'extraText',
        text: '',
        nonEditable: true,
        createChild,
        createSibling,
        expandChildren: expandCellChildren,
        style: { background },
        location: { rowId: uRow.rowId, columnId: 'controls' },
        createSiblingAbove,
        renderer: () => (
          <div
            onPointerDownCapture={(e) => e.stopPropagation()}
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <IconWrapperLabel
              onClick={() => {
                addChildRow(uRow.rowId)
                onChange?.(true)
              }}
            >
              <Icon icon={faPlusCircle as any} size="lg" color="#7F7F7F" />
            </IconWrapperLabel>
            <IconWrapperLabel
              onClick={() => {
                liftRow(uRow.rowId)
                onChange?.(true)
              }}
            >
              <Icon icon={faArrowCircleUp as any} size="lg" color="#7F7F7F" />
            </IconWrapperLabel>
            <IconWrapperLabel
              onClick={() => {
                removeWithDescendants(uRow.rowId)
                onChange?.(true)
              }}
            >
              <Icon icon={faTimesCircle as any} size="lg" color="#7F7F7F" />
            </IconWrapperLabel>
          </div>
        ),
      } as ExtraTextCell)
    }

    return {
      rowId: uRow.rowId,
      height: 50,
      cells,
    }
  })
}

export default useDataRows
